import FormJaivana from 'dashboard_jaivana_v1';


/**
 * @author Juan Camilo Villa
 * @description Custom modificado para Mostrador - Administrador Operarios- 1346
 * @version 1.01.0055
 * @class Administrador Operarios
 * @extends {FormJaivana}
 */

class CustomAdministradorOperarios extends FormJaivana.form {


    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);

        this.traerListadoAdminOperarios = this.traerListadoAdminOperarios.bind(this);
        this.successTraerListadoAdminOperarios = this.successTraerListadoAdminOperarios.bind(this);
        this.setButtonDeleteAdminOperario = this.setButtonDeleteAdminOperario.bind(this);
        this.gridOptionsListadoAdminOperarios = Object.assign({},this.gridOptions)

        this.traerListadoUbicaciones = this.traerListadoUbicaciones.bind(this);
        this.successTraerListadoUbicaciones = this.successTraerListadoUbicaciones.bind(this);

        this.deleteAdminOperario = this.deleteAdminOperario.bind(this);
        this.successDeleteAdminOperario = this.successDeleteAdminOperario.bind(this);

        this.crearAdminOperario = this.crearAdminOperario.bind(this);
        this.successCrearAdminOperario = this.successCrearAdminOperario.bind(this);

        this.confirmDelete = this.confirmDelete.bind(this);

        this.limpiarCampos = this.limpiarCampos.bind(this);
        
    }
    /**
     * Método que se ejecuta al inicio de renderizar el componente. Se encarga de configurar por defecto 
     * la vista principal
     */
    initForm() {
        console.log('Administración de Operarios @version: 1.01.0004.1, @author: Cristian Ciro');
        this.getField('estadoactivo_id').setValue(1); 
        
        this.getField("btn_agregar").setClick(() => {
            this.getField('estadoactivo_id').setValue(1); 
            this.getField("modal_admin_operario").handleClickOpen();
            this.traerListadoUbicaciones();

        });
        this.getField("btn_agregar_modal").setClick(() => {
            this.crearAdminOperario();
           
        });

        this.getField('modal_admin_operario').setCloseButton(() => {
            this.limpiarCampos();
        });
        this.traerListadoAdminOperarios();
    }

    traerListadoAdminOperarios(){
        this.datosConsultar = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'most-adminoperarios', operacion: 'listadopedidos', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successTraerListadoAdminOperarios,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successTraerListadoAdminOperarios(data){
        if(data.estado_p === 200 && data.data.length > 0){
            let configCell = new Map();
            /* this.gridOptionsListadoAdminOperarios.paginationPageSize = 100; */
            let arrayCopy = [...data.data];
            arrayCopy.forEach(operario => {
                let caracter = 'T' || ' '; 
                if (operario.fecha.includes(caracter)) {
                    let dateArray = operario.fecha.split(caracter);
                    
                    operario.fecha = dateArray[0];
                }
            });

            this.gridOptionsListadoAdminOperarios['rowData'] = arrayCopy;     
            configCell.set('fecha', {headerName: 'Fecha'});
            configCell.set('codigo', {headerName: 'Código Usuario'});
            configCell.set('nombre', {headerName: 'Nombre Usuario'});
            configCell.set('ubicacion', {headerName: 'Ubicación'});
            configCell.set('siglas', {headerName: 'Estado'});
            configCell.set('supervisor', {headerName: 'Supervisor'});
            configCell.set('tipo_pedido', {headerName: 'Tipo Pedido Contar'}); 
            configCell.set('accion', { cellRenderer: this.setButtonDeleteAdminOperario, headerName: 'Acción', width: 200, sortable: false, filter: false });
            
            
            /* configCell.set(2,{cellRenderer:this.setButtonDeleteAdminOperario ,field: 'accion', with: 40 },); */
            this.getField('rejilla').initData(this.gridOptionsListadoAdminOperarios,configCell);

        }else if(data.estado_p === 404 ){
            this.gridOptionsListadoAdminOperarios['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsListadoAdminOperarios);
            /* this.alertGeneral.toggle(true,'No hay datos',"error"); */
            this.getField('rejilla').toggle(false);
        } else {
            this.gridOptionsListadoAdminOperarios['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsListadoAdminOperarios);
            this.getField('rejilla').toggle(false);
        }
    }

    traerListadoUbicaciones(){
        this.datosConsultar = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'most-adminoperarios', operacion: 'listarUbicaciones', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successTraerListadoUbicaciones,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successTraerListadoUbicaciones(data){
        
        if (data.estado_p === 200) {
            let arrayLocation = data.data.listado
            let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
            arrayLocation.forEach(location => {
                let dataop = {};
                dataop['value']=location['id'];
                dataop['text'] = location['ubicacion'] 
                opciones.push(dataop);
                
            });
            this.getField('ubicacion_articulo_id').setOptions(opciones);

            
        } 
    }

    confirmDelete(id){
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar Registro','Está seguro que desea eliminar este registro? ');
        this.getField('confirmModalCustom').setClickDialog(() => this.deleteAdminOperario(id));
        this.getField('confirmModalCustom').toggle(true);
    }

    setButtonDeleteAdminOperario(props){
        let id = props.data.id; 
        let button = document.createElement('input'); 
        button.setAttribute('style', 'width: 50%; heigth: 50%;');
        button.setAttribute("class","buttonStyle");//,"width:100%; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.onclick = ()=>this.confirmDelete(props.data.id);
        button.setAttribute("id",'button_adminOperario_'+id);
        button.setAttribute("value","Eliminar");
        button.setAttribute("type","button");
        let fragment = document.createDocumentFragment();
        fragment.appendChild(button);
        
        /* return this.createElementJaivana(fragment); */
        return this.createElementJaivana(button);

        
    }
    deleteAdminOperario(id){
        if (id !== "") {
            let datos = {
                datos: {
                    id: id
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'most-adminoperarios', operacion: 'eliminar', operacion_tipo: 'eliminar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'DELETE',
                body: datos,
                success: this.successDeleteAdminOperario,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }
    successDeleteAdminOperario(data){
        if (data.estado_p === 200) {
            this.traerListadoAdminOperarios();
            this.getField('confirmModalCustom').toggle(false);
        }
    }

    crearAdminOperario(){
        if (this.getField('usuario_id').getValue() !== '' && (this.getField('estadoactivo_id').getValue() !== '' || this.getField('estadoactivo_id').valid()) && 
         (this.getField('buscador_usuario').getValue() !== '' || this.getField('buscador_usuario').valid()) && (this.getField('pedido_contar').getValue() !== '' && this.getField('pedido_contar').valid()) && 
         (this.getField('ubicacion_articulo_id').getValue() !== '' || this.getField('ubicacion_articulo_id').valid()) && (this.getField('radio_supervisor').getValue() !== '' && this.getField('radio_supervisor').valid()) ) {

            let datos = {
                datos: {
                    usuario_id: this.getField('usuario_id').getValue(),
                    ubicacion_articulo_id: this.getField('ubicacion_articulo_id').getValue(),
                    estadoactivo_id: this.getField('estadoactivo_id').getValue(),
                    pedido_contar:this.getField("pedido_contar").getValue(),
                    supervisor:this.getField("radio_supervisor").getValue()
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'most-adminoperarios', operacion: 'crear', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'POST',
                body: datos,
                success: this.successCrearAdminOperario,
                error: this.error_,
                general: this.generalFormatPmv
            });

        }
    }

    successCrearAdminOperario(data){
        if (data.estado_p === 200) {
            this.getField('modal_admin_operario').handleClose();  
            this.traerListadoAdminOperarios();
            this.limpiarCampos();
        }else{
            this.alertGeneral.toggle(true, data.data.mensaje, 'error');
        }

    }

    limpiarCampos(){
        this.getField('usuario_id').setValue(''); 
        this.getField('ubicacion_articulo_id').setValue('');
        this.getField('ubicacion_articulo_id').setError(false,'');
        this.getField('estadoactivo_id').setValue(1);
        this.getField('estadoactivo_id').setError(false,'');
        this.getField('nombre_usuario').setValue(''); 
        this.getField('buscador_usuario').setError(false,'');
        this.getField('buscador_usuario').setValue(''); 
    }

 
}

FormJaivana.addController("most-adoperarios", CustomAdministradorOperarios);
export default CustomAdministradorOperarios;