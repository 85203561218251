import FormJaivana from 'dashboard_jaivana_v1';


/**
 * @author Juan Camilo Villa
 * @updated Cristian Ciro
 * @description Custom modificado para Mostrador - Administrador Operarios- 1346
 * @version jdesk_1.01.0004.1
 * @class Administrador Operarios
 * @extends {FormJaivana}
 */



class CustomRevisarConteo extends FormJaivana.form {


    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);

        this.traerListadoConteo = this.traerListadoConteo.bind(this);
        this.successtraerListadoConteo = this.successtraerListadoConteo.bind(this);
        this.setButtonConfigConteo = this.setButtonConfigConteo.bind(this);
        this.gridOptionsListadoConteo = Object.assign({},this.gridOptions)
        this.gridOptionsListadoVerMas = Object.assign({},this.gridOptions)


        this.devolverConteo = this.devolverConteo.bind(this);
        this.successDevolverConteo = this.successDevolverConteo.bind(this);


        this.confirmDevolverConteo = this.confirmDevolverConteo.bind(this);

        this.traerListadoVerMas = this.traerListadoVerMas.bind(this);
        this.successtraerListadoVerMas = this.successtraerListadoVerMas.bind(this);
        
        this.editarConteo = this.editarConteo.bind(this);
        
        this.arrayCopy =[];
        this.numero_pedido = 0;
        
        this.confirmRecontarPedido = this.confirmRecontarPedido.bind(this);
        this.recontarPedido = this.recontarPedido.bind(this);
        this.successRecontarPedido = this.successRecontarPedido.bind(this);
        
        this.actualizarConteo = this.actualizarConteo.bind(this);
        this.successActualizarConteo = this.successActualizarConteo.bind(this);

        this.setButtonTraerItems            = this.setButtonTraerItems.bind(this);
    
        this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
        this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);
        this.setSelectTipoPedido            = this.setSelectTipoPedido.bind(this);
        this.valueChangeTipoPedido          = this.valueChangeTipoPedido.bind(this);
        this.successCambiarTipoPedido       = this.successCambiarTipoPedido.bind(this);
    }   
    /**
     * Método que se ejecuta al inicio de renderizar el componente. Se encarga de configurar por defecto 
     * la vista principal
     */
    
    initForm() {
        console.log('Administración Conteo Pedidos Operarios @version: jdesk_1.01.0004.1, @author: Juan Camilo Villa, @updated: Cristian Ciro, @updated: Santiago Hernández, @updated: Anderson Acevedo');
       

        this.getField('modal_ver_mas').setCloseButton(() => {
            this.traerListadoConteo();
            
        });

        this.getField('btn_refrescar').setClick(() => {
            this.traerListadoConteo();
        });

        this.getField('btn_recontar').setClick(() => {
            this.recontarPedido();
        });

        this.getField('btn_cancelar_recontar').setClick(() => {
            this.getField('modal_ver_mas').handleClose();
            //this.traerListadoConteo();
        });

        this.traerListadoConteo();
    }

    traerListadoConteo(){
            //1347-servicio_traer_listado_conteo'
            let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'most-revisarconteo', operacion: 'listadoconteo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successtraerListadoConteo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    successtraerListadoConteo(data){
        if(data.estado_p === 200 && data.data.length > 0){
            let configCell = new Map();
            
            let arrayCopyConteo = [...data.data];

            let createElementJaivana   = this.createElementJaivana;
            this.gridOptionsListadoConteo['rowData'] = arrayCopyConteo;

            configCell.set('numero_pedido', {headerName: 'Número Pedido'});     
            //configCell.set('total_referencias_pedidas', {headerName: 'Total Referencias Pedidas', width: 300});
            configCell.set('total_referencias_pedidas', {headerName: 'Total Referencias Pedidas',cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_referencias_pedidas) }, cellStyle: {textAlign:"right"}});


            configCell.set('por_asignar', { cellRenderer:(props) => { 
                let fragment = document.createDocumentFragment();
                if ((props.data.por_asignar > 0) ) {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",this.currencyFormatterGeneral(props.data.por_asignar));
                    div.setAttribute("type","div");
                    fragment.appendChild(div);
                    return createElementJaivana(fragment);
                }else {
                    return ''+this.currencyFormatterGeneral(props.data.por_asignar)
                }
            },
            cellStyle: function (props){
                if(props.data.por_asignar > 0)
                    return { textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)',backgroundColor: '#F36262' }
                else
                    return {textAlign: "right"}
            },            
            editable: false, onCellValueChanged: this.editarConteo, sortable: true, filter: false, field: 'Por Asignar' });

             configCell.set('en_conteo', { cellRenderer:(props) => { 
                let fragment = document.createDocumentFragment();
                if ((props.data.en_conteo > 0) ) {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",this.currencyFormatterGeneral(props.data.en_conteo));
                    div.setAttribute("type","div");
                    fragment.appendChild(div);
                    return createElementJaivana(fragment);
                }else {
                    return this.currencyFormatterGeneral(props.data.en_conteo)
                }
             },
            cellStyle: function (props) {
                if(props.data.en_conteo > 0)
                    return { textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)', height: '100%',cursor: 'default', border: 'none',backgroundColor: '#F3F362', fontWeight: 'bold' }
                else
                    return {textAlign: "right"}
            },
            editable: false, onCellValueChanged: this.editarConteo, sortable: true, filter: false, field: 'En Conteo' });
             
            configCell.set('contados', { cellRenderer:(props) => { 
                let fragment = document.createDocumentFragment();
                if ((props.data.total_referencias_pedidas === props.data.contados) ) {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",this.currencyFormatterGeneral(props.data.contados));
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    fragment.appendChild(div);
                    return createElementJaivana(fragment);
                }else {
                    return this.currencyFormatterGeneral(props.data.contados);
                }
            },
            cellStyle: function (props) {
                if(props.data.total_referencias_pedidas === props.data.contados)
                    return { textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)',cursor: 'default', border: 'none',backgroundColor: '#69F362', fontWeight: 'bold' }
                else
                    return {textAlign: "right"}
            },
            editable: false, onCellValueChanged: this.editarConteo, sortable: true, filter: false, field: 'Contados' });
            
            configCell.set('empaque_faltante', { cellRenderer:(props) => { 
                return this.currencyFormatterGeneral(props.data.empaque_faltante);
            },
            cellStyle: function (props) {
                if(props.data.empaque_faltante === 0)
                    return { textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)',cursor: 'default', border: 'none',backgroundColor: '#69F362', fontWeight: 'bold' }
                else
                    return { textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)',cursor: 'default', border: 'none',backgroundColor: '#F3F362', fontWeight: 'bold' }
            },
            editable: false, sortable: true, filter: false, field: 'Empaque Faltante' });
            

            configCell.set('fecha', {headerName: 'Fecha'});     
            configCell.set('nit', {headerName: 'Nit'});     
            configCell.set('sede', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede) }, cellStyle: {textAlign:"right"}});
            configCell.set('nombre', {headerName: 'Nombre'});     
            configCell.set('estado', {headerName: 'Estado'});      
            //configCell.set('tipo_pedido', {headerName: 'Tipo Pedido'}); 
            configCell.set("tipo_pedido",{ cellRenderer: this.setSelectTipoPedido, cellStyle: { backgroundColor: "rgb(238,238,238)",borderRadius:"5px",color:'rgb(0, 86, 201)' }, field: 'Tipo Pedido' });     
            configCell.set('accion', {cellRenderer: this.setButtonConfigConteo, headerName: 'Acción', sortable: false, filter: false });
            configCell.set('ver_mas', {cellRenderer: this.setButtonTraerItems, headerName: 'Ver más', sortable: false, filter: false });

            
            
            /* configCell.set(2,{cellRenderer:this.setButtonConfigConteo ,field: 'accion', with: 40 },); */
            this.getField('rejilla').initData(this.gridOptionsListadoConteo,configCell);

        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true,'No hay datos',"error");
            this.getField('rejilla').toggle(false);
        } else {
            this.gridOptionsListadoConteo['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsListadoConteo);
            this.getField('rejilla').toggle(false);
        }
    }

    setSelectTipoPedido(props){
        let maquina = props.data.tipo_pedido;
        let select = document.createElement("select");
        let div = document.createElement('div');
        div.setAttribute('class', 'custom-select');
        select.setAttribute('class', 'custom-select');
        select.onchange = (event) => this.valueChangeTipoPedido(event, props.data);
        select.setAttribute('id', 'mySelectTipoPedido_');

        if(maquina === "Entrega Inmediata"){
            maquina = 1;
        }else{
            maquina = 2;
        }

        let opciones = this.getTipoPedido();
        this.valor_idRec = 1;
        opciones.map((item) => {
            select.appendChild(item);
            if (parseInt(item.value) === maquina)
                this.valor_idRec = parseInt(item.value);
            return null;
        })
        div.appendChild(select)
        if(maquina !== null && maquina !== undefined)
            select.value = this.valor_idRec;
        return this.createElementJaivana(div);
    }

    valueChangeTipoPedido(e,data){
        let valor = e.target.value === "1"?'Entrega Inmediata':'Entrega Posterior';
        let id_reg = data.numero_pedido;

        // proc-procesosactividadesencargadosconfiguracion
        let datos = { datos: {
            numero_pedido: id_reg,
            tipo_pedido: valor
        }};

        this.generalFormatPmv = { tipo_servicio: 'most-revisarconteo', operacion: 'cambiartipo', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successCambiarTipoPedido,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successCambiarTipoPedido(data){
        if(data.estado_p === 200){
            this.getField('rejilla').toggle(false);
            this.traerListadoConteo();
        }
    }

    getTipoPedido(){
        let opcionN = document.createElement('option');
        opcionN.setAttribute('value',' ');
        opcionN.textContent = 'Ninguno';

        let datos = [opcionN];
        let opcion = document.createElement('option');
        opcion.setAttribute('value',1);
        opcion.textContent = "Entrega Inmediata";
        datos.push(opcion);

        let opcion2 = document.createElement('option');
        opcion2.setAttribute('value',2);
        opcion2.textContent = "Entrega Posterior";
        datos.push(opcion2);
        return datos;
    }

    traerListadoVerMas(numero_pedido){
            //'1347-servicio_ver_mas_conteo'
            let datos={ datos: {numero_pedido: parseInt(numero_pedido)}};
            this.generalFormatPmv = { tipo_servicio: 'most-revisarconteo', operacion: 'vermas', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successtraerListadoVerMas,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    successtraerListadoVerMas(data){

        if(data.estado_p === 200 && data.data.length > 0){
            this.getField("modal_ver_mas_2").handleClickOpen();
            let configCell = new Map();
            this.arrayCopy = [...data.data];

            let createElementJaivana   = this.createElementJaivana;
            configCell.set('conteo', { cellRenderer:(props) => {
                let fragment = document.createDocumentFragment();
                if (props.data.estado === 'EN CONTEO') {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",this.currencyFormatterGeneral(props.data.conteo));
                    //div.setAttribute("style","width: 100%;height: 100%;border-style: none;background-color: #d8d8d8");
                    //div.setAttribute("class","divStyle3");
                    div.setAttribute("type","div");
                    /* div.setAttribute("style","width: 100%;height: 100%;cursor: default;border: none;background-color: #69F362;font-weight: bold;"); */                        
                    fragment.appendChild(div);
                    return createElementJaivana(fragment);                        
                } else if((props.data.estado === 'POR ASIGNAR') ) {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",this.currencyFormatterGeneral(props.data.conteo));
                    //div.setAttribute("style","width: 100%;height: 100%;border-style: none;background-color: #d8d8d8");
                    //div.setAttribute("class","divStyle3");
                    div.setAttribute("type","div");
                    /* div.setAttribute("style","width: 100%;height: 100%;cursor: default;border: none;background-color: #F36262;font-weight: bold;"); */                        
                    fragment.appendChild(div);
                    return createElementJaivana(fragment);                        
                }else if ((props.data.estado === 'CONTADOS') ) {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",this.currencyFormatterGeneral(props.data.conteo));
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    fragment.appendChild(div);
                    return createElementJaivana(fragment);
                }else {
                    return this.currencyFormatterGeneral(props.data.conteo)
                }
            },cellStyle: { textAlign:"right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }
            /*editable: true, onCellValueChanged: this.editarConteo, sortable: true, filter: false, field: 'conteo'*/
            });


            configCell.set('estado', { cellRenderer: function (props) { 
                if (props.data.estado === 'CONTADOS') {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value","CONTEO FINALIZADO");
                    div.setAttribute("class","divStyle3");
                    div.setAttribute("type","div");
                    //fragment.appendChild(div);
                    return createElementJaivana(div);     
                }else if (props.data.estado === 'EN CONTEO'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value","CONTEO EN PROCESO");
                    div.setAttribute("class","divStyle4");
                    div.setAttribute("type","div");
                    //fragment.appendChild(div);
                    return createElementJaivana(div);     
                }else{
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value","CONTEO SIN INICIAR");
                    div.setAttribute("class","divStyle5");
                    div.setAttribute("type","div");
                    //fragment.appendChild(div);
                    return createElementJaivana(div);    
                }
            }, field: 'estado' });

            configCell.set('cantidad_pedida', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_pedida) }, cellStyle: {textAlign:"right"}});


            this.gridOptionsListadoVerMas['rowData'] = this.arrayCopy;
            this.getField('rejilla_ver_mas').initData(this.gridOptionsListadoVerMas, configCell);


        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true,'No hay datos',"error");
            this.getField('rejilla_ver_mas').toggle(true);
        } else {
            this.gridOptionsListadoVerMas['rowData'] = [];
            this.getField('rejilla_ver_mas').initData(this.gridOptionsListadoVerMas);
        }



    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }



    editarConteo(data){
        let seguir = this.getField('rejilla_ver_mas').cellValid(data.oldValue, data.newValue, "^[0-9]", undefined, data.data.cantidad_pedida, 0);
        let cantidad_pedida = data.data.cantidad_pedida;
        let newValue = data.newValue;
        if (seguir) {
            
            if( newValue <= cantidad_pedida && newValue !== ''){
                
                this.actualizarConteo(data.data, newValue);
                
                /* this.actualizarConteo(); */
                
            }else{
                this.alertGeneral.toggle(true,'El conteo no puede ser mayor a la cantidad pedida ',"error");
                this.traerListadoVerMas(this.numero_pedido);
                    
                /* data.data.conteo = data.oldValue; */
                /*             let newArray = [... this.arrayCopy];
                newArray.find((item, index) => {
                    if(item.id === data.data.id){
                        newArray[index].conteo = data.oldValue;
                        }
                        return null;
                        });
                        let configCell = new Map(); 
                        configCell.set('conteo', { headerName: 'Conteo', editable: true, 
                        cellRenderer:function(props){return props.data.conteo}, onCellValueChanged:this.editarConteo, cellStyle: {backgroundColor: "rgb(238,238,238)",borderRadius:"5px",color:'rgb(0, 86, 201)' } });
                        this.gridOptionsListadoVerMas['rowData'] = newArray;
                        
                        this.getField('rejilla_ver_mas').initData(this.gridOptionsListadoVerMas,configCell);
                        
                        
                        this.alertGeneral.toggle(true,'El conteo no puede ser mayor a la cantidad pedida ',"error"); */
            }
        }else{
            //this.alertGeneral.toggle(true,'Formato no válido',"error");
            this.traerListadoVerMas(this.numero_pedido);
        }
    }

    
    
    
    setButtonConfigConteo(props){
        let id = props.data.id;
        
        
        let lockButton = (props.data.total_referencias_pedidas === props.data.contados && props.data.empaque_faltante === 0)? true: false;
        let button = document.createElement('input'); 
        //button.setAttribute('style',"margin-left: 10px;margin-left: 10px");
        if(lockButton)
            button.setAttribute("class","buttonStyle7");//,"width:100%; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        else 
            button.setAttribute("class","buttonDisabled");
        button.onclick = ()=> (lockButton) ? this.confirmRecontarPedido(props.data) : null;
        button.setAttribute("id",'button_conteo_'+id);
        button.setAttribute("value",'Recontar Pedido');
        button.setAttribute("type","button");
        let fragment = document.createDocumentFragment();
        fragment.appendChild(button);
        
        /* return this.createElementJaivana(fragment); */
        return this.createElementJaivana(fragment);
        
    }

    setButtonTraerItems(props){
        let id = props.data.id;        
        let button = document.createElement('input'); 
        button.setAttribute("class","buttonStyle7");
        button.onclick = ()=> this.traerListadoVerMas(props.data.numero_pedido);
        button.setAttribute("id",'button_conteo_'+id);
        button.setAttribute("value",'Ver conteo');
        button.setAttribute("type","button");
        let fragment = document.createDocumentFragment();
        fragment.appendChild(button);
        
        /* return this.createElementJaivana(fragment); */
        return this.createElementJaivana(fragment);
        
    }

    confirmDevolverConteo(id){
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar Registro','Está seguro que desea devolver el conteo de este pedido? ');
        this.getField('confirmModalCustom').setClickDialog(() => this.devolverConteo(id));
        this.getField('confirmModalCustom').toggle(true);
    }


    devolverConteo(id){
        if (id !== 0 && id !== '') {
            //'1347-servicio_modificaar_estado_conteo';
            let datos={ datos: {
                'id': id,
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-revisarconteo', operacion: 'modificarestado', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successDevolverConteo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }

    }
    successDevolverConteo(data){
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true,data.data.mensaje,"success");
            this.getField('confirmModalCustom').toggle(false);
            this.traerListadoConteo();
        }else{
            this.alertGeneral.toggle(true,'Error Actualizando el conteo',"error");
        }
    }


    confirmRecontarPedido(data){
        this.getField("modal_ver_mas").handleClickOpen();
        this.getField('nombre_usuario').setError(false,'');
        this.getField('buscador_usuarios').setError(false,'');

        this.getField('numero_pedido').setValue(data.numero_pedido);
        this.codigo_sucursal = data.codigo_sucursal;
        this.getField('numero_pedido').setVisible(true);
        this.getField('buscador_usuarios').setVisible(true);
        this.getField('nombre_usuario').setVisible(true);



        /*this.getField('confirmModalCustom').setTitleAndContent('Recontar Pedido','Está seguro que desea recontar este pedido? ');
        this.getField('confirmModalCustom').setClickDialog(() => this.recontarPedido(props));
        this.getField('confirmModalCustom').toggle(true);*/
    }

    recontarPedido(){
        if(this.getField('buscador_usuarios').valid() && this.getField('nombre_usuario').valid() && this.getField('usuario_id').valid()){
            //1347-servicio_recontarpedido
            let datos={ datos: {
                'numero_pedido': this.getField('numero_pedido').getValue(),
                    'codigo_sucursal': this.codigo_sucursal,
                    'codigo_usuario': this.getField('buscador_usuarios').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-revisarconteo', operacion: 'recontarpedido', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successRecontarPedido,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successRecontarPedido(data){
        if (data.estado_p === 200) {
            this.getField('modal_ver_mas').handleClose();
            //this.traerListadoConteo();
        }else{
            this.alertGeneral.toggle(true,'Error al recontar el pedido',"error");
        }
    }

    /*successCerrarPedido(data){
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true,data.data.mensaje,"success");
            this.getField('confirmModalCustom').toggle(false);
            this.traerListadoConteo();
        }else{
            this.alertGeneral.toggle(true,'Error Cerrando el pedido',"error");
        }

    }*/
    actualizarConteo(props,newValue){

            // '1347-servicio_actualizarconteo';
            let datos={ datos: {
                'numero_pedido': props.numero_pedido,
                'nitsx_sedes_id': props.nitsx_sedes_id,
                'conteo': parseInt(newValue),
                'id': props.id
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-revisarconteo', operacion: 'actualizarconteo', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successActualizarConteo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });

    }

    successActualizarConteo(data){
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true,"Conteo actualizado con éxito","success");
            this.getField('confirmModalCustom').toggle(false);
            this.traerListadoVerMas(this.numero_pedido);
        }else{
            this.alertGeneral.toggle(true,'Error actualizando el conteo',"error");
        }

    }




 
}

FormJaivana.addController("most-revisarconteo", CustomRevisarConteo);
export default CustomRevisarConteo;