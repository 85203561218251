import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomGrabacionDeCompras
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_jdesk_1.01.0002
 **/
class CustomGrabacionDeCompras extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);
    this.llenarSelect = this.llenarSelect.bind(this);
    this.suceesLlenarSelect = this.suceesLlenarSelect.bind(this);
    this.comenzarForm = this.comenzarForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);
    this.onSelectionChangedPrincipal                                  = this.onSelectionChangedPrincipal.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChangedPrincipal;

    this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsComponentes1 = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentes1['onSelectionChanged']           = this.onSelectionChanged;

    
    this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
    this.formatNumberSaldo                               = this.formatNumberSaldo.bind(this);
    this.primeraVez                               = this.primeraVez.bind(this);
    this.sucessPrimeraVez                               = this.sucessPrimeraVez.bind(this);
    this.crearBotonTabla                               = this.crearBotonTabla.bind(this);
    this.accionProcesar                               = this.accionProcesar.bind(this);
    this.eliminarRegistro                               = this.eliminarRegistro.bind(this);
    this.confirmDelete                               = this.confirmDelete.bind(this);
    this.refescarTabla                               = this.refescarTabla.bind(this);
    this.successEliminar                               = this.successEliminar.bind(this);
    this.sucessRefrescar                               = this.sucessRefrescar.bind(this);
    this.abrirModalAgregar                               = this.abrirModalAgregar.bind(this);

    this.sucursal_ingreso='';
    this.calcularDescuento                               = this.calcularDescuento.bind(this);
    this.calcularItem                               = this.calcularItem.bind(this);
    this.traerCostoBodega                               = this.traerCostoBodega.bind(this);
    this.sucessTraerCostoBodega                               = this.sucessTraerCostoBodega.bind(this);
    this.sucessAlterno                               = this.sucessAlterno.bind(this);
    this.traerPropiedades                               = this.traerPropiedades.bind(this);
    this.sucessTraerPropiedades                               = this.sucessTraerPropiedades.bind(this);
    this.validacionesAgregar                               = this.validacionesAgregar.bind(this);
    this.autoriza_ordenesc='';

    this.sucessValidaciones                               = this.sucessValidaciones.bind(this);
    this.validacionesAgregar2                               = this.validacionesAgregar2.bind(this);
    this.grabarItem                               = this.grabarItem.bind(this);
    this.sucessGrabarItem                               = this.sucessGrabarItem.bind(this);
    this.recalcularArticulo                               = this.recalcularArticulo.bind(this);
    this.sucessRecalcularArticulo                               = this.sucessRecalcularArticulo.bind(this);
    this.grabarItem2                               = this.grabarItem2.bind(this);
    this.lista={};

    this.validarCuenta                         =this.validarCuenta.bind(this);
    this.sucessValidarCuenta                         =this.sucessValidarCuenta.bind(this);
    this.validarfechas                         =this.validarfechas.bind(this);
    this.validarOrden                         =this.validarOrden.bind(this);
    this.validarBodega                         =this.validarBodega.bind(this);
    this.sucessValidarBodega                         =this.sucessValidarBodega.bind(this);

    this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
    this.abrirImprimir                         =this.abrirImprimir.bind(this);

    this.operacion_actual='';


    this.isJson                         =this.isJson.bind(this);
    this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
    this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
    this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
    this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.procesar                         =this.procesar.bind(this);
    this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
    this.genearModalGlobales                         =this.genearModalGlobales.bind(this);
    this.traerItem                         =this.traerItem.bind(this);
    this.sucessTraerItems                         =this.sucessTraerItems.bind(this);

    this.fecha_hoy='';


    //nuevas
    this.mostrarMensajeAdicional                         =this.mostrarMensajeAdicional.bind(this);
    this.llenarSelectRete                         =this.llenarSelectRete.bind(this);
    this.suceesLlenarSelectRete                         =this.suceesLlenarSelectRete.bind(this);
    this.traerImpuestosNit                         =this.traerImpuestosNit.bind(this);
    this.sucessTraerImpuestos                         =this.sucessTraerImpuestos.bind(this);
    this.validarReteIva                         =this.validarReteIva.bind(this);
    this.validarReteICA                         =this.validarReteICA.bind(this);
    this.validarRetefuente                         =this.validarRetefuente.bind(this);
    this.validarCree                         =this.validarCree.bind(this);
    this.abrirAjustes                         =this.abrirAjustes.bind(this);
    this.abrirFechas                         =this.abrirFechas.bind(this);
    this.validarModalFechas                         =this.validarModalFechas.bind(this);
    this.traerOCWMSExterno   =this.traerOCWMSExterno.bind(this);
    this.cargarOrden   =this.cargarOrden.bind(this);
    this.sucessMostrarOrdenes =this.sucessMostrarOrdenes.bind(this);
    this.gripOptionesOrdenes = Object.assign({}, this.gridOptions);
    this.onSelectionChangedOrdenes                                  = this.onSelectionChangedOrdenes.bind(this);
    this.gripOptionesOrdenes['onSelectionChanged']           = this.onSelectionChangedOrdenes;
    this.gripOptionesDocumentos = Object.assign({}, this.gridOptions);
    this.onSelectionChangedDocumentos                                = this.onSelectionChangedDocumentos.bind(this);
    this.gripOptionesDocumentos['onSelectionChanged']           = this.onSelectionChangedDocumentos;
    this.gripOptionesUbicaciones= Object.assign({}, this.gridOptions);
    this.onSelectionChangedUbicaciones                              = this.onSelectionChangedUbicaciones.bind(this);
    this.gripOptionesUbicaciones['onSelectionChanged']           = this.onSelectionChangedUbicaciones;
    this.gridOptionsBodega= Object.assign({}, this.gridOptions);
    this.gridOptionsBodega['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsProductosProveedor= Object.assign({}, this.gridOptions);
    this.gridOptionsProductosProveedor['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsAuditoriaDiferencias= Object.assign({}, this.gridOptions);
    this.gridOptionsAuditoriaDiferencias['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsAuditoriaFaltantes= Object.assign({}, this.gridOptions);
    this.gridOptionsAuditoriaFaltantes['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsAuditoriaDemas= Object.assign({}, this.gridOptions);
    this.gridOptionsAuditoriaDemas['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsImpArti= Object.assign({}, this.gridOptions);
    this.gridOptionsImpArti['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsOtrosImp= Object.assign({}, this.gridOptions);
    this.gridOptionsOtrosImp['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsItemsPrecio= Object.assign({}, this.gridOptions);
    this.onSelectionChangedPrecios                              = this.onSelectionChangedPrecios.bind(this);
    this.gridOptionsItemsPrecio['onSelectionChanged']           = this.onSelectionChangedPrecios;

    this.gridOptionsItemsParciales= Object.assign({}, this.gridOptions);
    this.onSelectionChangedParciales                            = this.onSelectionChangedParciales.bind(this);
    this.gridOptionsItemsParciales['onSelectionChanged']           = this.onSelectionChangedParciales;
    this.gridOptionsImpuestosGeneral= Object.assign({}, this.gridOptions);
    this.gridOptionsImpuestosGeneral['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsNoUbicaciones= Object.assign({}, this.gridOptions);
    this.gridOptionsNoUbicaciones['onSelectionChanged']           = this.onSelectionChanged;

    this.validarDocumento                                  = this.validarDocumento.bind(this);
    this.definirOrden                                  = this.definirOrden.bind(this);
    this.mostrarOrdenes                                  = this.mostrarOrdenes.bind(this);
    this.validarDocumentosRecibidos                                  = this.validarDocumentosRecibidos.bind(this);
    this.sucessValidarDocumentosRecibidos                                  = this.sucessValidarDocumentosRecibidos.bind(this);
    this.cargarOrden                                  = this.cargarOrden.bind(this);
    this.cargarOrdenWms                                  = this.cargarOrdenWms.bind(this);
    this.sucessCargarOrden                                  = this.sucessCargarOrden.bind(this);
    this.validarOrdenWms                                  = this.validarOrdenWms.bind(this);
    this.cargarItems                                  = this.cargarItems.bind(this);
    this.sucessCargarItems                                  = this.sucessCargarItems.bind(this);
    this.abrirOrden                                  = this.abrirOrden.bind(this);
    this.calcularNeto                                  = this.calcularNeto.bind(this);

    this.modificarCabys                                  = this.modificarCabys.bind(this);
    this.sucessModificarCabys                                  = this.sucessModificarCabys.bind(this);
    this.mostrarCabys                                  = this.mostrarCabys.bind(this);
    this.modificarUbicacion                                  = this.modificarUbicacion.bind(this);
    this.sucessCambiarUbicacion                                  = this.sucessCambiarUbicacion.bind(this);
    this.cambiarUbicacion                                  = this.cambiarUbicacion.bind(this);
    this.traerUbicaciones                                  = this.traerUbicaciones.bind(this);


    this.saldosubicacion                                  = this.saldosubicacion.bind(this);
    this.successSaldosUbicacion                                  = this.successSaldosUbicacion.bind(this);
    this.traersaldoBodegas                                  = this.traersaldoBodegas.bind(this);
    this.successSaldosBodegas                                  = this.successSaldosBodegas.bind(this);
    this.successSalcalcularPrecioSugeridodosBodegas                                  = this.calcularPrecioSugerido.bind(this);
    this.existeUbicacion                                  = this.existeUbicacion.bind(this);
    this.sucessExisteubicacion                                  = this.sucessExisteubicacion.bind(this);
    this.validarUbicacionBodega                                  = this.validarUbicacionBodega.bind(this);
    this.mostrarMensajeValidandoInformacion                                  = this.mostrarMensajeValidandoInformacion.bind(this);


    this.successEliminarProveedores                                  = this.successEliminarProveedores.bind(this);
    this.confirmDeleteProveedores                                  = this.confirmDeleteProveedores.bind(this);
    this.eliminarRegistroProveedor                                  = this.eliminarRegistroProveedor.bind(this);
    this.setButtonDelete                                  = this.setButtonDelete.bind(this);
    this.sucessConsultarProductosProveedor                                  = this.sucessConsultarProductosProveedor.bind(this);
    this.consultarProductosProveedor                                  = this.consultarProductosProveedor.bind(this);


    this.sucessModificarProductoProveedor                                  = this.sucessModificarProductoProveedor.bind(this);
    this.modificarProductoProveedor                                  = this.modificarProductoProveedor.bind(this);
    this.sucessConsultarExisteProductoProveedor                                  = this.sucessConsultarExisteProductoProveedor.bind(this);
    this.consultarExisteProductoProveedor                                  = this.consultarExisteProductoProveedor.bind(this);
    this.calcularAjustes                                  = this.calcularAjustes.bind(this);
    this.setButtonBotonesTabla                                  = this.setButtonBotonesTabla.bind(this);
    this.sucessLlenarSelectDoc                                  = this.sucessLlenarSelectDoc.bind(this);
    this.traerDocumentos                                  = this.traerDocumentos.bind(this);
    this.sucessPropiedadBodega                                  = this.sucessPropiedadBodega.bind(this);
    this.abrirModalInfoDoc                                  = this.abrirModalInfoDoc.bind(this);
    this.suceesLlenarSelectInfo                                  = this.suceesLlenarSelectInfo.bind(this);
    this.llenarSelectInfo                                  = this.llenarSelectInfo.bind(this);
    this.cancelarModalInfoDoc                                  = this.cancelarModalInfoDoc.bind(this);
    this.validarDocSoporte                                  = this.validarDocSoporte.bind(this);
    this.sucessValidarRequiereOrden                                  = this.sucessValidarRequiereOrden.bind(this);
    this.validarRequiereOrden                                  = this.validarRequiereOrden.bind(this);
    this.abrirFletes                                  = this.abrirFletes.bind(this);
    this.validarFletes                                  = this.validarFletes.bind(this);
    this.abrirAuditoria                                  = this.abrirAuditoria.bind(this);
    this.sucessAuditoria                                  = this.sucessAuditoria.bind(this);
    this.sucessGrabarDatosCargue                                  = this.sucessGrabarDatosCargue.bind(this);
    this.validarModalDatosCargue                                  = this.validarModalDatosCargue.bind(this);
    this.borrarFletes                                  = this.borrarFletes.bind(this);
    this.traerInfoArticulo                         =this.traerInfoArticulo.bind(this);
    this.sucessTraerArticulo                         =this.sucessTraerArticulo.bind(this);
    this.sucessTraerArticulo2                         =this.sucessTraerArticulo2.bind(this);

    this.validarImpuestos                         =this.validarImpuestos.bind(this);
    this.validarCodigo                         =this.validarCodigo.bind(this);
    this.sucessValidarCodigo                         =this.sucessValidarCodigo.bind(this);
    this.crearProductoProveedor                         =this.crearProductoProveedor.bind(this);
    this.sucessCrearProductoProveedor                         =this.sucessCrearProductoProveedor.bind(this);
    this.sucessTraerImpuestosArticulos                         =this.sucessTraerImpuestosArticulos.bind(this);
    this.traerImpuestosArticulo                         =this.traerImpuestosArticulo.bind(this);
    this.calcularFactor                         =this.calcularFactor.bind(this);
    this.abrirOtrosImpuestos                         =this.abrirOtrosImpuestos.bind(this);

    this.consultarOtrosImpuestos                         =this.consultarOtrosImpuestos.bind(this);
    this.sucessTraerOtrosImpuestos                         =this.sucessTraerOtrosImpuestos.bind(this);
    this.setButtonDeleteImp                         =this.setButtonDeleteImp.bind(this);
    this.eliminarRegistroImp                         =this.eliminarRegistroImp.bind(this);
    this.confirmDeleteImp                         =this.confirmDeleteImp.bind(this);
    this.successEliminarImp                         =this.successEliminarImp.bind(this);
    this.limpiarCamposImp                         =this.limpiarCamposImp.bind(this);
    this.traerValoresSwitch                         =this.traerValoresSwitch.bind(this);
    this.successTraerValoresSwitch                         =this.successTraerValoresSwitch.bind(this);

    this.modificarImpArt                         =this.modificarImpArt.bind(this);
    this.successModificarImpArt                         =this.successModificarImpArt.bind(this);
    this.crearImpArt                         =this.crearImpArt.bind(this);
    this.successCrearImpArt                         =this.successCrearImpArt.bind(this);
    this.validarSiExisteImpArt                         =this.validarSiExisteImpArt.bind(this);
    this.successValidarSiExisteImpArt                         =this.successValidarSiExisteImpArt.bind(this);
    this.sucessModificarPrecio                         =this.sucessModificarPrecio.bind(this);
    this.actualizarPrecio                         =this.actualizarPrecio.bind(this);
    this.abrirDatosCargue                         =this.abrirDatosCargue.bind(this);
    this.validarCodigoAlterno                         =this.validarCodigoAlterno.bind(this);
    this.traerCostoBodegaYAdmn                         =this.traerCostoBodegaYAdmn.bind(this);
    this.sucessTraerCostoBodegaYAdmn                         =this.sucessTraerCostoBodegaYAdmn.bind(this);
    this.contarProductosOrdenWms                         =this.contarProductosOrdenWms.bind(this);
    this.sucessContarProductos                         =this.sucessContarProductos.bind(this);
    this.sucessGrabarBarras                         =this.sucessGrabarBarras.bind(this);
    this.grabarBarras2                         =this.grabarBarras2.bind(this);
    this.grabarBarras1                         =this.grabarBarras1.bind(this);
    this.validarBarras                         =this.validarBarras.bind(this);
    this.sucessGetCosto                         =this.sucessGetCosto.bind(this);
    this.getCosto                         =this.getCosto.bind(this);
    this.successSugerirCantidad                         =this.successSugerirCantidad.bind(this);
    this.sugerirCantidad                         =this.sugerirCantidad.bind(this);
    this.pedirDescuentos                         =this.pedirDescuentos.bind(this);

    this.validarDescuentos                         =this.validarDescuentos.bind(this);
    this.validarRequiereOrdenGrabar                         =this.validarRequiereOrdenGrabar.bind(this);
    this.sucessValidarRequiereOrdenGrabar                         =this.sucessValidarRequiereOrdenGrabar.bind(this);
    this.successGetNovedad                         =this.successGetNovedad.bind(this);
    this.abrirNovedades                         =this.abrirNovedades.bind(this);
    this.successEliminarNovedad                         =this.successEliminarNovedad.bind(this);
    this.eliminarNovedad                         =this.eliminarNovedad.bind(this);
    this.sucessAceptarNovedad                         =this.sucessAceptarNovedad.bind(this);
    this.actualizarNovedad                         =this.actualizarNovedad.bind(this);

    this.abrirModificarPrecios                         =this.abrirModificarPrecios.bind(this);
    this.successGetPrecios                         =this.successGetPrecios.bind(this);
    this.abrirModalPedirPrecio                         =this.abrirModalPedirPrecio.bind(this);
    this.sucessModificarPrecioArticulo                         =this.sucessModificarPrecioArticulo.bind(this);
    this.modificarPrecioArticulo                         =this.modificarPrecioArticulo.bind(this);
    this.grabarCompra                         =this.grabarCompra.bind(this);
    this.successGrabarCompra                         =this.successGrabarCompra.bind(this);
    this.cerrarOrden2                         =this.cerrarOrden2.bind(this);
    this.cerrarOrden                         =this.cerrarOrden.bind(this);
    this.sucessCerrarOrden                         =this.sucessCerrarOrden.bind(this);
    this.abrirObservacionesMercancia                         =this.abrirObservacionesMercancia.bind(this);
    this.sucessObservacionesMercancia                         =this.sucessObservacionesMercancia.bind(this);
    this.guardarObservacionesMercancia                         =this.guardarObservacionesMercancia.bind(this);

    this.totalizar                         =this.totalizar.bind(this);
    this.successTotalizar                         =this.successTotalizar.bind(this);
    this.guardarMercanciaParcial                         =this.guardarMercanciaParcial.bind(this);
    this.sucessGuardarMercanciaParcial                         =this.sucessGuardarMercanciaParcial.bind(this);
    this.traerItemsParcial                         =this.traerItemsParcial.bind(this);
    this.successGetItemsParciales                         =this.successGetItemsParciales.bind(this);
    this.modificarMercanciaParcial                         =this.modificarMercanciaParcial.bind(this);
    this.sucessObservacionesMercancia                         =this.sucessObservacionesMercancia.bind(this);
    this.succesImpuestosGeneral                         =this.succesImpuestosGeneral.bind(this);
    this.abrirImpuestosGeneral                         =this.abrirImpuestosGeneral.bind(this);
    this.traerArticuloBarras                         =this.traerArticuloBarras.bind(this);
    this.sucessTraerArticuloBarras                         =this.sucessTraerArticuloBarras.bind(this);
    this.validarNuevasUbicacionesWms                         =this.validarNuevasUbicacionesWms.bind(this);
    this.sucessValidarUbicacionesWms                         =this.sucessValidarUbicacionesWms.bind(this);
    this.traerDatosModificarPrecios                   =this.traerDatosModificarPrecios.bind(this);
    this.successGetItemsParcialesTodos                         =this.successGetItemsParcialesTodos.bind(this);
    this.traerItemsParcialTodos                   =this.traerItemsParcialTodos.bind(this);
    this.limpiarCamposArticulo                   =this.limpiarCamposArticulo.bind(this);
    this.changeKeyUpCustom                  = this.changeKeyUpCustom.bind(this);
    this.recorrerErrores                  = this.recorrerErrores.bind(this);

    this.consultarXml                                             = this.consultarXml.bind(this);
    this.consultarDocumentos                                      = this.consultarDocumentos.bind(this);
    this.validarfechasDocReci                                     = this.validarfechasDocReci.bind(this); 
    this.successConsulta                                          = this.successConsulta.bind(this);
    this.gridOptionsDoc                                           = Object.assign({},this.gridOptions);
    this.onSelectionChangedRowTable2                              = this.onSelectionChangedRowTable2.bind(this);
    this.selectedRows                                             = [];
    this.datosLista                                               = [];
    this.documentos_recibidos_id                                  = '';
    this.successDocumentosRecibidosXml                            = this.successDocumentosRecibidosXml.bind(this);
    this.gridOptionsDocumentosRecibidosXml                        = Object.assign({},this.gridOptions);
    this.onSelectionChangedRowTable3                              = this.onSelectionChangedRowTable3.bind(this);
    this.selectedRowsCompra                                       = [];
    this.manejaDecimales                                          = this.manejaDecimales.bind(this);
    this.successManejaDecimales                                   = this.successManejaDecimales.bind(this);
    this.numeroDecimales                                          = 0;
    this.successOrdenCompra                                       = this.successOrdenCompra.bind(this);
    this.gridOptionsOrdenCompras                                  = Object.assign({},this.gridOptions);
    this.definirOrdenXml                                          = this.definirOrdenXml.bind(this);

    this.onSelectionChangedOrdenesXml                             = this.onSelectionChangedOrdenesXml.bind(this);
    this.gridOptionsOrdenCompras['onSelectionChanged']            = this.onSelectionChangedOrdenesXml;
    this.cargarOrdenXml                                           = this.cargarOrdenXml.bind(this);
    this.traerPropiedades2                                        = this.traerPropiedades2.bind(this);
    this.sucessTraerPropiedades2                                  = this.sucessTraerPropiedades2.bind(this);
    this.crearActividadProceso                                    = this.crearActividadProceso.bind(this);
    this.sucessCrearActividadProceso                              = this.sucessCrearActividadProceso.bind(this);

    //propiedades
    this.existe_ubicaciones='';
    this.retefuente='';
    this.reteiva='';
    this.reteica='';
    this.tarifa_reteica='';
    this.tarifa_cree_compras='';
    this.tarifa_reteiva='';
    this.proveedor_tipo_dias_vencimiento='';
    this.dias_vence_proveedor=0;
    this.maneja_wms_externo='';
    this.wms_externo_bodega='';
    this.wms='';
    this.wms_nuevo='';
    this.docSoporteCri='';
    this.facturacion_electronica_propia='';
    this.row_orden=[];
    this.row_orden_xml=[];
    this.row_documento=[];
    this.row_ubicacion=[];
    this.fila_seleccionada=[];
    this.requiere_orden_compra='';
    this.precio_incluye_iva='';
    this.compraDsCri=false;
    this.regimen='';
    this.calcula_iva_item='';
    this.xsucursal='';
    this.lasucursal='';
    this.moneda_empresa='';
    this.existe_impuestos=false;
    this.existe_impuestos_articulo=false;
    this.cons_impart=false;
    this.cargue_compras=false;
    this.smartvv='';
    this.compras_wms='';
    this.costo_por_bodega='';
    this.costo_administrativo='';
    this.asigna_precio='';
    this.row_precios=[];
    this.row_parciales=[];
    this.bodega_mandato='';
    this.numero_decimales_compras=0;
    this.exigir_orden='';
    this.maneja_bodega_ubicacion='';

    //auxiliares
    this.nit_responsable='';
    this.sede_responsable='';
    this.nombre_responsable='';
    this.numero_factura_proveedor='';
    this.tipo_de_cambio='';
    this.select_condicion_venta=' ';
    this.select_medio_pago=' ';
    this.select_moneda=' ';
    this.select_actividad_economica=' ';
    this.observaciones_doc='';
    this.modificando='false';
    this.fecha_vencimiento='';
    this.count=0;
    
    this.nit_fletes='';
    this.sede_fletes='';
    this.nombre_fletes='';
    this.valor_fletes='';
    this.tarifa_fletes='';
    this.ch_facturado='false';
    this.ch_cantidad='false';
    this.ch_valor='false';
    this.ch_peso='false';
    this.no_orden_aux='';
    this.factura_numero_aux='';
    this.fecha_servidor_aux='';

    this.valor_retenido_fletes='';
    this.calculo_factor=false;
    this.cantidad_escaneada=false;
    this.fecha_emision_actual='';
    this.calcular_cree_automatico='';
    this.rsSinIva='';
    this.prioridad_barras='';
    this.cambiar_prioridad=false;
    this.operacion_seleccionada='';
    this.validar_documentos_recibidos=true;


    //descuentos ( se guardan porque al cerrar el modal se limpian los campos)
    this.valor_descuento1=0;
    this.valor_descuento2=0;
    this.valor_descuento3=0;
    this.valor_descuento_directo=0;
    this.valor_descargue=0;
    this.fecha1='';
    this.fecha2='';
    this.fecha3='';
    this.valor_anterior='0';
    this.calculando_descuento=false;

    this.respuesta_completa=false;
    this.datos_articulo_aux=[];
    this.orden_wms=false;
    this.limpiar_numero=true;
    this.no_limpiar_mod=true;
    this.numero_orden_compra = '0';
    this.numero_documento_xml = '0';
    this.fecha_vence_xml = new Date().toISOString().split('T')[0];
    this.porcentaje_diferencia=0;
  }

  initForm() {
    console.log("Formulario CustomGrabacionDeCompras,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez, @update: Santiago Hernández");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.traerPropiedades();
    this.traerPropiedades2();
    this.llenarSelectInfo();
    this.llenarSelectRete();
    this.getField('label_tipo').setDisabled(true);
    /*     this.getField('bt_reteiva').setClick(this.validarReteIva);
        this.getField('bt_reteica').setClick(this.validarReteICA);
    this.getField('bt_retefuente').setClick(this.validarRetefuente); */
    /* this.getField('bt_cree').setClick(this.validarCree); */

    /* this.getField('bt_fecha').setClick(this.abrirFechas); */
    this.getField('bt_aceptar_fechas').setClick(()=>{this.validarModalFechas();});
    this.getField("fecha_vencimiento").setOnChange(()=>{this.validarfechas();});

    this.getField('bt_orden').setClick(this.abrirOrden);

    //modal descuentos
    this.getField('bt_aceptar_descuentos').setClick(()=>{this.validarDescuentos();});

    //modal pregunta
    this.getField('bt_cancelar_pregunta').setClick(()=>{this.getField('modal_pregunta').handleClose();this.cargarOrden(false,false)});
    this.getField('bt_aceptar_pregunta').setClick(()=>{this.getField('modal_pregunta').handleClose();this.cargarOrden(false,false);this.getField('modal_barras').handleClickOpen();});

    //modal barras
    this.getField('bt_cancelar_barras').setClick(()=>{this.getField('modal_barras').handleClose();});
    this.getField('bt_aceptar_barras').setClick(()=>{this.validarBarras()});
    /*this.getField('nombre_articulo_barras').setOnChange(()=>{
      this.getField('nombre_articulo_barras').setError(false,'')
      if(this.getField('nombre_articulo_barras').getValue()!==''){
        this.traerArticuloBarras();
      }
    });*/
    this.getField("barras_articulo").setCustomSuccess(()=>{
      this.getField('nombre_articulo_barras').setError(false,'');
      this.traerArticuloBarras();
    });



    //actualizar precio
    this.getField('bt_actualizar_precio').setClick(this.actualizarPrecio);

    //datos de cargue
   /*  this.getField('bt_datos_cargue').setClick(this.abrirDatosCargue); */
    this.getField("fecha_factura").setOnChange(()=>{this.validarfechasDatosCargue();});
    this.getField('bt_cargue_cancelar').setClick(()=>{this.getField('modal_datos_cargue').handleClose();});
    this.getField('bt_cargue_grabar').setClick(()=>{this.validarModalDatosCargue();});

    //auditoria
    /* this.getField('bt_auditoria').setClick(this.abrirAuditoria); */

    //fletes
   /*  this.getField('bt_fletes').setClick(this.abrirFletes); */
    this.getField('bt_flete_aceptar').setClick(this.validarFletes);
    this.getField('bt_borrar_fletes').setClick(this.borrarFletes);
    this.getField('bt_flete_cancelar').setClick(()=>{this.getField('modal_fletes').handleClose();});
    this.getField('ch_cantidad').setOnChange((props)=>{
      if(this.getField('ch_cantidad').getValue()==='false'){
        this.getField('ch_valor').setValueSwitch (false);
        this.getField('ch_peso').setValueSwitch (false);
      }
    });
    this.getField('ch_valor').setOnChange((props)=>{
      if(this.getField('ch_valor').getValue()==='false'){
        this.getField('ch_cantidad').setValueSwitch (false);
        this.getField('ch_peso').setValueSwitch (false);
      }
    });
    this.getField('ch_peso').setOnChange((props)=>{
      if(this.getField('ch_peso').getValue()==='false'){
        this.getField('ch_cantidad').setValueSwitch (false);
        this.getField('ch_valor').setValueSwitch (false);
      }
    });
    this.getField('valor_fletes').setOnChange((props)=>{
      if(this.getField('valor_fletes').valid())
        this.getField('valor_retencion_flete').setValue(this.getField('valor_fletes').getValue()*this.getField('retencion_fletes').getValue()/100.0);
      else
        this.getField('valor_retencion_flete').setValue('0');
    });
    this.getField('retencion_fletes').setOnChange((props)=>{
      if(this.getField('retencion_fletes').valid())
        this.getField('valor_retencion_flete').setValue(this.getField('valor_fletes').getValue()*this.getField('retencion_fletes').getValue()/100.0);
      else
        this.getField('valor_retencion_flete').setValue('0');
    });

    this.getField('ch_facturado').setOnChange((props)=>{
      if(this.getField('ch_facturado').getValue()==='false'){
        this.getField('nit_fletes').setValue(this.getField('nit').getValue());
        this.getField('sede_fletes').setValue(this.getField('sede').getValue());
        this.getField('nombre_fletes').setValue(this.getField('nombre').getValue());
      }
    });

    this.getField('sede_fletes').setOnChange((props)=>{
      if(this.getField('sede_fletes').getValue()==='' || this.getField('sede_fletes').getValue()===' ')
        this.getField('ch_facturado').setValueSwitch(false);
    });

    

    //otros impuestos
    this.getField('bt_impuestos_articulo').setClick(this.abrirOtrosImpuestos);
    this.getField('impuesto_id').setOnChange(this.traerValoresSwitch);
    this.getField('bt_cancelar_impuesto').setClick(this.limpiarCamposImp);
    this.getField('bt_aceptar_impuesto').setClick(this.validarSiExisteImpArt);

    //ajustes
    /* this.getField('bt_ajustes').setClick(this.abrirAjustes); */
    this.getField('bt_ok_ajustes').setClick(()=>{
        if(this.getField('valor_total_factura').valid())
        this.calcularAjustes();
    });
    this.getField('bt_cancelar_ajustes').setClick(()=>{this.getField('modal_ajustes').handleClose();});

     //ordenes wms
    this.getField('bt_aceptar_orden').setClick(this.validarOrdenWms);
    this.getField('bt_cancelar_orden').setClick(()=>{this.getField('modal_wms_orden').handleClose()});
    this.getField('modal_wms_orden').setCloseButton(()=>{
      this.validarDocumentosRecibidos();
    });
     //ordenes normal
    this.getField('bt_aceptar_orden_n').setClick(this.definirOrden);
    this.getField('bt_cancelar_orden_n').setClick(()=>{this.getField('modal_orden_normal').handleClose()});
    this.getField('modal_orden_normal').setCloseButton(()=>{
      this.validarDocumentosRecibidos();
    });

    this.getField('aceptar_orden').setClick(this.definirOrdenXml);
    
    //documentos
    this.getField('bt_aceptar_documentos').setClick(this.validarDocumento);
    //this.getField('bt_cancelar_documentos').setClick(()=>{this.getField('modal_documentos').handleClose()});

    //ubicaciones
    this.getField('bt_aceptar_ubicacion').setClick(this.modificarUbicacion);
    this.getField('bt_cancelar_ubicacion').setClick(()=>{this.getField('modal_ubicaciones').handleClose()});

    //ucabys
    this.getField('bt_aceptar_cabys').setClick(this.modificarCabys);
    this.getField('bt_cancelar_cabys').setClick(()=>{this.getField('modal_cabys').handleClose()});

    //modal novedades

    this.getField('bt_cancelar_novedad').setClick(()=>{this.getField('modal_novedades').handleClose()});
    this.getField('bt_eliminar_novedad').setClick(()=>{this.eliminarNovedad()});
    this.getField('bt_aceptar_novedad').setClick(()=>{this.actualizarNovedad()});
    this.getField('ch_modificar_cantidad').setDisabled(true);
    this.getField('ch_modificar_cantidad').setOnChange((props)=>{
      if(this.getField('ch_modificar_cantidad').getValue()==='false'){
        this.getField('ch_generar_ajuste').setValueSwitch (false);
      }
    });
    this.getField('ch_generar_ajuste').setOnChange((props)=>{
      if(this.getField('ch_generar_ajuste').getValue()==='false'){
        this.getField('ch_modificar_cantidad').setValueSwitch (false);
      }
    });

   /*  this.getField('valor_neto').setOnChange((props)=>{ this.getField('valor_neto').setError(false,'');}); */


    //info documento
    this.getField('bt_info_doc').setClick(this.abrirModalInfoDoc);
    this.getField('bt_info_doc_cancelar').setClick(()=>{this.cancelarModalInfoDoc();});
    this.getField('bt_info_doc_guardar').setClick(this.validarDocSoporte);
    this.getField('select_moneda').setOnChange(()=>{
      if(this.getField('select_moneda').getValue()===this.moneda_empresa){
        this.getField('tipo_de_cambio').setValue(1.00);
        this.getField('tipo_de_cambio').setError(false,'');
        this.getField('tipo_de_cambio').setDisabled(true);
      }else{
        this.getField('tipo_de_cambio').setDisabled(false);
      }
    });
    this.getField('sede_responsable').setOnChange((props)=>{
      this.getField('sede_responsable').setError(false,'');
      this.getField('nombre_responsable').setError(false,'');
    });
    this.getField('nombre_articulo_proveedor').setOnChange((props)=>{
      this.getField('nombre_articulo_proveedor').setError(false,'');
    });


    //botones tabla principal
    this.getField('cambiar_ubicacion').setClick(()=>{this.cambiarUbicacion();});
    this.getField('modificar_cabys').setClick(()=>{ this.mostrarCabys();});
    this.getField('agregar_item').setClick(this.abrirModalAgregar);
    this.getField('bt_novedades').setClick(()=>{this.abrirNovedades();})
    this.getField('bt_impuestos').setClick(()=>{this.abrirImpuestosGeneral();})
    

/*     this.getField('select_retencion').setOnChange(()=>{
      if(this.getField('select_retencion').getValue()!=='' && this.getField('select_retencion').getValue()!==' ')
        this.validarRetefuente();
    }); */
    
/*     this.getField('select_retencion').setOnBlur(()=>{
      this.getField('select_retencion').setError(false,'');
    }); */

    this.getField('select_direccion').setOnChange(()=>{
      if(this.getField('select_direccion').getValue()!=='' && this.getField('select_direccion').getValue()!==' '){
        this.validarBodega();
      }
    });

    this.getField('comenzar').setClick(this.comenzarForm);

    this.getField('sede').setOnChange((props)=>{
      this.getField('modificar_cabys').setVisible(false);
      this.getField('sede').setError(false,'');
      this.getField('nombre').setError(false,'');
      this.getField('responsable_iva').setError(false,'');
      if(this.getField('no_orden').getValue()!=='0'){
        this.limpiarCampos();
      }
      this.getField('no_orden').setValue('0');
      this.getField('no_orden').setDisabled(false);

      this.getField('factura_numero').setValue('0');

      if(this.getField('sede').getValue()!=='' && this.getField('sede').getValue()!==' '){
        this.validarCuenta();
      }
    });
    this.getField('nombre_articulo').setOnChange((props)=>{
      if(this.getField('nombre_articulo').getValue()==='' || this.getField('nombre_articulo').getValue()===' '){
        this.getField('rejilla_ubicacion').toggle(false);
        this.getField('rejilla_bodega').toggle(false);
        this.getField('rejilla_impuestos_articulo').toggle(false);
      }
    });

    this.getField('costo').setOnChange((props)=>{

    this.getField('nombre_articulo').setError(false,'');
        this.getField('tarifa_iva').setError(false,'');
        this.getField('responsable_iva').setError(false,'');
        if(this.getField('costo').getValue()!=='' && this.getField('costo').getValue()!==' '){
          this.validarCodigoAlterno();
          this.count+=1;
        }
    });

    this.getField("codigo_articulo_aux").setOnChange(()=>{
      if(this.getField('codigo_articulo_aux').getValue()!=='' && this.prioridad_barras==='S' && this.cambiar_prioridad!==false){
          this.validarCodigo();
      }else if(this.prioridad_barras!=='S'){
          //aquí­ no tiene prioridad entonces trae directamente el artículo
          if(this.getField('codigo_articulo_aux').getValue()!=='' && this.respuesta_completa===true && this.datos_articulo_aux.length<=1){
            this.sucessTraerArticulo2(this.datos_articulo_aux);
          }else if(this.datos_articulo_aux.length>1 && this.getField("codigo_articulo_aux").getValue()===this.getField("codigo_articulo").getValue()){
            this.sucessTraerArticulo2(this.datos_articulo_aux);
          }
      }
    });

    this.getField("codigo_articulo").setOnChange(()=>{
        this.respuesta_completa=false;
        this.getField('nombre_articulo').setError(false,'');
        this.limpiarCamposArticulo();
    });

    this.getField("codigo_articulo").setCustomSuccess((props)=>{
      this.datos_articulo_aux=props;
      this.respuesta_completa=true;
      if(this.prioridad_barras!=='S'){
        //para forzar a consumir de nuevo el onchange
        this.getField("codigo_articulo_aux").setValue('cod_aux');
      }
      if(props.length<=1 && this.operacion_seleccionada!=='F9'){
          if(this.prioridad_barras==='S'){
              this.cambiar_prioridad=false;
              this.validarCodigo();
          }else{
              this.cambiar_prioridad=true;
          }
      }else{
          this.cambiar_prioridad=true;
      }
    });

    //sobreescrivir keyups
    this.getField('codigo_articulo').setKeyUp((e)=>{this.changeKeyUpCustom(e)});

    this.getField('descuento').setOnBlur(this.calcularDescuento);
    this.getField('precio_sin_iva').setOnChange(()=>{
        //se pone en falso ya que se cambio el costo
        this.calculo_factor=false;
        if(this.calculando_descuento===false && this.valor_anterior!==this.getField('precio_sin_iva').getValue()){
          this.getField('descuento').setDisabled(false);
          this.getField('descuento').setValue(0);
        }else{
          this.valor_anterior=this.getField('precio_sin_iva').getValue();
        }
        this.calcularItem();
    });
    this.getField('rentabilidad').setOnChange(()=>{this.calcularPrecioSugerido();});
    this.getField('cantidad_articulo').setOnChange(this.calcularItem);
    this.getField('impuestos2').setOnChange(this.calcularItem);
    this.getField('cancelar_agregar').setClick(()=>{this.getField('modal_agregar').handleClose()});
    this.getField('grabar_agregar').setClick(this.validacionesAgregar);

    //modal productos proveedor
    this.getField('br_consultar_proveedor').setClick(this.consultarProductosProveedor);
    this.getField('bt_procesar_proveedor').setClick(this.consultarExisteProductoProveedor);

    //grabar orden
    this.getField('grabar').setClick(this.validarOrden);


    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;
  


      //imprimir
      this.getField('radio_correo').setValue("N");
      this.getField('generar_archivo').setClick(()=>this.procesar());

      this.getField('radio_correo').setOnChange((props)=>{
        if(this.getField('radio_correo').getValue()==='N'){
          this.getField('email').setDisabled(true);
          this.getField('email').setValue('');
          this.getField('email').setError(false,'');
        }else{
          this.getField('email').setDisabled(false);
          this.getField('email').setValue('');
          this.getField('email').setError(false,'');
        }
      });
      //para balances globales
      this.getField('ch_excel').setOnChange((props)=>{
        if(this.getField('ch_excel').getValue()==='false'){
          this.getField('ch_pdf').setValueSwitch (false);
          this.getField('ch_pantalla').setValueSwitch (false);
          this.operacion_actual='excel';
          this.getField('radio_correo').setValue("N");
        }
      });
      this.getField('ch_pdf').setOnChange((props)=>{
        if(this.getField('ch_pdf').getValue()==='false'){
          this.getField('ch_excel').setValueSwitch (false);
          this.getField('ch_pantalla').setValueSwitch (false);
          this.operacion_actual='pdf';
          this.getField('radio_correo').setValue("N");
        }
      });
      this.getField('ch_pantalla').setOnChange((props)=>{
        if(this.getField('ch_pantalla').getValue()==='false'){
          this.getField('ch_excel').setValueSwitch (false);
          this.getField('ch_pdf').setValueSwitch (false);
          this.operacion_actual='pantalla';
          this.getField('radio_correo').setValue("N");
        }else{
          this.getField('radio_correo').setValue("N");
        }
      });
      this.getField('modal_imprimir').setCloseButton(()=>{
        this.limpiarCampos();
        this.getField('nit').setValue('');
        this.getField('sede').setValue('');
        this.getField('nombre').setValue('');
        this.getField('responsable_iva').setValue('');
      });


      this.getField('btn_xml').setClick(this.consultarXml);
      this.getField("consultar").setClick(this.consultarDocumentos);

      this.getField('diferencia_total_subtotal').setValue(0);
      this.getField('diferencia_valor_iva').setValue(0);
      this.getField('diferencia_total_factura').setValue(0);
      this.getField('total_subtotal_xml').setValue(0);
      

  }

  changeKeyUpCustom = (e) =>{
      let keycode = (e.keyCode ? e.keyCode : e.which);
      //keycode 120 = f9_servicio;
      //keycode 13 = enter_servicio;
      if(keycode === 120){
          this.operacion_seleccionada = "F9";
      }else{
          this.operacion_seleccionada = "ENTER-BOTON"; 
      }
      this.getField("codigo_articulo").changeKeyUp(e);
  }

  limpiarCamposArticulo(){
    this.getField('nombre_articulo').setError(false,'');

    this.getField('nombre_articulo').setValue('');
    this.getField('contenido').setValue('');
    this.getField('presentacion').setValue('');
    this.getField('tarifa_iva').setValue('');
    this.getField('descuento').setValue('0');
    if(this.no_limpiar_mod)
      this.no_limpiar_mod=false;
    else{
      //this.getField('precio_sin_iva').setValue('0');
      this.getField('cantidad_articulo').setValue('0');
      this.getField('impuestos2').setValue('0');
      this.getField('ubicacion_agregar').setValue('999999999999');
    }
    this.getField('rejilla_ubicacion').toggle(false);
    this.getField('rejilla_bodega').toggle(false);
    this.getField('rentabilidad').setValue('');
    this.getField('precio').setValue('')
   }

  //nuevas funciones

  validarNuevasUbicacionesWms(){
    this.mostrarMensajeValidandoInformacion();
    let datos={ datos: {
        xsucursal:this.xsucursal,
        sucursal_ingreso:this.sucursal_ingreso,
        select_direccion:this.getField('select_direccion').getValue(),
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'validarubicacioneswms', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessValidarUbicacionesWms,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    }

    sucessValidarUbicacionesWms(data){
    this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200 )
      {    

      }else {
          if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
          }
          else {
              if(data.data.errores_ubicaciones_wms!==undefined)
                this.mostrarMensajeAdicional(data.data.errores_ubicaciones_wms);
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          }
      }
  }

  abrirImpuestosGeneral(){
    this.mostrarMensajeGenerando();
    this.getField('bt_impuestos').setDisabled(true);
    let datos={ datos: {
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_impuestos_general', operacion_tipo: 'consulta' };
    this.service.send({
        endpoint: this.constant.formConfiguration(),
        method:'GET',
        body: datos,
        success: this.succesImpuestosGeneral,
        error: this.error_,
        general: this.generalFormatPmv,
        showMessage:false
    });
  }

  succesImpuestosGeneral(data)
  {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('bt_impuestos').setDisabled(false);
    if(data.estado_p===200){
      let configCell = new Map();
      this.gridOptionsImpuestosGeneral['rowData'] = data.data;
      configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});

      this.getField('rejilla_impuestos_general').toggle(true);
      this.getField('rejilla_impuestos_general').initData(this.gridOptionsImpuestosGeneral, configCell);
      this.getField('modal_impuestos_general').handleClickOpen();
    }else if(data.estado_p === 404){
      this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  totalizar(){
    if(this.getField('nuevo_ajuste').valid()){
        this.getField('bt_modificar_ajuste').setDisabled(true);
        this.mostrarMensajeGuardando();
          let datos={ datos: {
            codigo_articulo:this.row_parciales[0].codigo_articulo,
            nuevo_ajuste:this.getField('nuevo_ajuste').getValue(),        
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'modificarparcial', operacion_tipo: 'modificar' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'PUT',
                  body: datos,
                  success: this.successTotalizar,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
      }
  }

  successTotalizar(data) {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('bt_modificar_ajuste').setDisabled(false);
    if(data.estado_p === 200){
        //this.mostrarMensajeAdicional(data.data.mensaje);
        this.getField('total_codigos').setValue(data.data.total_codigos);
        this.getField('total_cantidad').setValue(data.data.total_cantidad);
        this.getField('modal_confirmacion_ajuste').handleClose();
        this.traerItemsParcialTodos();
    }else {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
  }

  traerItemsParcialTodos(){
    //traer items parciales
      this.mostrarMensajeGenerando();
      let datos={ datos: {
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_todosparciales', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.successGetItemsParcialesTodos,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
}

successGetItemsParcialesTodos(data)
{
  this.getField('confirmModalCustom').toggle(false);
  if(data.estado_p===200){
    let configCell = new Map();
    this.gridOptionsItemsParciales['rowData'] = data.data;
    configCell.set('cantidad_compra', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_compra) }, cellStyle: {textAlign:"right"},width: 110});
    configCell.set('cantidad_ajuste', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_ajuste) }, cellStyle: {textAlign:"right"},width: 110});

    this.getField('rejilla_mercancia').toggle(true);
    this.getField('rejilla_mercancia').initData(this.gridOptionsItemsParciales, configCell);
  }else if(data.estado_p === 404){
    this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
  }else {
    let respuesta=Object.values(data.data.errores);
    let keys=Object.keys(data.data.errores);
    this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
  }
}

  traerItemsParcial(){
      //traer items parciales
        this.mostrarMensajeGenerando();
        let datos={ datos: {
          no_orden: this.no_orden_aux,
          factura_numero:this.factura_numero_aux,
          xfecha:this.fecha_servidor_aux,
          xsucursal:this.xsucursal,
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'itemsmercanciaparcial', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successGetItemsParciales,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
  }

  onSelectionChangedParciales(){
    this.row_parciales = this.gridOptionsItemsParciales.api.getSelectedRows();
  }

  successGetItemsParciales(data)
  {
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p===200){
      let configCell = new Map();
      this.gridOptionsItemsParciales['rowData'] = data.data;
      configCell.set('cantidad_compra', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_compra) }, cellStyle: {textAlign:"right"},width: 110});
      configCell.set('cantidad_ajuste', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_ajuste) }, cellStyle: {textAlign:"right"},width: 110});

      this.getField('rejilla_mercancia').toggle(true);
      this.getField('rejilla_mercancia').initData(this.gridOptionsItemsParciales, configCell);
    }else if(data.estado_p === 404){
      this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  modificarMercanciaParcial(){
    if(this.row_parciales.length>0){
      this.getField('modal_confirmacion_ajuste').handleClickOpen();
      this.getField('nuevo_ajuste').setValue(this.row_parciales[0].cantidad_ajuste);
      this.getField('bt_cancelar_ajuste').setClick(()=>{this.getField('modal_confirmacion_ajuste').handleClose();});
      this.getField('bt_modificar_ajuste').setClick(()=>{this.totalizar();});
    }else{
      this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    }
  }

  guardarMercanciaParcial(){
    if(this.getField('observaciones_mercancia_parcial').valid()){
      if(this.getField('total_codigos').getValue()<=0 || this.getField('total_cantidad').getValue()<=0){
        this.alertGeneral.toggle(true, 'No hay codigos con cantidades de ajuste.', 'error');
      }else{
        this.getField('bt_aceptar_parcial').setDisabled(true);
        this.mostrarMensajeGuardando();
          let datos={ datos: {
            xsucursal:this.xsucursal,
            no_orden: this.no_orden_aux,
            factura_numero:this.factura_numero_aux,
            xfecha:this.fecha_servidor_aux,
            observaciones_mercancia_parcial:this.getField('observaciones_mercancia_parcial').getValue(),
            
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'observacionesmercanciaparcial', operacion_tipo: 'crear' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'POST',
                  body: datos,
                  success: this.sucessGuardarMercanciaParcial,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
      }
    }
  }

  sucessGuardarMercanciaParcial(data) {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('bt_aceptar_parcial').setDisabled(false);
    if(data.estado_p === 200){
        this.mostrarMensajeAdicional(data.data.mensaje);
        this.getField('modal_mercancia_parcial').handleClose();
    }else {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
  }

  guardarObservacionesMercancia(){
    if(this.getField('observaciones_mercancia').valid()){
      this.getField('bt_aceptar_obs_mercancia').setDisabled(true);
      this.mostrarMensajeGuardando();
        let datos={ datos: {
          xsucursal:this.xsucursal,
          
          no_orden: this.no_orden_aux,
          factura_numero:this.factura_numero_aux,
          xfecha:this.fecha_servidor_aux,
          observaciones_mercancia:this.getField('observaciones_mercancia').getValue(),
          
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'observacionesmercancia', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessObservacionesMercancia,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
  }

  sucessObservacionesMercancia(data) {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('bt_aceptar_obs_mercancia').setDisabled(false);
    if(data.estado_p === 200){
        this.mostrarMensajeAdicional(data.data.mensaje);
        this.getField('modal_observaciones_mercancia').handleClose();
    }else {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
  }

  abrirObservacionesMercancia(){
    if(this.bodega_mandato===this.getField('select_direccion').getValue()){
      this.getField('modal_pregunta_ajuste').handleClickOpen();
      this.getField('bt_ajuste_total').setClick(()=>{
        this.getField('modal_pregunta_ajuste').handleClose();
        this.getField('modal_observaciones_mercancia').handleClickOpen();
        this.getField('observaciones_mercancia').setValue('');
        this.getField('bt_cancelar_obs_mercancia').setClick(()=>{
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', '¿Desea cancelar el ajuste en proceso? Al presionar SI, el ajuste no se realizara.');
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.getField('modal_observaciones_mercancia').handleClose(); });
            this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("SI"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        });
        this.getField('bt_aceptar_obs_mercancia').setClick(()=>{this.guardarObservacionesMercancia();});
      });
      //ajuste parcial
      this.getField('bt_ajuste_parcial').setClick(()=>{
        this.getField('modal_pregunta_ajuste').handleClose();
        
        this.traerItemsParcial();
        //funciones
        this.getField('modal_mercancia_parcial').handleClickOpen();
        this.getField('observaciones_mercancia').setValue('');
        this.getField('total_codigos').setValue(0);
        this.getField('total_cantidad').setValue(0);
        this.getField('bt_cancelar_parcial').setClick(()=>{
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', '¿Desea cancelar el ajuste en proceso? Al presionar SI, el ajuste no se realizara.');
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.getField('modal_mercancia_parcial').handleClose(); });
            this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("SI"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        });
        this.getField('bt_aceptar_parcial').setClick(()=>{this.guardarMercanciaParcial();});
        this.getField('bt_modificar_parcial').setClick(()=>{this.modificarMercanciaParcial();});
        this.row_parciales=[];
      });

        
    }
  }

  cerrarOrden2(input){

        this.mostrarMensajeGuardando();
        let datos={ datos: {
          sucursal_ingreso:this.sucursal_ingreso,
          nit:this.getField('nit').getValue(),
          sede:this.getField('sede').getValue(),
          xsucursal:this.xsucursal,
          existe_ubicaciones:this.existe_ubicaciones,
          no_orden: this.no_orden_aux,
          factura_numero:this.factura_numero_aux,
          select_direccion:this.getField('select_direccion').getValue(),
          factura_provisional:this.getField('factura_provisional').getValue(),
          input:"0"
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cerrarordencompra', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessCerrarOrden,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
  
  }

  cerrarOrden(no_orden,factura_numero,fecha_servidor){

        this.mostrarMensajeGuardando();
        let datos={ datos: {
          sucursal_ingreso:this.sucursal_ingreso,
          nit:this.getField('nit').getValue(),
          sede:this.getField('sede').getValue(),
          xsucursal:this.xsucursal,
          existe_ubicaciones:this.existe_ubicaciones,
          no_orden: no_orden,
          factura_numero:factura_numero,
          select_direccion:this.getField('select_direccion').getValue(),
          factura_provisional:this.getField('factura_provisional').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cerrarordencompra', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessCerrarOrden,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
  
  }
  crearActividadProceso(no_orden,factura_numero,valor_factura_compra){
    let data_envio={
        compra_nueva:true,
        factura_numero:factura_numero,
        orden_compra:no_orden
    }
    let datos={ datos: {
      data:data_envio,
      proceso_configuracion_id:5,
      procesos_actividades_configuracion_id:13,
      referencia:factura_numero,
      tabla:".",
      registro_id:1,
      nombre_proceso:"Nueva Compra",
      descripcion_proceso:valor_factura_compra.toString()
    }};
    this.generalFormatPmv = { tipo_servicio: 'proc-procesos', operacion: '5', operacion_tipo: 'crear' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.sucessCrearActividadProceso,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });

}

  sucessCerrarOrden(data) {
    this.getField('confirmModalCustom').toggle(false);
    let imprimir=false;
    if(data.estado_p === 200){
      if(data.data.mensaje_mostrar!==undefined){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.mensaje_mostrar);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.cerrarOrden2("0") });
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }else{
        if(data.data.mensaje_devolver!==undefined){
          this.mostrarMensajeAdicional(data.data.mensaje_devolver);
          //this.alertGeneral.toggle(true, data.data.mensaje_devolver, 'success');
        }
        this.mostrarMensajeAdicional("Orden cerrada.");
        //this.alertGeneral.toggle(true,'Orden cerrada', 'success');
        imprimir=true;
      }
    }else {
      //aunque falle viene e imprime
      imprimir=true;
      if(data.data.mensaje_devolver!==undefined){
        this.mostrarMensajeAdicional(data.data.mensaje_devolver);
      }
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
    if(imprimir){
      this.abrirImprimir();
      if(this.existe_ubicaciones===false){
        //va y valida bodega mandato
        this.abrirObservacionesMercancia();
      }
      //graba-> cierra orden --> modifica precios --> muestra observaciones y a lo ultimo imprimir
      this.abrirModificarPrecios();
    }
  }
  sucessCrearActividadProceso(data) {
    if(data.estado_p === 200){
      this.alertGeneral.toggle(true,'Se creo la actividad en el proceso compras01 con éxito.', 'success');
    }else {
      this.alertGeneral.toggle(true, 'Error al crear la actividad en procesos.', 'error');
    }
  }

  grabarCompra(){
    this.getField('grabar').setDisabled(true);
    this.mostrarMensajeGuardando();
    
    let op='';
    let datos={ datos: {  }};
    if(this.existe_ubicaciones){
      datos={ datos: {
        sucursal_ingreso : this.sucursal_ingreso,
        select_direccion : this.getField('select_direccion').getValue(),
        xsucursal : this.xsucursal,
        docSoporteCri : this.docSoporteCri,
        no_orden : this.getField('no_orden').getValue(),
        nit : this.getField('nit').getValue(),
        nombre : this.getField('nombre').getValue(),
        sede : this.getField('sede').getValue(),
        responsable_iva : this.getField('responsable_iva').getValue(),
        compraDsCri:this.compraDsCri,
        factura_provisional:this.getField('factura_provisional').getValue(),
        consecutivo_entrega:this.getField('consecutivo_entrega').getValue()===''?'0':this.getField('consecutivo_entrega').getValue(), //se envia pero siempre va a ser 0
        existe_novedad:this.getField('existe_novedad').getValue(),
        existe_ubicaciones:this.existe_ubicaciones,
        factor:this.getField('factor').getValue(),
        fecha_emision_actual:this.fecha_emision_actual,
        detalle:this.getField('detalle').getValue(),
  
        fecha1 : this.fecha1,
        valor_descuento_1 : this.valor_descuento1,
        fecha2 : this.fecha2,
        valor_descuento_2 : this.valor_descuento2,
        fecha3 : this.fecha3,
        valor_descuento_3 : this.valor_descuento3,
        valor_descuento_directo:this.valor_descuento_directo,
        valor_descargue:this.valor_descargue,
        fecha_vencimiento:this.getField('fecha_vencimiento_xml').getValue(),
        valor_neto:this.getField('total_pedido').getValue(),
        valor_iva:this.getField('valor_iva').getValue(),
        impuestos:this.getField('impuestos').getValue(),
      }};
      op='grabarcompraubicacion';
    }else{

      datos={ datos: {
        sucursal_ingreso : this.sucursal_ingreso,
        select_direccion : this.getField('select_direccion').getValue(),
        xsucursal : this.xsucursal,
        no_orden : this.getField('no_orden').getValue(),
        nit : this.getField('nit').getValue(),
        nombre : this.getField('nombre').getValue(),
        sede : this.getField('sede').getValue(),
        responsable_iva : this.getField('responsable_iva').getValue(),
        factura_provisional:this.getField('factura_provisional').getValue(),
        consecutivo_entrega:this.getField('consecutivo_entrega').getValue(), //se envia pero siempre va a ser 0
        existe_novedad:this.getField('existe_novedad').getValue(),
        existe_ubicaciones:this.existe_ubicaciones,
        factor:this.getField('factor').getValue(),
        fecha_emision_actual:this.fecha_emision_actual,
        detalle:this.getField('detalle').getValue(),
        fecha_recibido:this.fecha1,
        
  
        fecha1 : this.fecha1,
        valor_descuento_1 : this.valor_descuento1,
        fecha2 : this.fecha2,
        valor_descuento_2 : this.valor_descuento2,
        fecha3 : this.fecha3,
        valor_descuento_3 : this.valor_descuento3,
        valor_descuento_directo:this.valor_descuento_directo,
        valor_descargue:this.valor_descargue,
        fecha_vencimiento:this.getField('fecha_vencimiento_xml').getValue(),
        valor_neto:this.getField('total_pedido').getValue(),
        valor_iva:this.getField('valor_iva').getValue(),//!== "" ? this.getField('valor_iva').getValue().replaceAll('.','').replaceAll(',','.'): 0,
        impuestos:this.getField('impuestos').getValue() //!== "" ? this.getField('impuestos').getValue().replaceAll('.','').replaceAll(',','.'): 0,
  
      }};
      if(this.getField('factura_numero').getValue()!=='' && this.getField('factura_numero').getValue()!==' ')
        datos.datos.factura_numero=this.getField('factura_numero').getValue();
      op='grabarcomprasinubicacion';
      
    }
    if(this.getField('id_doc').getValue()!=='' && this.getField('id_doc').getValue()!==' ')
        datos.datos.id_doc=this.getField('id_doc').getValue();

/*     if(this.getField('flete').getValue()!=='' && this.getField('flete').getValue()!==' ' && this.nit_fletes!==''){
      datos.datos.nit_fletes=this.nit_fletes
      datos.datos.sede_fletes=this.sede_fletes;
      datos.datos.nombre_fletes=this.nombre_fletes;
      datos.datos.valor_fletes=this.valor_fletes;
      datos.datos.retencion_fletes=this.tarifa_fletes;
      datos.datos.valor_retencion_flete=this.valor_retenido_fletes;
      datos.datos.ch_cantidad=this.ch_cantidad;
      datos.datos.ch_valor=this.ch_valor;
      datos.datos.ch_peso=this.ch_peso;
      datos.datos.ch_facturado=this.ch_facturado;
      datos.datos.flete=this.getField('flete').getValue();

    } */
    if(this.getField('factura_numero').getValue()!==''){
      datos.datos.factura_numero=this.getField('factura_numero').getValue();
    }

    if(this.getField('base_retencion').getValue()!==''){
      datos.datos.base_retencion=this.getField('base_retencion').getValue();
    }

    if(this.getField('select_documentos').getValue()!=='' && this.getField('select_documentos').getValue()!==' '){
      datos.datos.select_documentos=this.getField('select_documentos').getValue();
    }

 /*   if(this.getField('retenido').getValue()!=='' && this.getField('retenido').getValue()!==' '){
      datos.datos.retenido=this.getField('retenido').getValue();
       datos.datos.select_retencion=this.getField('select_retencion').getValue(); 
    }*/
    /*if(this.getField('ajuste').getValue()!=='' && this.getField('ajuste').getValue()!==' '){
      datos.datos.ajuste=this.getField('ajuste').getValue();
    }*/
/*     if(this.getField('reteiva').getValue()!=='' && this.getField('reteiva').getValue()!==' '){
      datos.datos.reteiva=this.getField('reteiva').getValue();
    } */
    /*if(this.getField('reteica').getValue()!=='' && this.getField('reteica').getValue()!==' '){
      datos.datos.reteica=this.getField('reteica').getValue();
    }*/
/*     if(this.getField('cree').getValue()!=='' && this.getField('cree').getValue()!==' '){
      datos.datos.cree=this.getField('cree').getValue();
    } */

    if(this.docSoporteCri==='S'){
      datos.datos.nit_responsable=this.nit_responsable;
      datos.datos.sede_responsable=this.sede_responsable;
      datos.datos.nombre_responsable=this.nombre_responsable;
      datos.datos.numero_factura_proveedor=this.numero_factura_proveedor;
      datos.datos.select_condicion_venta=this.select_condicion_venta;
      datos.datos.select_medio_pago=this.select_medio_pago;
      datos.datos.select_moneda=this.select_moneda;
      datos.datos.tipo_de_cambio=this.tipo_de_cambio;
      datos.datos.select_actividad_economica=this.select_actividad_economica;
      datos.datos.observaciones_doc=this.observaciones_doc;

    }
     
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion:  op, operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.successGrabarCompra,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }

  successGrabarCompra(data){
    this.getField('confirmModalCustom').toggle(false);
    this.getField('grabar').setDisabled(false);
    if(data.estado_p === 200){
      //this.mostrarMensajeAdicional(data.data.mensaje);
      this.alertGeneral.toggle(true, data.data.mensaje, 'success');
      this.no_orden_aux=data.data.no_orden;
      this.factura_numero_aux=data.data.factura_numero;
      this.fecha_servidor_aux=data.data.fecha_servidor;
      this.valor_factura_compra=data.data.valor;
      this.cerrarOrden(this.no_orden_aux,this.factura_numero_aux,this.fecha_servidor_aux);
      this.crearActividadProceso(this.no_orden_aux,this.factura_numero_aux,this.valor_factura_compra);
    }else {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        if(data.data.errores_ubicaciones!==undefined && data.data.errores_ubicaciones.length>0){
          let configCell = new Map();
          this.getField('rejilla_ubicaciones_no_existen').toggle(true);
          this.gridOptionsNoUbicaciones['rowData'] = data.data.errores_ubicaciones;
          configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
          this.getField('rejilla_ubicaciones_no_existen').initData(this.gridOptionsNoUbicaciones, configCell);
          this.getField('modal_ubicaciones_no').handleClickOpen();
        }
        if(respuesta.length>0)
          this.recorrerErrores(respuesta,0);
        
      }
    }
  }

  recorrerErrores(array,posision){
    if(array[posision] instanceof Array || array[posision] instanceof Object){
      this.getField('confirmModalCustom').toggle(false);
      if(posision<array.length-1)
        this.recorrerErrores(array,posision+1);
    }else{
      this.getField('confirmModalCustom').toggle(false);
      this.getField('confirmModalCustom').setTitleAndContent('Compras', array[posision]);
      this.getField('confirmModalCustom').setClickDialog(() => { 
        this.getField('confirmModalCustom').toggle(false); 
        if(posision<array.length -1)
          this.recorrerErrores(array,posision+1);
      });
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botÃ³n de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botÃ³n de "confirmar" en los ConfirmDialog â€“ popup
      this.getField('confirmModalCustom').toggle(true);
    }
    
    
  }

  modificarPrecioArticulo(){
    if(this.getField('nuevo_precio').valid()){
        this.mostrarMensajeGuardando();
        this.getField('bt_modificar_precio').setDisabled(true);
        let datos={ datos: {
          nuevo_precio: this.getField('nuevo_precio').getValue(),
          codigo_articulo:this.row_precios[0].codigo
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '6_precios_mod', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessModificarPrecioArticulo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
  }

  sucessModificarPrecioArticulo(data) {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('bt_modificar_precio').setDisabled(false);
    if(data.estado_p === 200){
      this.getField('modal_confirmacion_precio').handleClose();
      this.mostrarMensajeAdicional("Registro modificado con éxito.");
      this.traerDatosModificarPrecios();
    }else {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
  }
  

  abrirModalPedirPrecio(){
    this.getField('modal_confirmacion_precio').handleClickOpen();
    this.getField('label_precio_actual').setLabel("".concat('El precio actual es : $',("".formatoPrecio(this.row_precios[0].precio1)) , '. Ingrese el nuevo precio:'));
    this.getField('nuevo_precio').setValue(0);
    //funciones
    this.getField('bt_modificar_precio').setClick(()=>{this.modificarPrecioArticulo();});
    this.getField('bt_cancelar_precio').setClick(()=>{this.getField('modal_confirmacion_precio').handleClose();});
  }

  onSelectionChangedPrecios(){
    this.row_precios = this.gridOptionsItemsPrecio.api.getSelectedRows();
  }

  abrirModificarPrecios(){
    if(this.asigna_precio==='1'){
      //traer precios
      this.traerDatosModificarPrecios();
        
    }
  }
    traerDatosModificarPrecios(){
    this.mostrarMensajeGenerando();
        let datos={ datos: {
          factura_numero:this.factura_numero_aux,
          xsucursal:this.xsucursal,
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_precios_mod', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successGetPrecios,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
  }

  successGetPrecios(data)
  {
    this.getField('confirmModalCustom').toggle(false);

    if(data.estado_p===200){
      let configCell = new Map();
      this.gridOptionsItemsPrecio['rowData'] = data.data;
      configCell.set('precio1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.precio1) }, cellStyle: {textAlign:"right"},width: 110});
      configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});

      this.getField('rejilla_articulos_mod').toggle(true);
      this.getField('rejilla_articulos_mod').initData(this.gridOptionsItemsPrecio, configCell);
      this.getField('modal_modificar_precios').handleClickOpen();

      //funciones
      this.getField('bt_acetar_mod_precio').setClick(()=>{this.getField('modal_modificar_precios').handleClose()});
      this.getField('bt_modificar_mod_precio').setClick(()=>{
        if(this.row_precios.length>0)
          this.abrirModalPedirPrecio();
        else
          this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
      });
      this.getField('bt_salir_mod_precio').setClick(()=>{this.getField('modal_modificar_precios').handleClose()});
    }else if(data.estado_p === 404){
      this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  actualizarNovedad(){
    if(this.getField('detalle_novedad').valid()){
      if(this.getField('ch_generar_ajuste').getValue()==='true' || this.getField('ch_modificar_cantidad').getValue()==='true'){
        if(this.getField('existe_novedad').getValue()==='true'){
          //actualizar
          this.mostrarMensajeGuardando();
          let datos={ datos: {
            codigo_articulo: this.fila_seleccionada[0].codigo_articulo,
            ubicacion_agregar: this.fila_seleccionada[0].ubicacion,
            cantidad_ajuste: this.getField('cantidad_ajuste').getValue(),
            detalle_novedad: this.getField('detalle_novedad').getValue(),
            value: this.getField('ch_generar_ajuste').getValue()==='true'?'A':'C',
  
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '6_novedad', operacion_tipo: 'modificar' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'PUT',
                  body: datos,
                  success: this.sucessAceptarNovedad,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
        }else{
          this.mostrarMensajeGuardando();
          //crear
          let datos={ datos: {
            codigo_articulo: this.fila_seleccionada[0].codigo_articulo,
            ubicacion_agregar: this.fila_seleccionada[0].ubicacion,
            cantidad_ajuste: this.getField('cantidad_ajuste').getValue(),
            detalle_novedad: this.getField('detalle_novedad').getValue(),
            value: this.getField('ch_generar_ajuste').getValue()==='true'?'A':'C',
  
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '5_novedad', operacion_tipo: 'crear' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'POST',
                  body: datos,
                  success: this.sucessAceptarNovedad,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
        }
      }else{
        this.alertGeneral.toggle(true, 'No ha seleccionado un tipo de novedad.', 'error');
      }
    }
  }

  sucessAceptarNovedad(data) {
    if(data.estado_p === 200){
      if(this.getField('existe_novedad').getValue()==='true')
        this.mostrarMensajeAdicional("Novedad modificada con éxito.");
      else
        this.mostrarMensajeAdicional("Novedad creada con éxito.");
      this.getField('modal_novedades').handleClose();
      this.limpiar_numero=false;
      this.refescarTabla();      
    }else if(data.estado_p===404) {
      this.alertGeneral.toggle(true, 'No hay datos', 'error');
    }else{
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
}

  eliminarNovedad(props) {
    this.getField('confirmModalCustom').toggle(false);
    if(this.getField('existe_novedad').getValue()==='true'){
        let datos = {
          datos: {
            codigo_articulo :this.fila_seleccionada[0].codigo_articulo,
            ubicacion_agregar :this.fila_seleccionada[0].ubicacion
          }
        };
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '7_novedad', operacion_tipo: "eliminar" };
        this.service.send(
          {
              endpoint: this.constant.formConfiguration(),
              method: 'DELETE',
              body: datos,
              success: this.successEliminarNovedad,
              error: this.error_,
              general: this.generalFormatPmv
          });
    }else{
      this.alertGeneral.toggle(true, 'No existe la novedad.', 'error');
    }
   
  }
  
  successEliminarNovedad(data) {
      if(data.estado_p === 200){
        this.mostrarMensajeAdicional("Novedad eliminada con éxito.");
        this.getField('modal_novedades').handleClose();        
        this.limpiar_numero=false;
        this.refescarTabla();
      }else{
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
  }

  abrirNovedades(){
    if(this.fila_seleccionada.length>0 && this.fila_seleccionada[0].codigo_articulo!=='.'){
      let novedad=this.fila_seleccionada[0].novedad;
      if (novedad==='A' || novedad==='S') {
        this.getField('existe_novedad').setValue(false);
        this.mostrarMensajeGenerando();
          let datos={ datos: {
            codigo_articulo:this.fila_seleccionada[0].codigo_articulo,
            ubicacion_agregar:this.fila_seleccionada[0].ubicacion
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_novedad', operacion_tipo: 'consulta' };
          this.service.send({
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successGetNovedad,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage:false
          });
        }else{
          this.getField('confirmModalCustom').toggle(false);
          this.getField('confirmModalCustom').setTitleAndContent('Novedad', 'El registro no puede generar novedad.');
          this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
          this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
          this.getField('confirmModalCustom').toggle(true);
        }
      }else{
        this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
      }
   
  }

  
  successGetNovedad(data)
  {
    this.getField('confirmModalCustom').toggle(false);
    let codigo_articulo = this.fila_seleccionada[0].codigo_articulo;
    let nombre_articulo = this.fila_seleccionada[0].nombre;
    let cantidad_pedida = this.fila_seleccionada[0].cant_orden;
    let cantidad_contada =this.fila_seleccionada[0].cantidad;
    let cantidad_ajuste = 0.0;
    let detalle = "";
    let tipo = "";
    this.getField('codigo_nombre_articulo').setValue(codigo_articulo+' - '+nombre_articulo);
    if(data.estado_p===200){
      this.getField('existe_novedad').setValue(true);
      cantidad_ajuste = data.data[0].cantidad_ajuste;
      cantidad_pedida = data.data[0].cantidad_orden;
      cantidad_contada = data.data[0].cantidad_compra;
      tipo =  data.data[0].tipo_novedad;
      detalle =  data.data[0].detalle_novedad;
      this.getField('cantidad_pedida').setValue(cantidad_pedida);
      this.getField('cantidad_contada').setValue(cantidad_contada);
      this.getField('cantidad_ajuste').setValue(cantidad_ajuste);
      this.getField('detalle_novedad').setValue(detalle);
      this.getField('cantidad_pedida').setValue(cantidad_pedida);
      if(tipo==='A'){
        this.getField('ch_generar_ajuste').setValueSwitch(true)
        this.getField('ch_modificar_cantidad').setValueSwitch(false)
      }else{
        this.getField('ch_modificar_cantidad').setValueSwitch(true)
        this.getField('ch_generar_ajuste').setValueSwitch(false)
      }
      this.getField('modal_novedades').handleClickOpen();
    }else if(data.estado_p === 404){
      this.getField('existe_novedad').setValue(false);
      this.getField('cantidad_pedida').setValue(cantidad_pedida);
      this.getField('cantidad_contada').setValue(cantidad_contada);
      this.getField('cantidad_ajuste').setValue(cantidad_ajuste);
      this.getField('detalle_novedad').setValue(detalle);
      this.getField('cantidad_pedida').setValue(cantidad_pedida);
      if(tipo==='A'){
        this.getField('ch_generar_ajuste').setValueSwitch(true)
        this.getField('ch_modificar_cantidad').setValueSwitch(false)
      }else if(tipo==="C"){
        this.getField('ch_modificar_cantidad').setValueSwitch(true)
        this.getField('ch_generar_ajuste').setValueSwitch(false)
      }
      this.getField('modal_novedades').handleClickOpen();
    }else {
      this.getField('existe_novedad').setValue(false);
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }

    if (tipo==="A") {
        this.getField('label_tipo').setValue("Cantidad Ajuste: ");
        this.getField('cantidad_ajuste').setValue(cantidad_contada - cantidad_pedida);
    } else if (tipo==="C") {
        this.getField('label_tipo').setValue("Cantidad Final: ");
        this.getField('cantidad_ajuste').setValue(cantidad_pedida);
    }else{
      //defecto
      this.getField('label_tipo').setValue("Cantidad Ajuste: ");
      this.getField('cantidad_ajuste').setValue(cantidad_contada - cantidad_pedida);
    }
  }

  validarDescuentos(){

    if(this.getField('valor_descuento_1').valid() &&
    this.getField('valor_descuento_2').valid() &&
    this.getField('valor_descuento_3').valid() &&
    this.getField('valor_descuento_directo').valid() &&
    this.getField('valor_descargue').valid()) {
      this.valor_descuento1=this.getField('valor_descuento_1').getValue();
      this.valor_descuento2=this.getField('valor_descuento_2').getValue();
      this.valor_descuento3=this.getField('valor_descuento_3').getValue();
      this.valor_descuento_directo=this.getField('valor_descuento_directo').getValue();
      this.valor_descargue=this.getField('valor_descargue').getValue();
      this.fecha1=this.getField('fecha1').getValue();
      this.fecha2=this.getField('fecha2').getValue();
      this.fecha3=this.getField('fecha3').getValue();
      this.getField('modal_descuentos').handleClose();
      this.grabarCompra();
    }
  }

  pedirDescuentos(){
    //let prcDiferencia = ((this.getField('total_pedido_xml').getValue() * this.porcentaje_diferencia)/100) 
    //if (Math.abs(this.getField('total_pedido_xml').getValue() - this.getField('total_pedido').getValue()) <= prcDiferencia){
      this.getField('modal_descuentos').handleClickOpen();
      this.getField('fecha1').setValue(this.fecha_hoy);
      this.getField('fecha2').setValue(this.fecha_hoy);
      this.getField('fecha3').setValue(this.fecha_hoy);
      this.getField('valor_descuento_1').setValue(0);
      this.getField('valor_descuento_2').setValue(0);
      this.getField('valor_descuento_3').setValue(0);
      this.getField('valor_descuento_directo').setValue(0);
      this.getField('valor_descargue').setValue(0);
      this.getField('grabar').setDisabled(false);    
    /*}else {
      this.getField('confirmModalCustom').toggle(false);
      this.getField('confirmModalCustom').setTitleAndContent('Novedad', 'Para grabar la compra no puede haber una diferencia entre el xml y el pedido de compra de más del '+this.porcentaje_diferencia+'% .');
      this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
      this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
      this.getField('confirmModalCustom').toggle(true);
    }*/
  }

  validarRequiereOrdenGrabar(){
    this.getField('grabar').setDisabled(true);
    if(this.getField('no_orden').valid()){
      if((this.exigir_orden ==='S' && this.getField('no_orden').getValue()!=='0')){
        let datos={ datos: {
          sucursal_ingreso:this.sucursal_ingreso,
          nit:this.getField('nit').getValue(),
          sede:this.getField('sede').getValue(),
          no_orden:this.getField('no_orden').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'validarreqorden', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessValidarRequiereOrdenGrabar,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
      }else if(this.exigir_orden !=='S'){
        //no valida si quiere orden
        this.pedirDescuentos();
      }else{
        this.mostrarMensajeAdicional("Por favor, ingrese una orden de compra.");
        this.getField('grabar').setDisabled(false);
      }
    }
  }


  sucessValidarRequiereOrdenGrabar(data)
  {
    if(data.estado_p===200){
      this.pedirDescuentos();
    }else if(data.estado_p === 404){
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        this.getField('grabar').setDisabled(false);
    }else {
      this.getField('grabar').setDisabled(false);
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }

  }

  sugerirCantidad(){
    //this.mostrarMensajeGenerando();
    let datos={ datos: {
      barras_articulo: this.getField('barras_articulo').getValue(),
      no_orden: this.getField('no_orden').getValue(),
      nit: this.getField('nit').getValue(),
      sede: this.getField('sede').getValue(),

    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_sugerir_cantidad_barras', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successSugerirCantidad,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
  }
    
  successSugerirCantidad(data){
      //this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
        this.getField('cantidad_barras').setValue(data.data[0].total);
      }else if(data.estado_p === 404){
          //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          this.getField('cantidad_barras').setValue(0);
      }
      else{
        this.getField('cantidad_barras').setValue(0);
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
    }

  getCosto(){
    //this.mostrarMensajeGenerando();
    let datos={ datos: {
      barras_articulo: this.getField('barras_articulo').getValue()
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'costobarras', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessGetCosto,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
  }
    
  sucessGetCosto(data){
      //this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
        this.getField('costo_barras').setValue(data.data.costo);
      }else if(data.estado_p === 404){
          //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          this.getField('costo_barras').setValue(0);
      }
      else{
        this.getField('costo_barras').setValue(0);
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
    }

  traerArticuloBarras(){
    this.mostrarMensajeGenerando();
    let datos={ datos: {
      barras_articulo: this.getField('barras_articulo').getValue()
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'infobarras', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerArticuloBarras,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
  }
    
  sucessTraerArticuloBarras(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
        this.getField('nombre_articulo_barras').setValue(data.data[0].nombre);
        this.getField('precio_barras').setValue(data.data[0].precio1);
        this.sugerirCantidad();
        this.getCosto();
        
      }else if(data.estado_p === 404){
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
    }

  validarBarras(){
    if(this.getField('barras_articulo').valid()
    &&  this.getField('nombre_articulo_barras').valid()
    &&  this.getField('cantidad_barras').valid()
    &&  this.getField('costo_barras').valid()
    &&  this.getField('precio_barras').valid()){

        this.getField('bt_aceptar_barras').setDisabled(true);
        this.mostrarMensajeGuardando();
        let datos={ datos: {
          barras_articulo: this.getField('barras_articulo').getValue(),
          nombre_articulo_barras: this.getField('nombre_articulo_barras').getValue(),
          cantidad_barras: this.getField('cantidad_barras').getValue(),
          costo_barras: this.getField('costo_barras').getValue(),
          precio_barras: this.getField('precio_barras').getValue(),
          nit: this.getField('nit').getValue(),
          sede: this.getField('sede').getValue(),
          no_orden: this.getField('no_orden').getValue(),
          sucursal_ingreso:this.sucursal_ingreso,
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'grabarbarras', operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.sucessGrabarBarras,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    } 
  }

  grabarBarras1(opcion1){
    this.mostrarMensajeGuardando();
        let datos={ datos: {
          barras_articulo: this.getField('barras_articulo').getValue(),
          nombre_articulo_barras: this.getField('nombre_articulo_barras').getValue(),
          cantidad_barras: this.getField('cantidad_barras').getValue(),
          costo_barras: this.getField('costo_barras').getValue(),
          precio_barras: this.getField('precio_barras').getValue(),
          nit: this.getField('nit').getValue(),
          sede: this.getField('sede').getValue(),
          no_orden: this.getField('no_orden').getValue(),
          sucursal_ingreso:this.sucursal_ingreso,
          input:"0"
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'grabarbarras', operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.sucessGrabarBarras,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }

  grabarBarras2(opcion2){
    this.mostrarMensajeGuardando();
    let datos={ datos: {
      barras_articulo: this.getField('barras_articulo').getValue(),
      nombre_articulo_barras: this.getField('nombre_articulo_barras').getValue(),
      cantidad_barras: this.getField('nombre_articulo_barras').getValue(),
      costo_barras: this.getField('nombre_articulo_barras').getValue(),
      precio_barras: this.getField('nombre_articulo_barras').getValue(),
      nit: this.getField('nit').getValue(),
      sede: this.getField('sede').getValue(),
      no_orden: this.getField('no_orden').getValue(),
      sucursal_ingreso:this.sucursal_ingreso,
      input:"0",
      input2:"0"
  }};
  this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'grabarbarras', operacion_tipo: 'crear' };
  this.service.send(
  {
          endpoint: this.constant.formConfiguration(),
          method:'POST',
          body: datos,
          success: this.sucessGrabarBarras,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false
  });
}

  sucessGrabarBarras(data){
    this.getField('bt_aceptar_barras').setDisabled(false);
    if (data.estado_p === 200 )
    {
        this.getField('confirmModalCustom').toggle(false);
        if(data.data.mensaje_mostrar !== undefined){
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.mensaje_mostrar);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.grabarBarras1("0") });
            this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }else if(data.data.mensaje_mostrar2 !== undefined){
          this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.mensaje_mostrar2);
          this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.grabarBarras2("0") });
          this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);
        }else{
            this.getField('bt_aceptar_barras').setDisabled(false);
            //this.getField('modal_barras').handleClose();
            this.limpiar_numero=false;
            this.refescarTabla();
        }
    }
    else 
    { 
        this.getField('bt_aceptar_barras').setDisabled(false);
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
}

  contarProductosOrdenWms(){
    this.getField('bt_aceptar_impuesto').setDisabled(true);
    if(this.getField('codigo_impuesto').valid() 
    && this.getField('nombre_impuesto').valid()
    && this.getField('tarifa_impuesto').valid() 
    && this.getField('valor_impuesto').valid() 
    && this.getField('impuesto_id')!==' '){
      
      let datos = { datos: {
        no_orden:this.getField('no_orden').getValue(),
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_productos_contados', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessContarProductos,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
    }
}

  sucessContarProductos(data){
  this.getField('bt_aceptar_impuesto').setDisabled(false);
  if(data.estado_p===200){
        if(data.data[0].cuantos>0){
          this.getField('modal_pregunta').handleClickOpen();
        }else{
          this.mostrarMensajeAdicional("La orden de compra " + this.getField('no_orden').getValue() + " no tiene productos contados.")
        }
  }else if(data.estado_p === 404){
      this.mostrarMensajeAdicional("La orden de compra " + this.getField('no_orden').getValue() + " no tiene productos contados.")
  }else {
    let respuesta=Object.values(data.data.errores);
    let keys=Object.keys(data.data.errores);
    this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
  }
}


  actualizarPrecio(){
    if(this.getField('codigo_articulo').valid() &&
      this.getField('nombre_articulo').valid() &&
      this.getField('precio_sin_iva').valid() &&
      this.getField('tarifa_iva').valid() &&
      this.getField('rentabilidad').valid() &&
      this.getField('precio').valid()){
        this.getField('bt_actualizar_precio').setDisabled(true);
        let datos={ datos: {
          codigo_articulo: this.getField('codigo_articulo').getValue(),
          precio_sin_iva: this.getField('precio_sin_iva').getValue(),
          tarifa_iva: this.getField('tarifa_iva').getValue(),
          rentabilidad: this.getField('rentabilidad').getValue(),
          precio: this.getField('precio').getValue(),
          sucursal_ingreso:this.sucursal_ingreso

        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'actualizarprecio', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessModificarPrecio,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
  }

  sucessModificarPrecio(data) {
    this.getField('bt_actualizar_precio').setDisabled(false);
    if(data.estado_p === 200){
      this.mostrarMensajeAdicional(data.data.mensaje);         
    }else {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
  }

  modificarImpArt(id){
    this.getField('bt_aceptar_impuesto').setDisabled(true);
    let datos={ datos: {
        tarifa_impuesto: this.getField('tarifa_impuesto').getValue(),
        valor_impuesto: this.getField('valor_impuesto').getValue(),
        por_porcentaje: this.getField('ch_por_porcentaje').getValue()==='true'?"S":"N",
        en_compras: this.getField('ch_compras').getValue()==='true'?"S":"N",
        en_ventas: this.getField('ch_ventas').getValue()==='true'?"S":"N",
        afecta_costo: this.getField('ch_afecta_costo').getValue()==='true'?"S":"N",
        value: this.getField('codigo_articulo').getValue(),
        id_articulos_impuestos: id,
        impuesto_id: this.getField('impuesto_id').getValue(),
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-articulosimpuestos', operacion: '6', operacion_tipo: 'modificar' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'PUT',
            body: datos,
            success: this.successModificarImpArt,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
}

successModificarImpArt(data) {
  this.getField('bt_aceptar_impuesto').setDisabled(false);
  if(data.estado_p === 200){
    this.limpiarCamposImp();
    this.consultarOtrosImpuestos();          
  }else {
    if(data.estado_p===404) {
      this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
    }
    else {
      let respuesta=Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
    }
  }
  }


  crearImpArt(){
    this.getField('bt_aceptar_impuesto').setDisabled(true);
      let datos={ datos: {
        tarifa_impuesto: this.getField('tarifa_impuesto').getValue(),
        valor_impuesto: this.getField('valor_impuesto').getValue(),
        por_porcentaje: this.getField('ch_por_porcentaje').getValue()==='true'?"S":"N",
        en_compras: this.getField('ch_compras').getValue()==='true'?"S":"N",
        en_ventas: this.getField('ch_ventas').getValue()==='true'?"S":"N",
        afecta_costo: this.getField('ch_afecta_costo').getValue()==='true'?"S":"N",
        value: this.getField('codigo_articulo').getValue(),
        impuesto_id: this.getField('impuesto_id').getValue(),
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-articulosimpuestos', operacion: '5', operacion_tipo: 'crear' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successCrearImpArt,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  successCrearImpArt(data) {
    this.getField('bt_aceptar_impuesto').setDisabled(false);
    if(data.estado_p === 200){
      this.limpiarCamposImp();
      this.consultarOtrosImpuestos();           
    }else {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
  }

  validarSiExisteImpArt(){
      this.getField('bt_aceptar_impuesto').setDisabled(true);
      if(this.getField('codigo_impuesto').valid() 
      && this.getField('nombre_impuesto').valid()
      && this.getField('tarifa_impuesto').valid() 
      && this.getField('valor_impuesto').valid() 
      && this.getField('impuesto_id')!==' '){
        
        let datos = { datos: {
          impuesto_id:this.getField("impuesto_id").getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-articulosimpuestos', operacion: '1_existe', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successValidarSiExisteImpArt,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
      }
  }
  
  successValidarSiExisteImpArt(data){
    this.getField('bt_aceptar_impuesto').setDisabled(false);
    if(data.estado_p===200){
          this.getField('confirmModalCustom').setTitleAndContent('Crear', 'Confirma que desea modificar el registro?');
          this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false);this.modificarImpArt(data.data[0].id)});  
          this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);
    }else if(data.estado_p === 404){
        this.getField('confirmModalCustom').setTitleAndContent('Crear', 'Confirma que desea agregar el registro?');
        this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false);this.crearImpArt()});  
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }


  traerValoresSwitch(){
    this.getField('nombre_impuesto').setError(false,'');
    if(this.getField('impuesto_id').getValue()!=='' && this.getField('impuesto_id').getValue()!==' '){
      this.getField('codigo_impuesto').setDisabled(true);
      let datos = { datos: {
        id_impuestos:this.getField("impuesto_id").getValue(),
        value:this.getField("codigo_articulo").getValue(),
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-impuestos', operacion: '1_1', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.successTraerValoresSwitch,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
    }
}

successTraerValoresSwitch(data){
  if(data.estado_p===200){
        this.getField('ch_compras').setValueSwitch(data.data[0].en_compras==='S'?true:false);
        this.getField('ch_ventas').setValueSwitch(data.data[0].en_ventas==='S'?true:false);
        this.getField('ch_afecta_costo').setValueSwitch(data.data[0].afecha_costo==='S'?true:false);
        this.getField('tarifa_impuesto').setValue(data.data[0].tarifa);
        this.getField('valor_impuesto').setValue(data.data[0].valor);
        if(data.data[0].por_porcentaje==='N'){
          this.getField('ch_por_valor').setValueSwitch(true);
          this.getField('ch_por_porcentaje').setValueSwitch(false);
        }
        else{
          this.getField('ch_por_porcentaje').setValueSwitch(true);
          this.getField('ch_por_valor').setValueSwitch(false);
        }
        if (data.data[0].por_porcentaje==='S') {
            this.getField('tarifa_impuesto').setDisabled(false);
            this.getField('valor_impuesto').setValue(0);
            this.getField('valor_impuesto').setDisabled(true);
        } else {
            this.getField('tarifa_impuesto').setDisabled(true);
            this.getField('tarifa_impuesto').setValue(0);
            this.getField('valor_impuesto').setDisabled(false);
        }
  }else if(data.estado_p === 404){
      this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      //this.mostrarMensajeNoHayDatos();
  }else {
    let respuesta=Object.values(data.data.errores);
    let keys=Object.keys(data.data.errores);
    this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
  }
}

  limpiarCamposImp(){
    this.getField('bt_aceptar_impuesto').setDisabled(false);
    this.getField('codigo_impuesto').setDisabled(false);
    this.getField('tarifa_impuesto').setDisabled(true);
    this.getField('valor_impuesto').setDisabled(true);
    this.getField('codigo_impuesto').setValue('');
    this.getField('nombre_impuesto').setValue('');
    this.getField('tarifa_impuesto').setValue('');
    this.getField('valor_impuesto').setValue('');
    this.getField('ch_por_porcentaje').setValueSwitch(true);
    this.getField('ch_por_valor').setValueSwitch(false);
    this.getField('ch_compras').setValueSwitch(false);
    this.getField('ch_ventas').setValueSwitch(false);
    this.getField('ch_afecta_costo').setValueSwitch(false);
    this.getField('ch_por_porcentaje').setDisabled(true);
    this.getField('ch_por_valor').setDisabled(true);
    this.getField('ch_compras').setDisabled(true);
    this.getField('ch_ventas').setDisabled(true);
    this.getField('ch_afecta_costo').setDisabled(true);
  }

  consultarOtrosImpuestos(){
      if(this.getField('codigo_articulo').valid() &&
      this.getField('nombre_articulo').valid()){
        let datos = { datos: {
          value:this.getField("codigo_articulo").getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-articulosimpuestos', operacion: '1_todos', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerOtrosImpuestos,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
      }
  }

  sucessTraerOtrosImpuestos(data){
    if(data.estado_p===200){
          let configCell = new Map();
          this.gridOptionsOtrosImp['rowData'] = data.data;
          configCell.set('tarifa', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('accion', { cellRenderer: this.setButtonDeleteImp, width: 250, sortable: false, filter: false });

          this.getField('rejilla_impuestos_creados').toggle(true);
          this.getField('rejilla_impuestos_creados').initData(this.gridOptionsOtrosImp, configCell);
    }else if(data.estado_p === 404){
        this.getField('rejilla_impuestos_creados').toggle(false);
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        //this.mostrarMensajeNoHayDatos();
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  setButtonDeleteImp(props) {       
    let button = document.createElement("input");
    button.onclick = () => this.eliminarRegistroImp(props.data);
    button.setAttribute("id", 'button_delete_' + props.data.id);
    button.setAttribute("class", "buttonStyle");
    button.setAttribute("type", "button");
    button.setAttribute("value", "eliminar");
    return this.createElementJaivana(button);
  }

  eliminarRegistroImp(props) {
    this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
    this.getField('confirmModalCustom').setClickDialog(() => { this.confirmDeleteImp(props) });
    this.getField("confirmModalCustom").setVisibleCancel(false);
    this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
    this.getField('confirmModalCustom').toggle(true);
  }

  confirmDeleteImp(props) {
    this.getField('confirmModalCustom').toggle(false);
    let datos = {
        datos: {
          id_articulos_impuestos: props.id,
        }
    };
    this.generalFormatPmv = { tipo_servicio: 'comp-articulosimpuestos', operacion: '7', operacion_tipo: "eliminar" };
    this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminarImp,
            error: this.error_,
            general: this.generalFormatPmv
        });
  }

  successEliminarImp(data) {
      if(data.estado_p === 200){
        this.limpiarCamposImp();
        this.consultarOtrosImpuestos();         
      }else {
        if(data.estado_p===404) {
         this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
    }
  }

  abrirOtrosImpuestos(){
    if(this.cons_impart){
      if(this.getField('codigo_articulo').valid() && this.getField('nombre_articulo').valid()){
        this.getField('modal_otros_impuestos').setLabel('Otros Impuestos: '+this.getField('codigo_articulo').getValue());
        this.getField('modal_otros_impuestos').handleClickOpen();
        this.limpiarCamposImp();
        this.consultarOtrosImpuestos();
      }
    }else{
      this.mostrarMensajeAdicional("Opción no autorizada! (CONS_IMPART) ");
    }
  }

  calcularFactor(){
    if(this.calculo_factor===false){
      let xcosto1 = this.getField('precio_sin_iva').getValue();
      let xtarifa = this.getField('tarifa_iva').getValue();
      xcosto1 = xcosto1 / (1 + (xtarifa / 100));
      this.getField('precio_sin_iva').setValue(xcosto1);
      this.calculo_factor=true;
    }
  }

  traerImpuestosArticulo(){
    if(this.getField('nombre_articulo').getValue()!=='' && this.getField('nombre_articulo').getValue()!==' ' && Number(this.getField('total_item').getValue())!==0){
      if(this.getField('nit').valid() &&
      this.getField('sede').valid() &&
      this.getField('nombre').valid()){
        if(this.getField('precio_sin_iva').getValue()>0){
            let datos = { datos: {
              codigo_articulo:this.getField("codigo_articulo").getValue(),
              cantidad_articulo: this.getField("cantidad_articulo").getValue(),
              precio_sin_iva: this.getField("precio_sin_iva").getValue(),
            }};
      
            this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cargarretencionesitems', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerImpuestosArticulos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
        }
      }
    }else{
      this.getField('xotros_impuestos').setValue(0);
      this.getField('xt_otros_impuestos').setValue(0);
      this.calcularItem();
    }
    
  }

  sucessTraerImpuestosArticulos(data){
    if(data.estado_p===200){
          let configCell = new Map();
          this.gridOptionsImpArti['rowData'] = data.data.impuestos;
          configCell.set('tarifa', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total) }, cellStyle: {textAlign:"right"},width: 110});

          this.getField('rejilla_impuestos_articulo').toggle(true);
          this.getField('rejilla_impuestos_articulo').initData(this.gridOptionsImpArti, configCell);
          this.getField('xt_otros_impuestos').setValue(data.data.total_impuestos);
          this.getField('xotros_impuestos').setValue(data.data.total_impuestos_unidad);
          
    }else if(data.estado_p === 404){
        this.getField('rejilla_impuestos_articulo').toggle(false);
        this.getField('xt_otros_impuestos').setValue(0)
        this.getField('xotros_impuestos').setValue(0);
        //this.mostrarMensajeNoHayDatos();
    }else {
      this.getField('rejilla_impuestos_articulo').toggle(true);
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      this.getField('xt_otros_impuestos').setValue(0)
      this.getField('xotros_impuestos').setValue(0);
    }
    this.calcularItem();
  }

  validarModalDatosCargue(){
    if(this.validarfechasDatosCargue()){
      if(this.getField('encargado').valid()&&
      this.getField('conductor').valid()&&
      this.getField('origen_mercancia').valid()&&
      this.getField('numero_factura_cargue').valid()&&
      this.getField('hora_inicio').valid()&&
      this.getField('minutos_inicio').valid()&&
      this.getField('hora_fin').valid()&&
      this.getField('minutos_fin').valid()&&
      this.getField('fecha_factura').valid()&&
      this.getField('peso').valid()&&
      this.getField('valor_cargue').valid()){
        //grabar datos

        let datos = { datos: {
          encargado:this.getField("encargado").getValue(),
          conductor: this.getField("conductor").getValue(),
          origen_mercancia: this.getField("origen_mercancia").getValue(),
          numero_factura_cargue: this.getField("numero_factura_cargue").getValue(),
          fecha_factura: this.getField("fecha_factura").getValue(),
          hora_inicio: this.getField("hora_inicio").getValue(),
          minutos_inicio: this.getField("minutos_inicio").getValue(),
          hora_fin: this.getField("hora_fin").getValue(),
          minutos_fin: this.getField("minutos_fin").getValue(),
          peso: this.getField("peso").getValue(),
          valor_cargue: this.getField("valor_cargue").getValue(),
          sucursal_ingreso:this.sucursal_ingreso,
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'grabardatoscargue', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessGrabarDatosCargue,
                error: this.error_,
                general: this.generalFormatPmv
        });
      }
    }
  }

  sucessGrabarDatosCargue(data){
    if(data.estado_p===200){
      this.mostrarMensajeAdicional("Cargue grabado.");
      this.getField('modal_datos_cargue').handleClose();
    }else if(data.estado_p === 404){
      this.mostrarMensajeAdicional("Error al grabar el cargue.");
    }else {
      this.mostrarMensajeAdicional("Error al grabar el cargue.");
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  validarfechasDatosCargue(){
    if(this.getField('fecha_factura').getValue()!=='' && this.getField('fecha_factura').getValue()!==' '){
      this.getField("fecha_factura").setError(false,'');
      this.getField('bt_cargue_grabar').setDisabled(false);
      let fecha_actual = new Date().toISOString().split('T')[0];
      let fecha_factura = new Date(this.getField("fecha_factura").getValue()).toISOString().split('T')[0];
      if(fecha_factura < fecha_actual){
          this.getField("fecha_factura").setError(true,"No puede ser menor a la emisión de la factura.");
          this.getField('bt_cargue_grabar').setDisabled(true); 
          return false;
      }

      this.getField('bt_cargue_grabar').setDisabled(false); 
      return true;
    }else{
      return false;
    }
}

  abrirDatosCargue(){
    if(this.getField('nit').valid() &&
    this.getField('sede').valid() &&
    this.getField('nombre').valid() &&
    this.getField('no_orden').valid() &&
    this.getField('numero_factura').valid()){
      let date = new Date();
      let hour = date.getHours();
      let min = date.getMinutes();
      this.getField('modal_datos_cargue').handleClickOpen();
      this.getField('numero_factura_cargue').setValue(this.getField('numero_factura').getValue());
      this.getField('valor_cargue').setValue(this.getField('total_pedido').getValue().replaceAll('.','').replaceAll(',','.'));
      this.getField('encargado').setValue('');
      this.getField('conductor').setValue('');
      this.getField('origen_mercancia').setValue('');
      this.getField('hora_inicio').setValue(hour);
      this.getField('minutos_inicio').setValue(min);
      this.getField('hora_fin').setValue(hour);
      this.getField('minutos_fin').setValue(min);
      this.getField('fecha_factura').setValue(this.fecha_hoy);
      this.getField('peso').setValue('0');

    }
  }

  abrirAuditoria(){
    if(this.getField('nit').valid() &&
    this.getField('sede').valid()){
      /* this.getField('bt_auditoria').setDisabled(true); */
      let datos = { datos: {
        nit:this.getField("nit").getValue(),
        sede: this.getField("sede").getValue(),
        origen_mercancia: this.getField("origen_mercancia").getValue(),
        no_orden: this.getField("no_orden").getValue(),
        sucursal_ingreso:this.sucursal_ingreso,
      }};

      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'auditoria', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessAuditoria,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
    }
  }

  sucessAuditoria(data){
    /* this.getField('bt_auditoria').setDisabled(false); */
    if(data.estado_p===200){
      if(data.data.mensaje_mostrar!==undefined){
          this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
      }else{
        if(data.data.auditoria!==undefined && data.data.auditoria===false){
            //no ahce nada ya que no hace auditoria
            this.mostrarMensajeAdicional('No hay auditoría.');
        }else{  
          let configCell = new Map();
            if(data.data.diferencias.length>0){
              this.getField('rejilla_diferencias').toggle(true);
              this.gridOptionsAuditoriaDiferencias['rowData'] = data.data.diferencias;
              this.getField('rejilla_diferencias').initData(this.gridOptionsAuditoriaDiferencias,configCell);
            }else{
              this.getField('rejilla_diferencias').toggle(false);
            }
            if(data.data.faltantes.length>0){
              this.getField('rejilla_faltantes').toggle(true);
              this.gridOptionsAuditoriaFaltantes['rowData'] = data.data.faltantes;
              this.getField('rejilla_faltantes').initData(this.gridOptionsAuditoriaFaltantes,configCell);
              
            }else{
                this.getField('rejilla_faltantes').toggle(false);
            }
            if(data.data.demas.length>0){
              this.getField('rejilla_sobrantes').toggle(true);
              this.gridOptionsAuditoriaDemas['rowData'] = data.data.demas;
              this.getField('rejilla_sobrantes').initData(this.gridOptionsAuditoriaDemas,configCell);
            
            }else{
                this.getField('rejilla_sobrantes').toggle(false);
            }
            this.getField('modal_auditoria').handleClickOpen();
        }
      }
    }else if(data.estado_p === 404){
        this.mostrarMensajeNoHayDatos();
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
  }

  }

  validarFletes(){
    if(this.getField('nit_fletes').valid() &&
    this.getField('sede_fletes').valid() &&
    this.getField('nombre_fletes').valid() &&
    this.getField('valor_fletes').valid() &&
    this.getField('retencion_fletes').valid() &&
    this.getField('valor_retencion_flete').valid()
    ){
      if(this.getField('ch_peso').getValue()==='false' && this.getField('ch_valor').getValue()==='false' 
      && this.getField('ch_cantidad').getValue()==='false'){
          this.alertGeneral.toggle(true, 'Es necesario seleccionar un concepto de asignación', 'error');
      }else{
        this.nit_fletes=this.getField('nit_fletes').getValue();
        this.sede_fletes=this.getField('sede_fletes').getValue();
        this.nombre_fletes=this.getField('nombre_fletes').getValue();
        this.valor_fletes=this.getField('valor_fletes').getValue();
        this.tarifa_fletes=this.getField('retencion_fletes').getValue();
        this.ch_facturado=this.getField('ch_facturado').getValue();
        this.ch_cantidad=this.getField('ch_cantidad').getValue();
        this.ch_valor=this.getField('ch_valor').getValue();
        this.ch_peso=this.getField('ch_peso').getValue();
        this.valor_retenido_fletes=this.getField('valor_retencion_flete').getValue();
        /* this.getField('flete').setValue(this.valor_fletes); */
        this.getField('modal_fletes').handleClose();
      }
    }
  }

  borrarFletes(){
    this.nit_fletes='';
    this.sede_fletes='';
    this.nombre_fletes='';
    this.valor_fletes='';
    this.tarifa_fletes='';
    this.ch_facturado='false';
    this.ch_cantidad='false';
    this.ch_valor='false';
    this.ch_peso='false';
    this.valor_retenido_fletes='';
    this.getField('nit_fletes').setValue(this.nit_fletes);
    this.getField('sede_fletes').setValue(this.sede_fletes);
    this.getField('nombre_fletes').setValue(this.nombre_fletes);
    this.getField('valor_fletes').setValue(this.valor_fletes);
    this.getField('retencion_fletes').setValue(this.tarifa_fletes);
    this.getField('valor_retencion_flete').setValue(this.valor_retenido_fletes);
    this.getField('ch_cantidad').setValueSwitch(this.ch_cantidad);
    this.getField('ch_valor').setValueSwitch(this.ch_valor);
    this.getField('ch_peso').setValueSwitch(this.ch_peso);
    this.getField('ch_facturado').setValueSwitch(this.ch_facturado);
/*     this.getField('flete').setValue('');

    this.getField('flete').setError(false,''); */
    this.getField('valor_fletes').setError(false,'');
    this.getField('retencion_fletes').setError(false,'');
    this.getField('valor_retencion_flete').setError(false,'');


  }


  validarRequiereOrden(){
      if(this.getField('select_direccion').valid()){
      let datos={ datos: {
        sucursal_ingreso:this.sucursal_ingreso,
        nit:this.getField('nit').getValue(),
        sede:this.getField('sede').getValue(),
        no_orden:this.getField('no_orden').getValue(),
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'validarreqorden', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessValidarRequiereOrden,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
    }
  }

  sucessValidarRequiereOrden(data)
  {
    if(data.estado_p===200){
      this.getField('nit_fletes').setValue(this.nit_fletes);
      this.getField('sede_fletes').setValue(this.sede_fletes);
      this.getField('nombre_fletes').setValue(this.nombre_fletes);
      this.getField('valor_fletes').setValue(this.valor_fletes);
      this.getField('retencion_fletes').setValue(this.tarifa_fletes);
      this.getField('valor_retencion_flete').setValue(this.valor_retenido_fletes);
      this.getField('ch_cantidad').setValueSwitch(this.ch_cantidad);
      this.getField('ch_valor').setValueSwitch(this.ch_valor);
      this.getField('ch_peso').setValueSwitch(this.ch_peso);
      this.getField('ch_facturado').setValueSwitch(this.ch_facturado);
      
      this.getField('nit_fletes').setError(false,'');
      this.getField('sede_fletes').setError(false,'');
      this.getField('nombre_fletes').setError(false,'');
      this.getField('valor_fletes').setError(false,'');
      this.getField('retencion_fletes').setError(false,'');
      this.getField('valor_retencion_flete').setError(false,'');

      this.getField('modal_fletes').handleClickOpen();
    }else if(data.estado_p === 404){
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }

  }

  abrirFletes(){
    if(this.getField('no_orden').valid() &&
    this.getField('nit').valid() &&
    this.getField('sede').valid() &&
    this.getField('nombre').valid() ){
      if(this.getField('total_pedido').getValue()==='0' || this.getField('total_pedido').getValue()===''){
        this.alertGeneral.toggle(true, 'El total de la factura no puede ser cero.', 'error');
     /* }else if(this.getField('valor_neto').getValue()==='0' || this.getField('valor_neto').getValue()===''){
        this.alertGeneral.toggle(true, 'El valor neto no puede ser cero.', 'error');
       }else if(this.getField('fecha_vence_final').valid()===false){
        this.alertGeneral.toggle(true, 'Es necesario configurar la fecha de vencimiento.', 'error');
       */}else if(this.getField('factura_numero').valid()===false){
        this.alertGeneral.toggle(true, 'Es necesario configurar el número factura.', 'error');
      }else{
        this.validarRequiereOrden();
      }

    }
  }

  cancelarModalInfoDoc(){
      this.getField('modal_info_doc').handleClose();
  }

  validarDocSoporte(){

    if(this.getField('nit_responsable').valid() 
      && this.getField('sede_responsable').valid()
      && this.getField('nombre_responsable').valid()
      && this.getField('numero_factura_proveedor').valid()
      && this.getField('tipo_de_cambio').valid()
      && this.getField('select_condicion_venta').valid()
      && this.getField('select_medio_pago').valid()
      && this.getField('select_moneda').valid()
      && this.getField('select_actividad_economica').valid()
      && this.getField('observaciones_doc').valid()){
        //validat Tasa
          if (this.getField('select_moneda').getValue()!==this.moneda_empresa && this.getField('tipo_de_cambio').getValue() === 1.0) {
              this.mostrarMensajeAdicional("Ingrese una tasa de cambio válida");
          }else{
            this.nit_responsable=this.getField('nit_responsable').getValue();
            this.sede_responsable=this.getField('sede_responsable').getValue();
            this.nombre_responsable=this.getField('nombre_responsable').getValue();
            this.numero_factura_proveedor=this.getField('numero_factura_proveedor').getValue();
            this.tipo_de_cambio=this.getField('tipo_de_cambio').getValue();
            this.select_condicion_venta=this.getField('select_condicion_venta').getValue();
            this.select_medio_pago=this.getField('select_medio_pago').getValue();
            this.select_moneda=this.getField('select_moneda').getValue();
            this.select_actividad_economica=this.getField('select_actividad_economica').getValue();
            this.observaciones_doc=this.getField('observaciones_doc').getValue();
            this.getField('modal_info_doc').handleClose();
          }
    }
  }


  abrirModalInfoDoc(){
    this.getField('modal_info_doc').handleClickOpen();
    this.getField('nit_responsable').setValue(this.nit_responsable);
    this.getField('sede_responsable').setValue(this.sede_responsable);
    this.getField('nombre_responsable').setValue(this.nombre_responsable);
    this.getField('numero_factura_proveedor').setValue(this.numero_factura_proveedor);
    this.getField('tipo_de_cambio').setValue(this.tipo_de_cambio);
    this.getField('select_condicion_venta').setValue(this.select_condicion_venta);
    this.getField('select_moneda').setValue(this.select_moneda);
    this.getField('select_medio_pago').setValue(this.select_medio_pago);
    this.getField('select_actividad_economica').setValue(this.select_actividad_economica);
    this.getField('observaciones_doc').setValue(this.observaciones_doc);

    this.getField('nit_responsable').setError(false,'');
    this.getField('sede_responsable').setError(false,'');
    this.getField('nombre_responsable').setError(false,'');
    this.getField('numero_factura_proveedor').setError(false,'');
    this.getField('select_condicion_venta').setError(false,'');
    this.getField('select_medio_pago').setError(false,'');
    this.getField('select_moneda').setError(false,'');
    this.getField('tipo_de_cambio').setError(false,'');
    this.getField('select_actividad_economica').setError(false,'');
    this.getField('observaciones_doc').setError(false,'');

  }

  sucessPropiedadBodega(data)
  {
    if (data.estado_p === 200) {
      this.xsucursal=data.data[0].valor;
    }else if(data.estado_p === 404){
        //this.alertGeneral.toggle(true, 'No sé encontrarón direccion validas.', 'error');
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
   }
  }

    traerDocumentos(){
      let datos={ datos: {
        value: this.docSoporteCri==='S'?'_cri':'',
        xsucursal:this.xsucursal
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_select_documentos', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessLlenarSelectDoc,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
  }

  sucessLlenarSelectDoc(data)
  {
    let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
    if (data.estado_p === 200 && data.data.length >= 1) {
      data.data.forEach(item => {
          let dataOp = {}
          dataOp['value'] = item.codigo_documento;
          dataOp['text'] = item.nombre;
          dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
          opciones.push(dataOp);
      });
    }else if(data.estado_p === 404){
        this.alertGeneral.toggle(true, 'No sé encontrarón documentos.', 'error');
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
   }
   this.getField('select_documentos').setOptions(opciones);
  }
    


  consultarExisteProductoProveedor(){
    if(this.getField('nit_proveedor').valid() &&
    this.getField('sede_proveedor').valid()  &&
    this.getField('nombre_proveedor').valid() &&
    this.getField('codigo_articulo_proveedor').valid() && 
    this.getField('nombre_articulo_proveedor').valid() &&
    this.getField('articulo_proveedor').valid()){
       let datos = { datos: {
        nit_proveedor:this.getField("nit_proveedor").getValue(),
        codigo_articulo_proveedor:this.getField('codigo_articulo_proveedor').getValue()
       }};
       this.generalFormatPmv = { tipo_servicio: 'comp-productosproveedores', operacion: '1_existe', operacion_tipo: 'consulta' };
       this.service.send(
       {
           endpoint: this.constant.formConfiguration(),
           method:'GET',
           body: datos,
           success: this.sucessConsultarExisteProductoProveedor,
           error: this.error_,
           general: this.generalFormatPmv,
           showMessage:false
       });
   }
 }

 sucessConsultarExisteProductoProveedor(data){
     if (data.estado_p === 200 )
     {
        if(data.data[0].cuantos>0){
          this.modificarProductoProveedor();
        }else{
          this.crearProductoProveedor();
        }
     }else {
         if(data.estado_p===404) {
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
         }
         else {
           let respuesta=Object.values(data.data.errores);
           this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
         }
     }
  }

  modificarProductoProveedor(){

    let datos={ datos: {
        nit_proveedor: this.getField('nit_proveedor').getValue(),
        codigo_articulo_proveedor: this.getField('codigo_articulo_proveedor').getValue(),
        articulo_proveedor: this.getField('articulo_proveedor').getValue(),
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-productosproveedores', operacion: '6', operacion_tipo: 'modificar' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'PUT',
            body: datos,
            success: this.sucessModificarProductoProveedor,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
}

sucessModificarProductoProveedor(data) {
  if(data.estado_p === 200){
    this.getField('articulo_proveedor').setValue('');
    this.getField('codigo_articulo_proveedor').setValue('');
    this.getField('nombre_articulo_proveedor').setValue('');
    this.consultarProductosProveedor();          
  }else {
    if(data.estado_p===404) {
      this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
    }
    else {
      let respuesta=Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
    }
  }
  }


  crearProductoProveedor(){

      if(this.getField('codigo_articulo_proveedor').valid() && this.getField('nombre_articulo_proveedor').valid()
      && this.getField('articulo_proveedor').valid()){
          let datos={ datos: {
            nit_proveedor: this.getField('nit_proveedor').getValue(),
            codigo_articulo_proveedor: this.getField('codigo_articulo_proveedor').getValue(),
            articulo_proveedor: this.getField('articulo_proveedor').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-productosproveedores', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessCrearProductoProveedor,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
      }
        
  }

  sucessCrearProductoProveedor(data) {
    if(data.estado_p === 200){
      this.getField('articulo_proveedor').setValue('');
      this.getField('codigo_articulo_proveedor').setValue('');
      this.getField('nombre_articulo_proveedor').setValue('');
      this.consultarProductosProveedor();          
    }else {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
  }


  consultarProductosProveedor(){
    if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid()){
       let datos = { datos: {
        nit_proveedor:this.getField("nit_proveedor").getValue(),
       }};
       this.generalFormatPmv = { tipo_servicio: 'comp-productosproveedores', operacion: '1_articulo_proveedor', operacion_tipo: 'consulta' };
       this.service.send(
       {
           endpoint: this.constant.formConfiguration(),
           method:'GET',
           body: datos,
           success: this.sucessConsultarProductosProveedor,
           error: this.error_,
           general: this.generalFormatPmv,
           showMessage:false
       });
   }
 }

  sucessConsultarProductosProveedor(data){
     if (data.estado_p === 200 )
     {
        this.gridOptionsProductosProveedor['rowData'] = [];
        let configCell = new Map();
        this.gridOptionsProductosProveedor['rowData'] = data.data;
        configCell.set('accion', { cellRenderer: this.setButtonDelete, width: 250, sortable: false, filter: false });

        this.getField('rejilla_articulos_proveedor').initData(this.gridOptionsProductosProveedor,configCell);
        this.getField('rejilla_articulos_proveedor').toggle(true);
     }else {
        this.getField('rejilla_articulos_proveedor').toggle(false);
         if(data.estado_p===404) {
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
         }
         else {
           let respuesta=Object.values(data.data.errores);
           this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
         }
     }
  }

  setButtonDelete(props) {       
    let button = document.createElement("input");
    button.onclick = () => this.eliminarRegistroProveedor(props.data);
    button.setAttribute("id", 'button_delete_' + props.data.codigo_articulo);
    button.setAttribute("class", "buttonStyle");
    button.setAttribute("type", "button");
    button.setAttribute("value", "eliminar");
    return this.createElementJaivana(button);
  }

  eliminarRegistroProveedor(props) {
    this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
    this.getField('confirmModalCustom').setClickDialog(() => { this.confirmDeleteProveedores(props) });
    this.getField("confirmModalCustom").setVisibleCancel(false);
    this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
    this.getField('confirmModalCustom').toggle(true);
  }

  confirmDeleteProveedores(props) {
    this.getField('confirmModalCustom').toggle(false);
    let datos = {
        datos: {
          nit_proveedor: props.nit,
          codigo_articulo_proveedor: props.codigo_articulo,
          articulo_proveedor: props.articulo_proveedor
        }
    };
    this.generalFormatPmv = { tipo_servicio: 'comp-productosproveedores', operacion: '7', operacion_tipo: "eliminar" };
    this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminarProveedores,
            error: this.error_,
            general: this.generalFormatPmv
        });
  }

  successEliminarProveedores(data) {
      if(data.estado_p === 200){
        this.consultarProductosProveedor();          
      }else {
        if(data.estado_p===404) {
         this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
    }
  }


  validarCodigo(){
     if(this.getField("codigo_articulo").valid()){
        let datos = { datos: {
            nit:this.getField("nit").getValue(),
            codigo_articulo:this.getField("codigo_articulo").getValue(),
            con_codigo_proveedor:this.getField('con_codigo_proveedor').getValue(),
            sucursal_ingreso:this.sucursal_ingreso,
            existe_ubicaciones:this.existe_ubicaciones,
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'codigoauxiliar', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessValidarCodigo,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }
  }

  sucessValidarCodigo(data){
      if (data.estado_p === 200 )
      {
          this.getField('codigo_articulo').setValue(data.data.xcodigo);
          this.traerInfoArticulo();
          if(data.data.crear!==undefined && data.data.crear===true && this.existe_ubicaciones===false){
            this.getField('confirmModalCustom').setTitleAndContent('Artículos Proveedores', '¡El artículo no existe! . ¿Desea crearlo?');
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.abrirModalAgregarArticuloProveedor();});
            this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("Si"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
          }
      }else {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
          }
      }
  }

  abrirModalAgregarArticuloProveedor(){
      this.getField('nit_proveedor').setDisabled(true);
      this.getField('articulo_proveedor').setDisabled(true);
      this.getField('nit_proveedor').setError(false,'');
      this.getField('sede_proveedor').setError(false,'');
      this.getField('nombre_proveedor').setError(false,'');
      this.getField('articulo_proveedor').setError(false,'');
      this.getField('codigo_articulo_proveedor').setError(false,'');
      this.getField('nombre_articulo_proveedor').setError(false,'');
      this.getField('rejilla_articulos_proveedor').toggle(false);
      this.getField('nit_proveedor').setValue(this.getField('nit').getValue());
      this.getField('sede_proveedor').setValue(this.getField('sede').getValue());
      this.getField('nombre_proveedor').setValue(this.getField('nombre').getValue());
      this.getField('articulo_proveedor').setValue(this.getField('codigo_articulo').getValue());
      this.getField('modal_articulo_proveedores').handleClickOpen();
      this.consultarProductosProveedor();
  }



  calcularPrecioSugerido(){

    let xrenta = this.getField('rentabilidad').getValue();
    let xcosto = this.getField('precio_sin_iva').getValue();
    let xtarifa_iva = this.getField('tarifa_iva').getValue();
    if(xrenta>=0 && xcosto>0 && xtarifa_iva>0){
      let xprecio = (xcosto) / ((100 - xrenta) / 100);
      if (this.precio_incluye_iva==='S') {
          xprecio = xprecio * (1 + (xtarifa_iva / 100));
      }
      //precio_sugerido
      let decimal = (xprecio + "").split(".")[1];//para manejar los decimales
      if ((decimal !== 0) && (decimal !== undefined)) {
        if(decimal.length>2)
        xprecio=xprecio.toFixed(this.numero_decimales_compras);
      }
      //this.getField('precio').setValue(Number(xprecio));
    }
  }

  saldosubicacion(){
    if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid()){
        let datos = { datos: {
            sucursal:this.sucursal_ingreso,
            bodega:this.getField("select_direccion").getValue(),
            codigo_articulo:this.getField("codigo_articulo").getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'traersaldosubicacion', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successSaldosUbicacion,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }
  }

  successSaldosUbicacion(data){
      if (data.estado_p === 200 )
      {
          let configCell = new Map();
          let datos=[]

          data.data.forEach(element => {
            let arreglo=
              {
                ubicacion: element.ubicacion,
                saldo: element.saldo,
                cantidad_reservada: element.cantidad_reservada,
              }
    
              datos.push(arreglo);
          });
          this.gridOptionsComponentes1['rowData'] = datos;
          this.gridOptionsComponentes1['paginationPageSize']=5; //cantidad de datos que quiero mostrar por cada pagina
          configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('reservado', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_reservada) }, cellStyle: {textAlign:"right"},width: 110});
          if(this.wms_nuevo!=='S'){
            configCell.set('reservado', {cellRenderer:function(props){return props.data.reservado}, hide: true});
          }
          this.getField('rejilla_ubicacion').toggle(true);
          this.getField('rejilla_ubicacion').initData(this.gridOptionsComponentes1, configCell);
      }else {
          if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No hay datos de ubicación.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
          }
      }
  }

  traersaldoBodegas(){
      let datos = { datos: {
          bodega:this.getField("select_direccion").getValue(),
          codigo_articulo:this.getField("codigo_articulo").getValue()
      }};
      
      this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'traersaldosbodegas', operacion_tipo: 'consulta' };
      this.service.send(
      {
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.successSaldosBodegas,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
  }

  successSaldosBodegas(data){
      if (data.estado_p === 200 )
      {
          let datos=[]
          data.data.bodega.forEach(element => {
          let arreglo=
              {
                  bodega: element.bodega,
                  saldo: element.saldo
              }
          
              datos.push(arreglo);
          });
          let arraybodegas = Object.entries(data.data.bodegas[0]);
          arraybodegas.forEach(element => {
              let arreglo=
              {
                  bodega: element[0],
                  saldo: element[1]
              }
          
              datos.push(arreglo);
          });
          let configCell = new Map();
          configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});
          this.gridOptionsBodega['rowData'] = [];
          this.getField('rejilla_bodega').initData(this.gridOptionsBodega);
          this.getField('rejilla_bodega').toggle(false);
          
          this.gridOptionsBodega['rowData'] = datos;
          this.gridOptionsBodega['paginationPageSize']=5; //cantidad de datos que quiero mostrar por cada pagina
          //this.gridOptionsBodega['domLayout'] = 'autoHeight';// organiza el tamaño de la tabla
          this.getField('rejilla_bodega').toggle(true);
          this.getField('rejilla_bodega').initData(this.gridOptionsBodega, configCell);

      }else {
          if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No hay datos de bodegas.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
          }
      }
  }

  modificarCabys(){
    if(this.getField('codigo_articulo_cabys').valid()){
        let datos={ datos: {
          codigo_articulo:this.fila_seleccionada[0].codigo_articulo,
          codigo_cabys:this.getField('codigo_cabys').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '6_cabys', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessModificarCabys,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
  }


  sucessModificarCabys(data)
  {   
      if (data.estado_p === 200)
      {
          this.limpiar_numero=false;
          this.refescarTabla();
          this.getField('modal_cabys').handleClose();
      }
      else 
      {
        if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No hay datos', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
  }

  
  mostrarCabys(){
    if(this.fila_seleccionada.length>0 && this.fila_seleccionada[0].codigo_articulo!=='.'){
      this.getField('codigo_articulo_cabys').setValue(this.fila_seleccionada[0].codigo_articulo);
      this.getField('codigo_cabys').setValue('');
      this.getField('modal_cabys').handleClickOpen();
    }else{
        this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    }

  }

  modificarUbicacion(){
    if(this.getField('select_ubicaciones').valid()){
        let datos={ datos: {
          codigo_articulo:this.fila_seleccionada[0].codigo_articulo,
          origen:this.fila_seleccionada[0].origen,
          select_ubicaciones:this.getField('select_ubicaciones').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '6_ubicacion', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessCambiarUbicacion,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
  }


  sucessCambiarUbicacion(data)
  {   
      if (data.estado_p === 200)
      {
          this.getField('modal_ubicaciones').handleClose();
          this.limpiar_numero=false;
          this.refescarTabla();
      }
      else 
      {
        if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No hay datos', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
  }

  cambiarUbicacion(){
    if(this.maneja_wms_externo==='S'){
      this.mostrarMensajeAdicional("No se permite modificar la ubicación cuando se maneja wms externo.");
    }else{
      if(this.fila_seleccionada.length>0 && this.fila_seleccionada[0].codigo_articulo!=='.'){
        if(this.wms==='N'){
          this.mostrarMensajeAdicional("La bodega " + this.getField('select_direccion').getValue() + " no está configurada como wms.");
        }else{
            this.mostrarMensajeGenerando();
            let datos={ datos: {
              select_direccion:this.getField('select_direccion').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_ubicaciones_pag', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.traerUbicaciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
      }else{
          this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
      }
    }
  }

  traerUbicaciones(data)
  {   
      this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200)
      {
          this.getField('select_ubicaciones').setValue(' ');
          this.getField('modal_ubicaciones').handleClickOpen();
          this.getField('nombre_registro').setValue(this.fila_seleccionada[0].nombre);
          let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
          data.data.forEach(item => {
              let dataOp = {}
              dataOp['value'] = item.ubicacion;
              dataOp['text'] = item.ubicacion;
              dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
              opciones.push(dataOp);
              if(item.ubicacion===this.fila_seleccionada[0].ubicacion)
              this.getField('select_ubicaciones').setValue(item.ubicacion);
          });
          opciones.sort();
          this.getField('select_ubicaciones').setOptions(opciones);
          
      }
      else 
      {
        if(data.estado_p===404) {
            this.mostrarMensajeNoHayDatos();
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      }
  }

  validarDocumento(){
    if(this.row_documento.length>0){
      let mensaje='Selecciono el documento nro. ' + this.row_documento[0].numero_documento + ' del proveedor ' + this.getField('nit').getValue()
      + ' ¿Desea relacionarlo con la compra actual?';
      this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
      this.getField('confirmModalCustom').setClickDialog(() => { 
        this.getField('id_doc').setValue(this.row_documento[0].id);
        this.row_documento=[];
        //this.getField('modal_documentos').handleClose();
        this.getField('confirmModalCustom').toggle(false);
      });
      this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
    }else{
      this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    }
  }

  definirOrden(){
    if(this.row_orden.length>0){
      this.numero_orden_compra = this.row_orden[0].numero;
      this.getField('numero_factura').setDisabled(false);
      this.getField('no_orden').setValue(this.row_orden[0].numero);
      this.getField('detalle').setValue(this.row_orden[0].observaciones.trim());
      this.getField('detalle').setError(false,'');
      if(this.existe_ubicaciones===true){
        this.cargarOrden(false,false);
      }else{
        if(this.smartvv==='S' && this.compras_wms==='S'){
          this.contarProductosOrdenWms();
        }else{
          this.getField('modal_pregunta').handleClickOpen();
        }
      }
    }else{
      this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    }
  }

  onSelectionChangedOrdenes(){
    this.row_orden = this.gripOptionesOrdenes.api.getSelectedRows();
  }

  onSelectionChangedOrdenesXml(){
    this.row_orden_xml = this.gridOptionsOrdenCompras.api.getSelectedRows();
  }

  onSelectionChangedDocumentos(){
    this.row_documento = this.gripOptionesDocumentos.api.getSelectedRows();
  }
  onSelectionChangedUbicaciones(){
    this.row_ubicacion = this.gripOptionesUbicaciones.api.getSelectedRows();
  }

  mostrarOrdenes(){
    this.getField('confirmModalCustom').toggle(false);
    this.mostrarMensajeGenerando();
    let datos={ datos: {
      nit:this.getField('nit').getValue(),
      sede:this.getField('sede').getValue(),
    }};
    let op='';
    if(this.existe_ubicaciones===true)
      op='1_cargar_orden';
    else
      op='1_cargar_orden_referencia';
    this.mostrarMensajeGenerando();
    this.getField("rejilla_cargar_orden").setTipoServicioOperacion("comp-grabarcompras-"+op);
    this.getField("rejilla_cargar_orden").sendRequest(this.sucessMostrarOrdenes, datos);

    /*
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_cargar_orden', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessMostrarOrdenes,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });*/
  }



  sucessMostrarOrdenes(data)
  {   
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200){
        this.row_orden=[];
        this.getField('modal_orden_normal').handleClickOpen();
        this.getField("rejilla_cargar_orden").toggle(true);
        this.gripOptionesOrdenes["rowData"] = data.data;
        let configCell = new Map();
        configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
        this.getField('rejilla_cargar_orden').initData(this.gripOptionesOrdenes,configCell);
        /* this.getField('factura_numero').setValue(data.data[0].numero); */

    }else if(data.estado_p === 404){
        this.validarDocumentosRecibidos();
        this.alertGeneral.toggle(true, 'No hay datos.', 'error');
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }


  validarDocumentosRecibidos(){
    if(this.validar_documentos_recibidos && this.getField('nit').getValue()!=='' && this.getField('sede').getValue()!==''){
      this.validar_documentos_recibidos=false;
      this.mostrarMensajeGenerando();
      let datos={ datos: {
        sucursal_ingreso:this.sucursal_ingreso,
        no_orden:this.getField('no_orden').getValue(),
        nit:this.getField('nit').getValue()
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'doc_recibidos', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessValidarDocumentosRecibidos,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }
  }



  sucessValidarDocumentosRecibidos(data)
  {   
    this.validar_documentos_recibidos=true;
    this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200)
      {
        if(data.data.mensaje_confirmacion!==undefined){
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_confirmacion);
          this.getField('confirmModalCustom').setClickDialog(()=>{ 
              this.getField('id_doc').setValue(data.data.id_doc);
              this.getField('confirmModalCustom').toggle(false); 
          });
          this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
          this.getField("confirmModalCustom").setVisibleCancel(false);
          this.getField('confirmModalCustom').toggle(true);
        }else if(data.data.array_pedidos!==undefined && data.data.array_pedidos.length>0){
          //abrir modal visor de documentos
          this.row_documento=[];
          /*this.getField('modal_documentos').handleClickOpen();
          this.getField("rejilla_documentos").toggle(true);
          this.gripOptionesDocumentos["rowData"] = data.data.array_pedidos;
          let configCell = new Map();
          configCell.set('id', {cellRenderer:(props) => {return ""+props.data.id } , hide: true});
          //configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
          this.getField('rejilla_documentos').initData(this.gripOptionesDocumentos,configCell);*/
        }
      }else if(data.estado_p === 404){
        this.mostrarMensajeAdicional(data.data.mensaje);
      }else {
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
  }

  cargarOrdenWms(cantidad_escaneada,cantidad_cero){
    this.mostrarMensajeGenerando();
    this.cantidad_escaneada=true;
    this.orden_wms=true;
    let datos={ datos: {
      sucursal_ingreso:this.sucursal_ingreso,
      cantidad_escaneada:cantidad_escaneada+'',
      factor:this.getField('factor').getValue(),
      nit:this.getField('nit').getValue(),
      sede:this.getField('sede').getValue(),
      no_orden:this.getField('no_orden').getValue(),
      xfecha : this.fecha_hoy,
      cantidad_cero:''+cantidad_cero,
      existe_ubicaciones:this.existe_ubicaciones,
      xsucursal:this.xsucursal,
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cargarorden', operacion_tipo: 'crear' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.sucessCargarOrden,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  cargarOrden(cantidad_escaneada,cantidad_cero){
    this.mostrarMensajeGenerando();
    this.orden_wms=false;
    let datos={ datos: {
      sucursal_ingreso:this.sucursal_ingreso,
      cantidad_escaneada:cantidad_escaneada+'',
      factor:this.getField('factor').getValue(),
      nit:this.getField('nit').getValue(),
      sede:this.getField('sede').getValue(),
      no_orden:this.getField('no_orden').getValue(),
      xfecha:this.row_orden[0].fecha,
      cantidad_cero:''+cantidad_cero,
      existe_ubicaciones:this.existe_ubicaciones,
      xsucursal:this.xsucursal,
    }};
    
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cargarorden', operacion_tipo: 'crear' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.sucessCargarOrden,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }



  sucessCargarOrden(data)
  { 
    this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200)
      {
        
        this.getField('detalle').setValue(data.data.observaciones.trim());
          this.getField('detalle').setError(false,'');
          this.getField('modal_orden_normal').handleClose();
          this.getField('modal_documentos_recibidos_2').handleClose();
          this.getField('modal_documentos_recibidos').handleClose();



          this.refescarTabla();
          if(this.cantidad_escaneada===true){
            this.abrirAuditoria();
            if(this.existe_ubicaciones===true && this.maneja_wms_externo==='S' &&this.wms_externo_bodega==='S')
              this.validarNuevasUbicacionesWms();
          }
          if(this.facturacion_electronica_propia==='S'){
            this.getField('id_doc').setValue('');
            this.validarDocumentosRecibidos();
          }
          if (this.numero_orden_compra !== '0' || this.numero_orden_compra !== 0){
            this.getField('no_orden').setValue(this.numero_orden_compra);
            this.getField('factura_numero').setValue(this.numero_documento_xml);
            this.getField('fecha_vencimiento_xml').setValue(this.fecha_vence_xml);

            this.getField('total_subtotal_xml').setValue(this.total_sin_impuestos_xml);
            this.getField('valor_iva_xml').setValue(this.valor_iva_xml);
            this.getField('total_pedido_xml').setValue(this.total_xml);

            
          }
      }else if(data.estado_p === 404){
        this.alertGeneral.toggle(true, 'No hay datos.', 'error');
      }else {
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
  }


  validarOrdenWms(){
    if(this.getField('numero_orden').valid() 
    && this.getField('sucursal_orden').valid()
  && this.getField('numero_factura').valid()){
        this.mostrarMensajeGenerando();
        this.cargarItems();
    }
  }

  cargarItems(){
    this.mostrarMensajeGenerando();
    let datos={ datos: {
      sucursal_ingreso:this.sucursal_ingreso,
      no_orden:this.getField('numero_orden').getValue(),
      sucursal_orden:this.getField('sucursal_orden').getValue(),
      numero_factura:this.getField('numero_factura').getValue(),
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cargaritemswms', operacion_tipo: 'crear' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.sucessCargarItems,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }



  sucessCargarItems(data)
  {   
    this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200)
      {
        //se creo tabla --> temporal_pda

        //para que no se limpie la tabla con el onchange de nit
        this.getField('no_orden').setValue('0');

        //llenar nti y sede
        this.getField('nit').setValue(data.data.nit);
        this.getField('sede').setValue(data.data.sede);
         //llenamos factura nro. y no_orden
        this.getField('factura_numero').setValue(this.getField('numero_factura').getValue());
        this.getField('no_orden').setValue(this.getField('numero_orden').getValue());
        this.getField('factura_provisional').setValue(this.getField('numero_factura').getValue());

        this.getField('modal_wms_orden').handleClose();
        this.limpiar_numero=false;
        this.cargarOrdenWms(true,false);
      }else if(data.estado_p === 404){
        this.alertGeneral.toggle(true, 'No hay datos.', 'error');
      }else {
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
  }


  abrirOrden(){
    if(this.getField('select_direccion').valid()){
      this.cantidad_escaneada=false;
      if(this.existe_ubicaciones===true && this.maneja_wms_externo==='S'){
        if(this.wms_externo_bodega==='S'){
          this.traerOCWMSExterno();
        }else{
          if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
            this.mostrarOrdenes();
          }
        }
      }else{
        if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
            //con y sin ubicacion
            this.mostrarOrdenes();
        }
      }
    }
  }

  traerOCWMSExterno(){
    this.getField('modal_wms_orden').handleClickOpen();
    this.getField('numero_orden').setValue('');
    this.getField('sucursal_orden').setValue('');
    this.getField('numero_factura').setValue('');
  }


  validarBodega(){
    //this.mostrarMensajeGenerando();
    let datos={ datos: {
      value:this.getField('select_direccion').getValue()
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_validar_bodega', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessValidarBodega,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }



  sucessValidarBodega(data)
  {   
      if (data.estado_p === 200)
      {
          
          this.wms=data.data[0].wms;
          this.wms_externo_bodega=data.data[0].wms_externo;
          if(this.existe_ubicaciones===true){
            this.xsucursal=data.data[0].codigo_sucursal;
          }else{
            //traer propiedad bodega
            let datos={ datos: {
              value:this.getField('select_direccion').getValue(),
              sucursal_ingreso:this.sucursal_ingreso
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_propiedad_bodega', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessPropiedadBodega,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
          }
      }
      else 
      {
        this.getField('select_direccion').setValue('');
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje','Bodega invalida. No se grabó el pedido');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    if(this.getField('sede').getValue()!==''){
      this.traerDocumentos();
    }
  }

  mostrarMensajeGuardando(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
    this.getField('confirmModalCustom').setClickDialog(()=>{});  
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
}

mostrarMensajeGenerando(){
    this.getField('confirmModalCustom').toggle(false);
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
    this.getField('confirmModalCustom').setClickDialog(()=>{});  
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
}


  validarModalFechas(){
    if(this.validarfechas()){
        this.fecha_vencimiento=this.getField('fecha_vencimiento').getValue();
        /* this.getField('fecha_vence_final').setValue(this.getField('fecha_vencimiento').getValue()); */
        if(this.existe_ubicaciones===false){
          this.getField('fecha_recibido').setValue(this.getField('fecha_vencimiento').getValue());
        }
        this.fecha_emision_actual=this.getField('fecha_emision_actual').getValue();
        this.getField('modal_fechas').handleClose();
    }
  }

  calcularAjustes(){
        let totalf = this.getField('valor_total_factura').getValue();
        let total_pedido=this.getField('total_pedido').getValue() // !== "" ? this.getField('total_pedido').getValue().toString().replaceAll('.','').replaceAll(',','.') : 0;
        let xajuste = totalf - total_pedido;
        //let valorAjuste = xajuste;
        let decimal = (xajuste + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
          if(decimal.length>this.numero_decimales_compras)
          xajuste=Number(xajuste).toFixed(this.numero_decimales_compras);
        }
        this.getField('ajuste').setValue(xajuste);
        this.getField('modal_ajustes').handleClose();
        this.calcularNeto();
  }

  abrirFechas(){
    if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
        this.getField('modal_fechas').handleClickOpen();
        this.getField('tipo_fecha').setValue(this.proveedor_tipo_dias_vencimiento);
        this.getField('cantidad_dias').setValue(0);
        this.getField('fecha_emision_actual').setValue(this.fecha_hoy);
        this.getField('fecha_actual').setValue(this.fecha_hoy);
        this.getField('fecha_vencimiento').setValue(this.fecha_hoy);
    
        var result = new Date(this.fecha_hoy);
        if(this.getField('tipo_fecha').getValue()==='FE'){
          this.getField('fecha_vencimiento').setDisabled(true);
          result.setDate(result.getDate() + this.dias_vence_proveedor);
          this.getField('fecha_vencimiento').setValue(result);
        }else if(this.getField('tipo_fecha').getValue()==='FR'){
          this.getField('fecha_vencimiento').setDisabled(true);
          result.setDate(result.getDate() + this.dias_vence_proveedor);
          this.getField('fecha_vencimiento').setValue(result);
        }else{
          this.getField('fecha_vencimiento').setDisabled(false);
          if(this.fecha_vencimiento!==''){
            this.getField('fecha_vencimiento').setValue(this.fecha_vencimiento);
          }
        }
    }
  }


  abrirAjustes(){
    this.getField('modal_ajustes').handleClickOpen();
    this.getField('valor_total_factura').setValue(0);
  }

  mostrarMensajeAdicional(mensaje){
    this.getField('confirmModalCustom').toggle(false);
    this.getField('confirmModalCustom').setTitleAndContent('Compras', mensaje);
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
}

  validarReteICA(){
    if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
      if(this.reteica==='S'){

          let xvalor = this.getField('total_subtotal').getValue() //!== "" ? this.getField('total_subtotal').getValue().replaceAll('.','').replaceAll(',','.'): 0;
          let xtarifa = this.tarifa_reteica;
          let xvalorreteica = 0;
          xvalorreteica = xvalor * (xtarifa / 100);

          let decimal = (xvalorreteica + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>this.numero_decimales_compras)
            xvalorreteica=xvalorreteica.toFixed(this.numero_decimales_compras);
          }
          this.getField('tarifa_reteica').setValue(xtarifa);
          this.getField('reteica').setValue(xvalorreteica);
          this.calcularNeto();
      }else{
        this.mostrarMensajeAdicional('Al proveedor '+this.getField('nit').getValue()+' no se le calcula ReteICA');
      }
    }
  }

  validarCree(){
    try {
      if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
        if(this.tarifa_cree_compras!=='' && this.tarifa_cree_compras!==' ' && this.tarifa_cree_compras!==undefined && Number(this.tarifa_cree_compras>0)){
          let xvalor = this.getField('total_subtotal').getValue() //!== "" ? this.getField('total_subtotal').getValue().replaceAll('.','').replaceAll(',','.'): 0;
          let xtarifa = this.tarifa_cree_compras;
          let xvalorcree = 0;
          xvalorcree = xvalor * (xtarifa / 100);
          let decimal = (xvalorcree + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>this.numero_decimales_compras)
            xvalorcree=xvalorcree.toFixed(this.numero_decimales_compras);
          }
          /* this.getField('cree').setValue(xvalorcree); */
          this.calcularNeto();
        }else{
          this.mostrarMensajeAdicional('Al proveedor '+this.getField('nit').getValue()+' no se le calcula retención CREE');
        }
      }
    } catch (error) {
      this.mostrarMensajeAdicional('Al proveedor '+this.getField('nit').getValue()+' no se le calcula retención CREE');
    }
    
  }

  validarRetefuente(){
    this.getField('base_retencion').setValue('');
    /* this.getField('retenido').setValue(''); */
/*     if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
      if(this.retefuente==='S' && this.getField('select_retencion').getValue()!==''){
        let xvalor = Number(this.getField('total_subtotal').getValue().replaceAll('.','').replaceAll(',','.'));
        let xtarifa = Number(this.getField('select_retencion').getValue());
        let xvalorretencion = 0;
  
        xvalorretencion = xvalor * (xtarifa / 100);

        let decimal = (xvalorretencion + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
          if(decimal.length>this.numero_decimales_compras)
            xvalorretencion=xvalorretencion.toFixed(this.numero_decimales_compras);
        }
        this.getField('retenido').setValue(Number(xvalorretencion));
        if(this.existe_ubicaciones===false)
          this.getField('base_retencion').setValue(xvalor);


      }else if(this.getField('select_retencion').getValue()!=='0' && this.getField('select_retencion').getValue()!==''){
        this.mostrarMensajeAdicional('Al proveedor '+this.getField('nit').getValue()+' no se le calcula Retefuente');
        this.getField('select_retencion').setValue('');
        this.getField('base_retencion').setValue('');
      }
      this.calcularNeto();
    }else{
      if(this.getField('select_retencion').getValue()!=='0' && this.getField('select_retencion').getValue()!==''){
        this.getField('select_retencion').setValue('');
      }
    } */
  }

  validarReteIva(){
    if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
      if(this.reteiva==='S'){
          let xvalor = this.getField('valor_iva').getValue() //!== "" ? this.getField('valor_iva').getValue().replaceAll('.','').replaceAll(',','.'): 0;
          let xtarifa = this.tarifa_reteiva;
          let xvalorreteiva = 0;
          xvalorreteiva = xvalor * (xtarifa / 100);

          let decimal = (xvalorreteiva + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>this.numero_decimales_compras)
            xvalorreteiva=xvalorreteiva.toFixed(this.numero_decimales_compras);
          }
          /* this.getField('reteiva').setValue(xvalorreteiva); */
          this.calcularNeto();
      }else{
        this.mostrarMensajeAdicional('Al proveedor '+this.getField('nit').getValue()+' no se le calcula ReteIVA');
      }
    }
  }

  traerImpuestosNit(){
    let datos={ datos: {
      nit: this.getField('nit').getValue(),
      sede: this.getField('sede').getValue(),
    }};
    this.generalFormatPmv = { tipo_servicio: 'maes-terceros', operacion: '1_impuestos', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerImpuestos,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
}

sucessTraerImpuestos(data)
{
    if (data.estado_p === 200 && data.data.length >= 1) {
      this.retefuente=data.data[0].retefuente;
      this.reteiva=data.data[0].reteiva;
      this.reteica=data.data[0].reteica;
      this.tarifa_reteica=data.data[0].tarifa_reteica;
      this.tarifa_cree_compras=data.data[0].tarifa_cree_compras;
      this.proveedor_tipo_dias_vencimiento=data.data[0].proveedor_tipo_dias_vencimiento;
      this.dias_vence_proveedor=data.data[0].dias_vence_proveedor;
      this.regimen=data.data[0].regimen;
      this.calcula_iva_item=data.data[0].calcula_iva_item;
      //llenar los datos del nit por si cambiaron
      this.getField('nombre').setValue(data.data[0].nombre);
      this.getField('responsable_iva').setValue(data.data[0].responsable_iva);

      this.getField('bt_info_doc').setVisible(false);
      this.getField('select_documentos').setValue(' ');
      this.compraDsCri=false;
      if(this.existe_ubicaciones ===true){
        if(this.facturacion_electronica_propia==='S' || this.docSoporteCri==='S'){
          //trae regimen
          if (this.regimen ==='RS' && this.maneja_wms_externo==='S') {
            this.getField('label_numero').setVisible(true);
            this.getField('factura_numero').setVisible(true);

              this.getField('label_documento').setVisible(true);
              this.getField('select_documentos').setVisible(true);
              this.traerDocumentos();
          } else {
              if (this.regimen ==='RS') {
                  this.getField('label_numero').setVisible(false);
                  this.getField('factura_numero').setVisible(false);
  
                  this.getField('label_documento').setVisible(true);
                  this.getField('select_documentos').setVisible(true);
                  if (this.docSoporteCri==='S') {
                      this.getField('bt_info_doc').setVisible(true);
                      this.compraDsCri = true;
                      this.getField('modificar_cabys').setVisible(true);
                  }
                  this.traerDocumentos();
              } else {
                this.getField('bt_info_doc').setVisible(false);
                this.getField('label_numero').setVisible(true);
                this.getField('factura_numero').setVisible(true);
                this.getField('label_documento').setVisible(false);
                this.getField('select_documentos').setVisible(false);
              }
          }
        }
      }else{
        if (this.regimen ==='RS') {
          this.getField('label_numero').setVisible(false);
          this.getField('factura_numero').setVisible(false);
          this.getField('label_documento').setVisible(true);
          this.getField('select_documentos').setVisible(true);
          this.traerDocumentos();
        }else{
          this.getField('label_numero').setVisible(true);
          this.getField('factura_numero').setVisible(true);
          this.getField('label_documento').setVisible(false);
          this.getField('select_documentos').setVisible(false);
        }
        if(this.calcula_iva_item==='S'){
            if(this.existe_impuestos && this.existe_impuestos_articulo && this.calcula_iva_item==='S')
              this.getField("cantidad_articulo").setOnBlur(()=>{this.calcularFactor();this.traerImpuestosArticulo();});
            else
              this.getField("cantidad_articulo").setOnBlur(()=>{this.calcularFactor()});
        }
        if(this.calcular_cree_automatico==='S' && this.getField('sede').getValue()!=='')
          this.validarCree();
      }
      
    }else if(data.estado_p === 404){
        this.alertGeneral.toggle(true, 'No sé encontrarón los impuestos para el NIT.', 'error');
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
}

  llenarSelectRete(){
      let datos={ datos: {
        sucursal_ingreso: this.sucursal_ingreso
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cargarretenciones', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.suceesLlenarSelectRete,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }

  suceesLlenarSelectRete(data)
  {
    if (data.estado_p === 200 && data.data.length >= 1) {

      let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
      data.data.forEach(item => {
          let dataOp = {}
          dataOp['value'] = item.valor;
          dataOp['text'] = item.valor;
          dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
          opciones.push(dataOp);
      });
      opciones.sort();
    /*   this.getField('select_retencion').setOptions(opciones); */

    }else if(data.estado_p === 404){
        this.alertGeneral.toggle(true, 'No sé encontrarón retenciones validas.', 'error');
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
  }
  }


  //fin nuevas
  deshabilitarCorreo(){
    this.getField('email').setDisabled(true);
    this.getField('email').setValue('');
    this.getField('email').setError(false,'');
    this.getField('radio_correo').setDisabled(true);
    this.getField('radio_correo').setValue("N");
}

habilitarCorreo(){
    this.getField('email').setDisabled(false);
    this.getField('radio_correo').setDisabled(false);
    this.getField('radio_correo').setValue("N");
}

  validarOrden(){
    let errores=0;
    this.getField('grabar').setDisabled(true);
    if(this.validarfechas()===false){
        errores++;
    }

    if(!this.getField('no_orden').valid()){
      errores++;
    }


    if(!this.getField('nit').valid()){
      errores++;
    }

    if(!this.getField('sede').valid()){
      errores++;
    }
    
    if(!this.getField('nombre').valid()){
      errores++;
    }

    if(this.getField('detalle').getValue().toString().length>0) {
      if(this.existe_ubicaciones===false && !this.getField('detalle').valid()){
        errores++;
      }
    }else{
      this.getField('detalle').setError(false,'');
      this.getField('detalle').setValue('.')
    }

    if(!this.getField('responsable_iva').valid()){
      errores++;
    }

    if(!this.getField('select_direccion').valid()){
      errores++;
    }

    if (this.getField('factura_numero').getValue()==='0' || this.getField('factura_numero').getValue()===0){
      this.getField("factura_numero").setError(true,"Este campo es requerido.");
      this.alertGeneral.toggle(true, 'La factura no puede tener como número cero.', 'error');
      errores++;
    }
/*     if(!this.getField('valor_neto').valid())
      errores++; */

    //si no maneja wms externo, al grabar el va a validar si con el documento trae el consecutivo
    if(this.regimen==='RS'){
      //manea select documentos, entonces se valida al grabaar
    }else{
      if(!this.getField('factura_numero').valid()){
        errores++;
      }
    }
   
    //validar bodega, se valida cada vez que se cambia el select
    //validar si quiere orden se hace despues de impuestos


    if(errores===0){
      if(this.getField('total_pedido').getValue()==='0' || this.getField('total_pedido').getValue()===''){
        this.alertGeneral.toggle(true, 'El total del pedido no puede ser cero.', 'error');
        this.getField('grabar').setDisabled(false);
      }else if(this.getField('factura_numero').getValue()==='' && this.getField('select_documentos').getValue()==='' ){
        this.alertGeneral.toggle(true, 'El necesario proporcionar el número factura o un documento.', 'error');
        this.getField('grabar').setDisabled(false);
      }else{
        this.validarImpuestos();
      }
    }else{
      this.getField('grabar').setDisabled(false);
    }


  }

  validarImpuestos(){
    let impuestos='';
/*       if (this.getField('retenido').getValue().toString().length === 0 && this.retefuente==='S') {
        impuestos = impuestos + "Retención en la Fuente, ";
      } */
/*       if (this.getField('reteiva').getValue().toString().length === 0 && this.reteiva==='S' && this.getField('valor_iva').getValue()!==0) {
          impuestos = impuestos + "ReteIVA, ";
      } */
      /*if (this.getField('reteica').getValue().toString().length === 0 && this.reteica==='S') {
          impuestos = impuestos + "ReteICA, ";
      }*/
      if (/* this.getField('cree').getValue().toString().length === 0 &&  */this.tarifa_cree_compras!=='' && this.tarifa_cree_compras!==' ' && this.tarifa_cree_compras!==undefined && Number(this.tarifa_cree_compras>0)) {
          impuestos = impuestos + "Retención CREE, ";
      }
      if (impuestos.length > 0) {
          this.getField('grabar').setDisabled(false);
          impuestos=impuestos.substring(0,impuestos.length-2);
          let mensaje= "Al proveedor " + this.getField('nit').getValue() + " se le calcula: " + impuestos
                  + ".¿Desea continuar la grabación de la compra sin calcular los impuestos?";
          this.getField('confirmModalCustom').setTitleAndContent('Mensaje',mensaje);
          this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validarRequiereOrdenGrabar();});
          this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);

      } else {
          this.validarRequiereOrdenGrabar();
      }
  }

  validarfechas(){
    if(this.getField('fecha_vencimiento_xml').getValue()!=='' && this.getField('fecha_vencimiento_xml').getValue()!==' '){
      this.getField("fecha_vencimiento_xml").setError(false,'');
      this.getField('bt_aceptar_fechas').setDisabled(false);
      let fecha_actual = new Date().toISOString().split('T')[0];
      let fecha_vencimiento = new Date(this.getField("fecha_vencimiento_xml").getValue()).toISOString().split('T')[0];
      if(fecha_vencimiento < fecha_actual){
          this.getField("fecha_vencimiento_xml").setError(true,"No puede ser menor a la emisión de la factura.");
          this.getField('bt_aceptar_fechas').setDisabled(true); 
          return false;
      }
      this.getField('bt_aceptar_fechas').setDisabled(false); 
      return true;
    }else{
      return false;
    }
}



  abrirImprimir(){
        
        this.getField("modal_imprimir").setLabel("Compra No. "+this.factura_numero_aux);
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
  }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' && this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    this.nombre_pdf='compra';
                    this.nombre_excel='compra';
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.genearModalGlobales();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    genearModalGlobales(){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      let datos={ datos: {
        xsucursal:this.xsucursal,
        factura_numero: this.factura_numero_aux,
        xfecha: this.fecha_servidor_aux,
        nit: this.getField('nit').getValue(),
        sede: this.getField('sede').getValue(),
        email: this.getField('email').getValue(),
        sucursal_ingreso: this.sucursal_ingreso,
        operacion: this.operacion_actual
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'archivoitems', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessTraerDatos,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
      
  }

  sucessTraerDatos(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "sucursal": this.lista.sucursal,
                    "direccion_xml": this.lista.direccion_xml,
                    "telefono_xml": this.lista.telefono_xml,
                    "nit_xml": this.lista.nit_xml,
                    "ciudad_xml": this.lista.ciudad_xml,
                    "compra_nro": this.lista.compra_nro,
                    "bodega": this.lista.bodega,
                    "fecha_compra": this.lista.fecha_compra,
                    "fecha_vence": this.lista.fecha_vence,
                    "orden_compra_no": this.lista.orden_compra_no,
                    "usuario": this.lista.usuario,

                };

            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('rejilla_items_result').toggle(true);
            this.getField("modal_result").setLabel('Compra');
            this.getField('modal_result').handleClickOpen();
            this.gridOptionsComponentesItems['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
            //con ubicacion-->codigo,xnombre,alias,ubicacion_bodega,cantidad,subtota1,tarifa_iva,valor_iva,valor_item
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});
          
            if(this.maneja_bodega_ubicacion!=='S'){
              configCell.set('ubicacion_bodega', {cellRenderer:(props) => {return ""+props.data.ubicacion_bodega } , hide: true});
            }
            this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems,configCell);


            this.getField('proveedor_result').setValue(this.lista.proveedor);
            this.getField('direcccion').setValue(this.lista.direccion_nit);
            this.getField('telefono').setValue(this.lista.telefono_nit);
            this.getField('ciudad').setValue(this.lista.ciudad_nit);

            this.getField('retencion_result').setValue(this.currencyFormatterGeneral(this.lista.retencion));
            this.getField('valor_exento_result').setValue(this.currencyFormatterGeneral(this.lista.valor_exento));
            this.getField('reteiva_result').setValue(this.currencyFormatterGeneral(this.lista.reteiva));
            this.getField('valor_grabado_result').setValue(this.currencyFormatterGeneral(this.lista.grabado));

            this.getField('reteica_result').setValue(this.currencyFormatterGeneral(this.lista.retencionica));
            this.getField('cree_result').setValue(this.currencyFormatterGeneral(this.lista.cree));
            this.getField('aproximacion_result').setValue(this.currencyFormatterGeneral(this.lista.aproximacion));
            this.getField('iva_result').setValue(this.currencyFormatterGeneral(this.lista.total_iva));
            this.getField('fletes_result').setValue(this.currencyFormatterGeneral(this.lista.fletes));
            this.getField('valor_total_result').setValue(this.currencyFormatterGeneral(this.lista.valor_total));

            //descuentos
            let fecha1=this.lista.descuento1.split(':')[0];
            let fecha2=this.lista.descuento2.split(':')[0];
            let fecha3=this.lista.descuento3.split(':')[0];
            let descuento1=this.currencyFormatterGeneral(Number(this.lista.descuento1.split(':')[1].trim()));
            let descuento2=this.currencyFormatterGeneral(Number(this.lista.descuento2.split(':')[1].trim()));
            let descuento3=this.currencyFormatterGeneral(Number(this.lista.descuento3.split(':')[1].trim()));
            
            this.getField('descuento1_result').setValue(fecha1+' : '+descuento1);
            this.getField('descuento2_result').setValue(fecha2+' : '+descuento2);
            this.getField('descuento3_result').setValue(fecha3+' : '+descuento3);

            
      }else if(data.estado_p === 404){
          //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          this.mostrarMensajeNoHayDatos();
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
  }


    enviarCorreoTerceros(){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      let datos={ datos: {
        xsucursal:this.xsucursal,
        factura_numero: this.factura_numero_aux,
        xfecha: this.fecha_servidor_aux,
        nit: this.getField('nit').getValue(),
        sede: this.getField('sede').getValue(),
        email: this.getField('email').getValue(),
        sucursal_ingreso: this.sucursal_ingreso,
        operacion: this.operacion_actual,
        radio_correo: this.getField('radio_correo').getValue(),
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'archivoitems', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successEnviarCorreo,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }
  
  successEnviarCorreo(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){
          this.mostrarMensajeEmailEnviado();
      }else{
          this.mostrarMensajeEmailFallo();
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      }
  }

  mostrarMensajeEmailEnviado(){
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeEmailFallo(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeNoHayDatos(){
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
}

  generarPdfTabla3()
    {       
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2= {
              xsucursal:this.xsucursal,
              factura_numero: this.factura_numero_aux,
              xfecha: this.fecha_servidor_aux,
              nit: this.getField('nit').getValue(),
              sede: this.getField('sede').getValue(),
              email: this.getField('email').getValue(),
              sucursal_ingreso: this.sucursal_ingreso,
              operacion: this.operacion_actual,
            };
            let op='archivoitems';
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabarcompras","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_pdf+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
              this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2= {
              xsucursal:this.xsucursal,
              factura_numero: this.factura_numero_aux,
              xfecha: this.fecha_servidor_aux,
              nit: this.getField('nit').getValue(),
              sede: this.getField('sede').getValue(),
              email: this.getField('email').getValue(),
              sucursal_ingreso: this.sucursal_ingreso,
              operacion: this.operacion_actual,
            };
            let op='archivoitems';

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabarcompras","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_excel+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }



  validarCuenta(){
    let datos={ datos: {
      nit:this.getField('nit').getValue(),
      sede:this.getField('sede').getValue(),
      sucursal_ingreso:this.sucursal_ingreso
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'validarcontable', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessValidarCuenta,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  sucessValidarCuenta(data)
  {   
      if (data.estado_p === 200)
      {
        this.traerImpuestosNit();
      }
      else 
      {
        this.getField('nit').setValue('');
        this.getField('nombre').setValue('');
        this.getField('sede').setValue('');
         if(data.estado_p===404) {

              this.alertGeneral.toggle(true, 'No hay datos', 'error');
         }
         else {
            this.getField('confirmModalCustom').setTitleAndContent('Compras', data.data.errores.mensaje);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
         }
     }
  }


  onSelectionChangedPrincipal(){
    this.fila_seleccionada = this.gridOptionsComponentes.api.getSelectedRows();
  }

  onSelectionChanged(){

  }

  validacionesAgregar(){
    if(Number(this.getField('total_item').getValue()!==0)){
        this.getField('grabar_agregar').setDisabled(true);
        if(this.getField('codigo_articulo').valid() 
        && this.getField('nombre_articulo').valid()
        && this.getField('tarifa_iva').valid() 
        && this.getField('descuento').valid() 
        && this.getField('cantidad_articulo').valid()
        && this.getField('impuestos2').valid()){
          this.mostrarMensajeValidandoInformacion();

            let datos={ datos: {
              sucursal_ingreso:this.sucursal_ingreso,
              select_direccion:this.getField('select_direccion').getValue(),
              costo:this.getField('costo').getValue(),
              codigo_articulo:this.getField('codigo_articulo').getValue(),
              existe_ubicaciones:this.existe_ubicaciones 
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'validarcostopro', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessValidaciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
          this.getField('grabar_agregar').setDisabled(false);
        }
    }else{
        this.alertGeneral.toggle(true, 'El total del item no puede ser cero.', 'error');
    }
      
  }

  mostrarMensajeValidandoInformacion(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
    this.getField('confirmModalCustom').setClickDialog(()=>{});  
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  validacionesAgregar2(input){
    if(this.getField('codigo_articulo').valid()){
      this.getField('grabar_agregar').setDisabled(true);
      this.mostrarMensajeValidandoInformacion();
        let datos={ datos: {
              sucursal_ingreso:this.sucursal_ingreso,
              input:input,
              select_direccion:this.getField('select_direccion').getValue(),
              costo:this.getField('costo').getValue(),
              codigo_articulo:this.getField('codigo_articulo').getValue(),
              existe_ubicaciones:this.existe_ubicaciones 
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'validarcostopro', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessValidaciones,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
}

  sucessValidaciones(data)
    {
      this.getField('confirmModalCustom').toggle(false);
      this.getField('grabar_agregar').setDisabled(false);
        if (data.estado_p === 200)
        { 
            if(data.data.costo_pro!==undefined){
              //pedir el input
              let costo_pro=data.data.costo_pro;
              if(costo_pro===0){
                  this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El costo_pro_'+this.getField('select_direccion').getValue()+' es cero. ¿Está seguro de continuar?');
                  this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                  this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                  this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                  this.getField('confirmModalCustom').toggle(true);
              }else{
                  let valor_costo = Math.abs((costo_pro - this.getField('costo').getValue())/ costo_pro) * 100;
                  let margen_costo_promedio=data.data.margen_costo_promedio;

                  if (valor_costo > margen_costo_promedio) {
                    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El valor del costo sobrepasa el margen costo entrada. Margen costo entrada= '+margen_costo_promedio+'. Variación='+valor_costo+'. ¿Está seguro de continuar? ');
                    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                  }else{
                    this.validacionesAgregar2(0)
                  }
              }
            }else{
              this.validarUbicacionBodega();
            }
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }
    validarUbicacionBodega(){
      if(this.wms==='S' && this.existe_ubicaciones===true){
          if(this.wms_nuevo==='S'){
            this.grabarItem();
          }else{
            this.existeUbicacion();
          }
      }else{
        this.grabarItem();
      }
    }

    existeUbicacion(){
      this.mostrarMensajeValidandoInformacion();
      let datos={ datos: {
          ubicacion_agregar:this.getField('ubicacion_agregar').getValue(),
          select_direccion:this.getField('select_direccion').getValue(),
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_existe_ubicacion', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessExisteubicacion,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
      }

    sucessExisteubicacion(data){
      this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {    
            if(data.data[0].total!==undefined && data.data[0].total>0){
              this.grabarItem();
            }else{
              this.getField('grabar_agregar').setDisabled(false);
              this.mostrarMensajeAdicional("La ubicación no existe en la bodega " + this.getField('select_direccion').getValue());
            }
        }else {
          this.getField('grabar_agregar').setDisabled(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }



    grabarItem(){
      this.mostrarMensajeGuardando();
      this.getField('grabar_agregar').setDisabled(true);
      let datos={};
      if(this.existe_ubicaciones){
        datos={ datos: {
          modificando: this.modificando,
          no_orden:this.getField('no_orden').getValue(),
          ubicacion_agregar:this.getField('ubicacion_agregar').getValue(),
          origen:this.getField('origen').getValue(),
          select_direccion: this.getField('select_direccion').getValue(),
          codigo_articulo: this.getField('codigo_articulo').getValue(),
          cantidad_articulo: this.getField('cantidad_articulo').getValue(),
          nombre_articulo: this.getField('nombre_articulo').getValue(),
          tarifa_iva: this.getField('tarifa_iva').getValue(),
          costo: this.getField('precio_sin_iva').getValue(),
          impuestos2: this.getField('impuestos2').getValue(),
          valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
          valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
          valor_unidad: this.getField('valor_unidad').getValue(),
          total_item: this.getField('total_item').getValue(),
          sucursal_ingreso : this.sucursal_ingreso,
          existe_ubicaciones:this.existe_ubicaciones,
        }};
      }else{
        datos={ datos: {
          modificando: this.modificando,
          no_orden:this.getField('no_orden').getValue(),
          select_direccion: this.getField('bodega').getValue(),
          codigo_articulo: this.getField('codigo_articulo').getValue(),
          cantidad_articulo: this.getField('cantidad_articulo').getValue(),
          nombre_articulo: this.getField('nombre_articulo').getValue(),
          tarifa_iva: this.getField('tarifa_iva').getValue(),
          costo: this.getField('precio_sin_iva').getValue(),
          impuestos2: this.getField('impuestos2').getValue(),
          valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
          valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
          valor_unidad: this.getField('valor_unidad').getValue(),
          total_item: this.getField('total_item').getValue(),
          sucursal_ingreso : this.sucursal_ingreso,
          existe_ubicaciones:this.existe_ubicaciones,
          xt_otros_impuestos:this.getField('xt_otros_impuestos').getValue(),
          xotros_impuestos:this.getField('xotros_impuestos').getValue(),
          numero_item:this.getField('numero_item').getValue(),
          costo_administrativo:this.getField('costo_administrativo').getValue(),
          total_iva:this.getField('total_iva').getValue(),
          total_item_sin_iva:this.getField('total_item_sin_iva').getValue(),
        }};
      }
      
      let array_impuestos=this.gridOptionsImpArti['rowData'];
      if(this.existe_ubicaciones===false && array_impuestos!==undefined && array_impuestos.length>0){
        datos.datos.array_impuestos=array_impuestos;
      } 
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'grabaritem_mod', operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.sucessGrabarItem,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }

    grabarItem2(opcion){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      this.getField('grabar_agregar').setDisabled(true);

      let datos={};
      if(this.existe_ubicaciones){
        datos={ datos: {
          modificando: this.modificando,
          no_orden:this.getField('no_orden').getValue(),
          ubicacion_agregar:this.getField('ubicacion_agregar').getValue(),
          origen:this.getField('ubicacion_agregar').getValue(),
          select_direccion: this.getField('select_direccion').getValue(),
          codigo_articulo: this.getField('codigo_articulo').getValue(),
          cantidad_articulo: this.getField('cantidad_articulo').getValue(),
          nombre_articulo: this.getField('nombre_articulo').getValue(),
          tarifa_iva: this.getField('tarifa_iva').getValue(),
          costo: this.getField('precio_sin_iva').getValue(),
          impuestos2: this.getField('impuestos2').getValue(),
          valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
          valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
          valor_unidad: this.getField('valor_unidad').getValue(),
          total_item: this.getField('total_item').getValue(),
          sucursal_ingreso : this.sucursal_ingreso,
          existe_ubicaciones:this.existe_ubicaciones,
          input:0,
        }};
      }else{
        datos={ datos: {
          modificando: this.modificando,
          no_orden:this.getField('no_orden').getValue(),
          select_direccion: this.getField('bodega').getValue(),
          codigo_articulo: this.getField('codigo_articulo').getValue(),
          cantidad_articulo: this.getField('cantidad_articulo').getValue(),
          nombre_articulo: this.getField('nombre_articulo').getValue(),
          tarifa_iva: this.getField('tarifa_iva').getValue(),
          costo: this.getField('precio_sin_iva').getValue(),
          impuestos2: this.getField('impuestos2').getValue(),
          valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
          valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
          valor_unidad: this.getField('valor_unidad').getValue(),
          total_item: this.getField('total_item').getValue(),
          sucursal_ingreso : this.sucursal_ingreso,
          existe_ubicaciones:this.existe_ubicaciones,
          xt_otros_impuestos:this.getField('xt_otros_impuestos').getValue(),
          xotros_impuestos:this.getField('xotros_impuestos').getValue(),
          numero_item:this.getField('numero_item').getValue(),
          costo_administrativo:this.getField('costo_administrativo').getValue(),
          total_iva:this.getField('total_iva').getValue(),
          total_item_sin_iva:this.getField('total_item_sin_iva').getValue(),
          input:0,
        }};
      }
      
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'grabaritem_mod', operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.sucessGrabarItem,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }

    sucessGrabarItem(data){
        this.getField('grabar_agregar').setDisabled(false);
        if (data.estado_p === 200 )
        {
            this.getField('confirmModalCustom').toggle(false);
            if(data.data.input !== undefined){
                //preguntar si desea adicionar
                this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.mensaje);
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.recalcularArticulo() });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.getField('modal_agregar').handleClose();
                this.limpiar_numero=false;
                this.refescarTabla();
            }
        }
        else 
        { 
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }
  

    recalcularArticulo(){
      this.getField('grabar_agregar').setDisabled(true);
      let datos={ datos: {
        codigo_articulo:this.getField('codigo_articulo').getValue(),
        sucursal_ingreso:this.sucursal_ingreso
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_articulo', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessRecalcularArticulo,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }

    sucessRecalcularArticulo(data)
    {
        if (data.estado_p === 200)
        {
          this.getField('cantidad_articulo').setValue(this.getField('cantidad_articulo').getValue()+data.data[0].cantidad);
          this.grabarItem2(0);
        }
        else 
        {
            this.getField('grabar_agregar').setDisabled(false);
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

  abrirModalAgregar(){
    this.modificando='false';
    if(this.requiere_orden_compra==='S'){
      this.mostrarMensajeAdicional("No puede agregar productos.");
    }else{ 
      if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()
       && this.getField('no_orden').valid() && this.getField('select_direccion').valid()){
          this.getField('codigo_articulo').setError(false,'');
          this.getField('nombre_articulo').setError(false,'');
          this.getField('tarifa_iva').setError(false,'');
          this.getField('descuento').setError(false,'');
          this.getField('cantidad_articulo').setError(false,'');
          this.getField('impuestos2').setError(false,'');
          this.getField('contenido').setError(false,'');
          this.getField('presentacion').setError(false,'');
    
          this.getField('total_item_sin_iva').setError(false,'');
          this.getField('precio_sin_iva').setError(false,'');
          this.getField('valor_sin_iva_ma_impuestos').setError(false,'');
          this.getField('total_iva').setError(false,'');
          this.getField('valor_iva_unidad').setError(false,'');
          this.getField('total_impuestos').setError(false,'');
          this.getField('valor_unidad').setError(false,'');
          this.getField('total_item').setError(false,'');
          this.getField('costo_bodega').setError(false,'');
          //limpiar impuestos
          this.gridOptionsImpArti['rowData']=[];
    
          this.getField('contenido').setValue('');
          this.getField('presentacion').setValue('');
          this.getField('codigo_articulo').setValue('');
          this.getField('nombre_articulo').setValue('');
          this.getField('tarifa_iva').setValue('');
          this.getField('descuento').setValue(0);
          this.getField('cantidad_articulo').setValue(0);
          this.getField('impuestos2').setValue(0);    
          this.getField('precio_sin_iva').setValue(0);
          this.getField('valor_sin_iva_ma_impuestos').setValue(0);
          this.getField('total_iva').setValue(0);
          this.getField('valor_iva_unidad').setValue(0);
          this.getField('total_impuestos').setValue(0);
          this.getField('valor_unidad').setValue(0);
          this.getField('total_item_sin_iva').setValue(0);
          this.getField('total_item').setValue(0);
          this.getField('costo_bodega').setValue(0);
          this.getField('modal_agregar').handleClickOpen();
          this.getField('ubicacion_agregar').setValue('999999999999');

          this.getField('xt_otros_impuestos').setValue(0);
          this.getField('xotros_impuestos').setValue(0);
          this.getField('numero_item').setValue(0);
          this.getField('costo_administrativo').setValue(0);
          this.getField('bodega').setValue(this.getField('select_direccion').getValue());
          if(this.wms==='S'){
            this.getField('ubicacion_agregar').setDisabled(false);
          }else{
            this.getField('ubicacion_agregar').setDisabled(true);/// No editable
          }
      }
    }
  }

  limpiarCampos(){
          this.primeraVez();
          //this.getField('nit').setValue('');
          //this.getField('sede').setValue('');
          //this.getField('nombre').setValue('');
        
          this.getField('label_numero').setVisible(true);
          this.getField('factura_numero').setVisible(true);
          this.getField('label_documento').setVisible(false);
          this.getField('select_documentos').setVisible(false);
          this.getField('bt_info_doc').setVisible(false);
          //para decidir si mostrar numero factura o select documentos
          if(this.getField('nit').getValue()!=='' && this.getField('nombre').getValue()!==''){
            if(this.existe_ubicaciones ===true){
              if(this.facturacion_electronica_propia==='S' || this.docSoporteCri==='S'){
                //trae regimen
                if (this.regimen ==='RS' && this.maneja_wms_externo==='S') {
                  this.getField('label_numero').setVisible(true);
                  this.getField('factura_numero').setVisible(true);
      
                    this.getField('label_documento').setVisible(true);
                    this.getField('select_documentos').setVisible(true);
                    this.traerDocumentos();
                } else {
                    if (this.regimen ==='RS') {
                        this.getField('label_numero').setVisible(false);
                        this.getField('factura_numero').setVisible(false);
        
                        this.getField('label_documento').setVisible(true);
                        this.getField('select_documentos').setVisible(true);
                        if (this.docSoporteCri==='S') {
                            this.getField('bt_info_doc').setVisible(true);
                            this.compraDsCri = true;
                            this.getField('modificar_cabys').setVisible(true);
                        }
                        this.traerDocumentos();
                    } else {
                      this.getField('bt_info_doc').setVisible(false);
                      this.getField('label_numero').setVisible(true);
                      this.getField('factura_numero').setVisible(true);
                      this.getField('label_documento').setVisible(false);
                      this.getField('select_documentos').setVisible(false);
                    }
                }
              }
            }else{
              if (this.regimen ==='RS') {
                this.getField('label_numero').setVisible(false);
                this.getField('factura_numero').setVisible(false);
                this.getField('label_documento').setVisible(true);
                this.getField('select_documentos').setVisible(true);
                this.traerDocumentos();
              }else{
                this.getField('label_numero').setVisible(true);
                this.getField('factura_numero').setVisible(true);
                this.getField('label_documento').setVisible(false);
                this.getField('select_documentos').setVisible(false);
              }
              if(this.calcula_iva_item==='S'){
                  if(this.existe_impuestos && this.existe_impuestos_articulo && this.calcula_iva_item==='S')
                    this.getField("cantidad_articulo").setOnBlur(()=>{this.calcularFactor();this.traerImpuestosArticulo();});
                  else
                    this.getField("cantidad_articulo").setOnBlur(()=>{this.calcularFactor()});
              }
              if(this.calcular_cree_automatico==='S' && this.getField('sede').getValue()!=='')
                this.validarCree();
            }
          }

          this.getField('factor').setValue('1');
          this.getField('detalle').setValue('');
          this.limpiarCamposInferiores();
          this.getField('total_subtotal').setValue(0);
          this.getField('valor_iva').setValue(0);
          this.getField('impuestos').setValue(0);
          this.getField('total_pedido').setValue(0);
          this.getField('no_orden').setValue('0');
          this.getField('factura_numero').setValue('0');
          
          this.getField('total_subtotal_xml').setValue(0);
          this.getField('diferencia_total_subtotal').setValue(0);
          this.getField('valor_iva_xml').setValue(0);
          this.getField('diferencia_valor_iva').setValue(0);
          this.getField('impuestos_xml').setValue(0);
          this.getField('total_pedido_xml').setValue(0);
          this.getField('diferencia_total_factura').setValue(0);
        }

  limpiarCamposInferiores(){ 
    if (this.maneja_wms_externo==='S' && this.orden_wms && this.getField('no_orden').getValue()!=='0' && this.getField('no_orden').getValue()!=='') {
      this.getField('factura_numero').setDisabled(true);
    } else {
      this.getField('factura_numero').setDisabled(false);
    }
    /* this.getField('fecha_vence_final').setError(false,''); */
    this.getField('factura_numero').setError(false,'');
    this.getField('base_retencion').setValue('');
    if(this.limpiar_numero){ // cuando no trae OrdenWms para no limpiar el numero
      //this.getField('factura_numero').setValue('');
      this.getField('select_documentos').setValue('');
      
      /* this.getField('fecha_vence_final').setValue(''); 
      this.getField('fecha_recibido').setValue('');*/
      this.fecha_vencimiento='';
      this.fecha_emision_actual='';

      //auxiliares
      this.nit_responsable='';
      this.sede_responsable='';
      this.nombre_responsable='';
      this.numero_factura_proveedor='';
      this.tipo_de_cambio='';
      this.select_condicion_venta=' ';
      this.select_medio_pago=' ';
      this.select_moneda=' ';
      this.select_actividad_economica=' ';
      this.observaciones_doc='';
      
      /* this.getField('flete').setValue(''); */
      this.nit_fletes='';
      this.sede_fletes='';
      this.nombre_fletes='';
      this.valor_fletes='';
      this.tarifa_fletes='';
      this.valor_retenido_fletes='';
      this.ch_facturado='false';
      this.ch_cantidad='false';
      this.ch_valor='false';
      this.ch_peso='false';
      this.no_orden_aux='';
      this.factura_numero_aux='';
      this.fecha_servidor_aux='';
    }
    /* this.getField('reteiva').setValue(''); */
    /* this.getField('select_retencion').setValue(''); */
    /* this.getField('retenido').setValue(''); */
    /* this.getField('tarifa_reteica').setValue(''); 
    this.getField('reteica').setValue('');
    this.getField('ajuste').setValue('');
    this.getField('valor_neto').setValue('');
    this.getField('cree').setValue('');*/
    //limpiar auxiliares
    this.modificando='false';

    this.calculo_factor=false;
    this.fila_seleccionada=[];
    this.cantidad_escaneada=false;

    //descuentos ( se guardan porque al cerrar el modal se limpian los campos)
    this.valor_descuento1=0;
    this.valor_descuento2=0;
    this.valor_descuento3=0;
    this.valor_descuento_directo=0;
    this.valor_descargue=0;
    this.fecha1='';
    this.fecha2='';
    this.fecha3='';
  
}

  comenzarForm(){
    if(this.getField('select_direccion').valid()){
      if(this.requiere_orden_compra==='S'){
          if((this.getField('no_orden').getValue()==='' && this.getField('no_orden').getValue()===' ' ) || this.getField('no_orden').getValue()==='0'){
              this.mostrarMensajeAdicional('Solo se puede grabar con orden de compra');
          }else{
            this.limpiarCampos();
            this.getField('nit').setError(false,'');
            this.getField('sede').setError(false,'');
            this.getField('nombre').setError(false,'');
          }
      }else{

          this.limpiarCampos();
          this.getField('nit').setError(false,'');
          this.getField('sede').setError(false,'');
          this.getField('nombre').setError(false,'');
      }
    }
    
  }



    primeraVez(){
      let datos={ datos: {
        sucursal_ingreso:this.sucursal_ingreso,
        xsucursal:this.xsucursal,
        existe_ubicaciones:this.existe_ubicaciones,
        select_direccion:this.getField('select_direccion').getValue()
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cargarprimeravez', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessPrimeraVez,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }

    sucessPrimeraVez(data)
    {
        if (data.estado_p === 200)
        {
          this.gridOptionsComponentes['rowData'] = [];
          let configCell = new Map();
          this.gridOptionsComponentes['rowData'] = data.data;
          /*configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.impuestos) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('subtotal2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal2) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('accion', { cellRenderer: this.setButtonBotonesTabla, width: 250, sortable: false, filter: false });   
                 */
          this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
          this.getField('rejilla').toggle(false);
          this.refescarTabla();  
        }
        else 
        {
          this.getField('rejilla').toggle(false);
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }

  }

  setButtonBotonesTabla(props){
    if(props.data.codigo_articulo!=='.'){
      let fragment = document.createDocumentFragment();
      let buttonModificar = this.crearBotonTabla(props, `Modificar`);
      let buttonEliminar = this.crearBotonTabla(props, 'Eliminar');
      buttonModificar.setAttribute("class", "buttonStyle2");
      buttonEliminar.setAttribute("class", "buttonStyle2");
      fragment.appendChild(buttonModificar);
      fragment.appendChild(buttonEliminar);
      return this.createElementJaivana(fragment);
    }
}

crearBotonTabla(data, boton) {
  let button = document.createElement('input');
  switch (boton) {
      case 'Modificar':
          button.setAttribute("id", `buttonModificar${data}`);
          break;
      case 'Eliminar':
          button.setAttribute("id", `buttonEliminar_${data}`);
          break;
      default:
          break;
  }
  button.onclick = () => {this.accionProcesar(data, boton) };
  button.setAttribute("value", boton);
  button.setAttribute("type", "button");
  return button;
}

accionProcesar(data, boton) {
  if (boton === 'Eliminar') {
      this.eliminarRegistro(data);
  }else if (boton === 'Modificar'){
      this.count=0;
      this.modificando='true';
      this.id_modificar=data.data.id
      //para limpiar los impuestos
      this.gridOptionsImpArti['rowData']=[];
      this.traerItem(data);
  }
}

  traerItem(props){
    this.mostrarMensajeGenerando();
    let op='';
    let datos={}
    if(this.existe_ubicaciones){
      op='1_item'
      datos={ datos: {
        codigo_articulo: props.data.codigo_articulo,
        origen: props.data.origen,
      }};
      
    }else{
      op='1_item_normal'
      datos={ datos: {
        codigo_articulo: props.data.codigo_articulo,
        select_direccion: props.data.bodega,
        numero_item:props.data.numero_item
      }};
    }
     

    
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: op, operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerItems,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
  }

  sucessTraerItems(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){ 
      this.getField('codigo_articulo').setValue(data.data[0].codigo_articulo);
      this.no_limpiar_mod=true;
      //this.getField('costo').setValue(data.data[0].costo)
      this.getField('precio_sin_iva').setValue(data.data[0].costo);
      this.getField('cantidad_articulo').setValue(data.data[0].cantidad);
      this.getField('impuestos2').setValue(data.data[0].impuestos);
      if(this.existe_ubicaciones){
        this.getField('ubicacion_agregar').setValue(data.data[0].ubicacion);
        this.getField('origen').setValue(data.data[0].origen);
      }else {
        this.getField('costo_administrativo').setValue(data.data[0].costo_adm);
        this.getField('numero_item').setValue(data.data[0].numero_item);
        this.getField('bodega').setValue(data.data[0].bodega);
      }
        
      if(this.wms==='S'){
          this.getField('ubicacion_agregar').setDisabled(false);
      }else{
          this.getField('ubicacion_agregar').setDisabled(true);/// No editable
      }
      this.getField('modal_agregar').handleClickOpen();
      this.validarCodigo();
    }else if(data.estado_p === 404){
        //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        this.mostrarMensajeNoHayDatos();
    }
    else{
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 
  }
  
  traerInfoArticulo(){
    this.mostrarMensajeGenerando();
    let datos={ datos: {
      value: this.getField('codigo_articulo').getValue()
    }};
    this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '42', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerArticulo,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
  }
    
    sucessTraerArticulo(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
        this.getField('nombre_articulo').setValue(data.data[0].nombre);
        if(this.getField('responsable_iva').getValue()==='N' || (this.regimen==='RS' && this.rsSinIva==='S'))
          this.getField('tarifa_iva').setValue(0);
        else
          this.getField('tarifa_iva').setValue(data.data[0].vivac);
        this.getField('contenido').setValue(data.data[0].contenido);
        this.getField('presentacion').setValue(data.data[0].presenta);
        this.getField('precio').setValue(data.data[0].precio1);
        this.getField('rentabilidad').setValue(data.data[0].rentabilidad);
        this.getField('impuestos2').setValue(data.data[0].impuesto1);
        this.getField('costo').setValue(data.data[0].costo);
        this.getField('descuento').setValue(0);
        
        if(this.existe_ubicaciones===false && this.existe_impuestos && this.existe_impuestos_articulo){
          this.traerImpuestosArticulo();
        }else if(this.existe_ubicaciones===false){
          this.calcularItem();
        }else{
          this.calcularItem();
        }
        
      }else if(data.estado_p === 404){
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
    }

    sucessTraerArticulo2(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.length>0){
        //eso es para evitar consumir dos veces el servicio traer articulo cuando prioridad barras esta en N
            //ya que no sería necesari, se guardo la respuesta dle primer servicio y con eso lleno los datos
        if(this.getField('codigo_articulo').getValue()!==''){
            data.forEach((element)=>{
                if(element.codigo_interno===this.getField('codigo_articulo').getValue()){
                  data[0]=element;
                  return;
                }
            });
        }
        this.getField('nombre_articulo').setValue(data[0].nombre);
        if(this.getField('responsable_iva').getValue()==='N' || (this.regimen==='RS' && this.rsSinIva==='S'))
          this.getField('tarifa_iva').setValue(0);
        else
          this.getField('tarifa_iva').setValue(data[0].vivac);
        this.getField('contenido').setValue(data[0].contenido);
        this.getField('presentacion').setValue(data[0].presenta);
        this.getField('precio').setValue(data[0].precio1);
        this.getField('rentabilidad').setValue(data[0].rentabilidad);
        //this.getField('impuestos2').setValue(data[0].impuesto1);
        this.getField('costo').setValue(data[0].costo);
        this.getField('descuento').setValue(0);

        if(this.existe_ubicaciones===false && this.existe_impuestos && this.existe_impuestos_articulo){
          this.traerImpuestosArticulo();
        }else if(this.existe_ubicaciones===false){
          this.calcularItem();
        }else{
          this.calcularItem();
        }
        this.datos_articulo_aux=[];    
      }else{
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
      }
    }

  eliminarRegistro(props) {
    this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
    this.getField('confirmModalCustom').setClickDialog(()=>this.confirmDelete(props.data));
    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  confirmDelete(props) {
    this.getField('confirmModalCustom').toggle(false);
    let datos = {
        datos: {
          codigo_articulo :props.codigo_articulo,
          existe_ubicaciones:this.existe_ubicaciones,
          numero_item:props.numero_item,
          origen :props.origen,
          select_direccion:this.getField('select_direccion').getValue()
        }
    };
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '7_item', operacion_tipo: "eliminar" };
    this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv
        });
  }
  
  successEliminar(data) {
      if(data.estado_p === 200){
        this.limpiar_numero=false;
        this.refescarTabla();          
      }else{
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
  }

  refescarTabla(){
    let datos={ datos: {
      sucursal_ingreso:this.sucursal_ingreso,
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'refrescar', operacion_tipo: 'consulta' };
    this.service.send({
        endpoint: this.constant.formConfiguration(),
        method:'GET',
        body: datos,
        success: this.sucessRefrescar,
        error: this.error_,
        general: this.generalFormatPmv
    });
  }

  calcularNeto(){
    //xneto = (xneto - xretefuente - xreteiva - xreteica - xcree) + xajustes;
    /* let xneto = (this.getField('total_pedido').getValue().replaceAll('.','').replaceAll(',','.')- this.getField('retenido').getValue() - this.getField('reteiva').getValue() - this.getField('reteica').getValue() - this.getField('cree').getValue()) +  this.getField('ajuste').getValue(); */

    let xneto = (this.getField('total_pedido').getValue());
    let decimal = (xneto + "").split(".")[1];//para manejar los decimales
    if ((decimal !== 0) && (decimal !== undefined)) {
      if(decimal.length>this.numero_decimales_compras)
      xneto=Number(xneto).toFixed(this.numero_decimales_compras);
    }
    /* this.getField('valor_neto').setValue(xneto); */
  }

  sucessRefrescar(data)
    {
        if (data.estado_p === 200)
        {
          this.gridOptionsComponentes['rowData'] = [];
          let configCell = new Map();
          this.gridOptionsComponentes['rowData'] = data.data.result;
          configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('subtotal2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal2) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.impuestos) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('orden_de_compra', {cellRenderer:(props) => {return props.data.orden_de_compra }, cellStyle: {textAlign:"right"},width: 110});

          if(this.existe_ubicaciones){
            //ocultar bodega,costo_adm,t_iva,t_subtotal,alias,otros_impuestos,t_otros_impuestos,numero_item
            configCell.set('bodega', {cellRenderer:(props) => {return ""+props.data.bodega } , hide: true});
            configCell.set('costo_adm', {cellRenderer:(props) => {return ""+props.data.costo_admin } , hide: true});
            configCell.set('t_iva', {cellRenderer:(props) => {return ""+props.data.t_iva } , hide: true});
            configCell.set('t_subtotal', {cellRenderer:(props) => {return ""+props.data.t_subtotal } , hide: true});
            configCell.set('alias', {cellRenderer:(props) => {return ""+props.data.alias } , hide: true});
            configCell.set('otros_impuestos', {cellRenderer:(props) => {return ""+props.data.otros_impuestos } , hide: true});
            configCell.set('t_otros_impuestos', {cellRenderer:(props) => {return ""+props.data.t_otros_impuestos } , hide: true});
            configCell.set('numero_item', {cellRenderer:(props) => {return ""+props.data.numero_item } , hide: true});

            //mostrar ubicacion,cant_orden,orden_de_compra,origen
            configCell.set('cant_orden', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cant_orden) }, cellStyle: {textAlign:"right"},width: 110});
            
          }else{
            //ocultar ubicacion,cant_orden,orden_de_compra,origen
            configCell.set('ubicacion', {cellRenderer:(props) => {return ""+props.data.ubicacion } , hide: true});
            configCell.set('cant_orden', {cellRenderer:(props) => {return ""+props.data.cant_orden } , hide: true});
            configCell.set('orden_de_compra', {cellRenderer:(props) => {return ""+props.data.orden_de_compra } , hide: true});
            configCell.set('origen', {cellRenderer:(props) => {return ""+props.data.origen } , hide: true});

            //mostrar bodega,costo_adm,t_iva,t_subtotal,alias,otros_impuestos,t_otros_impuestos,numero_item
            configCell.set('costo_adm', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_adm) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('t_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.t_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('t_subtotal', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.t_subtotal) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('otros_impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.otros_impuestos) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('t_otros_impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.t_otros_impuestos) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('numero_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.numero_item) }, cellStyle: {textAlign:"right"},width: 110});
          }
          if (this.maneja_wms_externo==='S' && this.existe_ubicaciones) {
            configCell.set('novedad', {cellRenderer:function(props){return props.data.novedad}, hide: false});
          }else{
            configCell.set('novedad', {cellRenderer:function(props){return props.data.novedad}, hide: true});
          }
          configCell.set('accion', { cellRenderer: this.setButtonBotonesTabla, width: 250, sortable: false, filter: false });

          this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
          this.getField('rejilla').toggle(true);
          //llenar totales
          this.getField('total_subtotal').setValue(data.data.totales.subtotal);
          this.getField('valor_iva').setValue(data.data.totales.valoriva);
          this.getField('impuestos').setValue(this.currencyFormatterGeneral(data.data.totales.impuestos));
          this.getField('total_pedido').setValue(data.data.totales.valortotal);


            this.getField('diferencia_total_subtotal').setValue(Number(this.getField('total_subtotal_xml').getValue()-this.getField('total_subtotal').getValue()).toFixed(2));
            this.getField('diferencia_valor_iva').setValue(Number(this.getField('valor_iva_xml').getValue()-this.getField('valor_iva').getValue()).toFixed(2));
            this.getField('diferencia_total_factura').setValue(Number(this.getField('total_pedido_xml').getValue()-this.getField('total_pedido').getValue()).toFixed(2));

          

          //antes de calcular neto actualizo los impuestos seleccionados
          this.limpiarCamposInferiores();
          this.limpiar_numero=true;
          this.calcularNeto();
          if(this.existe_ubicaciones){
            let cantidad=0;
            this.gridOptionsComponentes['rowData'].forEach((element)=>{
              cantidad+=element.cantidad;
            });
            if(cantidad!==0){
              this.getField('select_direccion').setDisabled(true);
            }else{
              this.getField('select_direccion').setDisabled(false);
            }
          }
        }
        else 
        {
          this.getField('rejilla').toggle(false);
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }

  }

  llenarSelectInfo(){
    let datos={ datos: {
      sucursal_ingreso: this.sucursal_ingreso
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cargarinfodocs', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.suceesLlenarSelectInfo,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
}

suceesLlenarSelectInfo(data)
{
  let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
  if (data.estado_p === 200) {

    opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
    data.data.condiciones.forEach(item => {
        let dataOp = {}
        dataOp['value'] = item.codigo;
        dataOp['text'] = item.valor;
        dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
        opciones.push(dataOp);
    });

    opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
    data.data.pagos.forEach(item => {
        let dataOp = {}
        dataOp['value'] = item.codigo;
        dataOp['text'] = item.valor;
        dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
        opciones.push(dataOp);
    });

    opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
    data.data.monedas.forEach(item => {
        let dataOp = {}
        dataOp['value'] = item.valor;
        dataOp['text'] = item.valor;
        dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
        opciones.push(dataOp);
    });

    opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
    data.data.actividades.forEach(item => {
        let dataOp = {}
        dataOp['value'] = item.codigo;
        dataOp['text'] = item.valor;
        dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
        opciones.push(dataOp);
    });
  }else if(data.estado_p === 404){
      
  }else {
    let respuesta=Object.values(data.data.errores);
    let keys=Object.keys(data.data.errores);
    this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
 }
 this.getField('select_moneda').setOptions(opciones);
 this.getField('select_actividad_economica').setOptions(opciones);
 this.getField('select_medio_pago').setOptions(opciones);
 this.getField('select_condicion_venta').setOptions(opciones);
}

  llenarSelect(){
      let datos={ datos: {
        sucursal_ingreso: this.sucursal_ingreso
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-legalizarrecepcion', operacion: 'cargarbodegas', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.suceesLlenarSelect,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }

  suceesLlenarSelect(data)
  {
    if (data.estado_p === 200 && data.data.result.length >= 1) {

      let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
      data.data.result.forEach(item => {
          let dataOp = {}
          dataOp['value'] = item.codigo;
          dataOp['text'] = item.cadena;
          dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
          opciones.push(dataOp);
      });
      this.getField('select_direccion').setOptions(opciones);
      if(data.data.data_defecto !== undefined){
          this.getField('select_direccion').setValue(data.data.data_defecto);
      }
    }else if(data.estado_p === 404){
        this.alertGeneral.toggle(true, 'No sé encontrarón direccion validas.', 'error');
        this.getField('plantillas').setVisible(false);
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
   }
   this.primeraVez();
  }


  currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    calcularDescuento(){
      this.getField('descuento').setError(false,'');
      if(this.getField('descuento').valid()){
          this.calculando_descuento=true;
          var xcosto = this.getField('precio_sin_iva').getValue();
          var xdescuentoc = this.getField('descuento').getValue();
          var xdescuento = xcosto * (xdescuentoc / 100);

          let decimales = Number.parseFloat(xcosto - xdescuento).toString().split(".")[1];
          if (decimales !== undefined && decimales.length > this.numero_decimales_compras) {
            this.getField('precio_sin_iva').setValue(Number.parseFloat(xcosto - xdescuento).toFixed(this.numero_decimales_compras));
          }else{
            this.getField('precio_sin_iva').setValue(Number.parseFloat(xcosto - xdescuento));
          }
          if(xdescuento>0)
            this.getField('descuento').setDisabled(true);
          if(this.existe_ubicaciones===false && this.existe_impuestos && this.existe_impuestos_articulo){
            this.traerImpuestosArticulo();
          }
          this.calculando_descuento=false;
      }
    }

    calcularItem(){
        let xtarifa_iva = this.getField('tarifa_iva').getValue();
        let ximpuestos1 = this.getField('impuestos2').getValue();
        let xcantidad1 = this.getField('cantidad_articulo').getValue();
        let xcosto1 =  this.getField('precio_sin_iva').getValue();
        let xcosto_mas_impuestos = xcosto1 + ximpuestos1;
        let xvalor_iva = xcosto1 * (xtarifa_iva / 100);

        
        this.getField('valor_sin_iva_ma_impuestos').setValue(Number.parseFloat(xcosto_mas_impuestos).toFixed(this.numero_decimales_compras));
        this.getField('valor_iva_unidad').setValue(Number.parseFloat(xvalor_iva).toFixed(this.numero_decimales_compras));
        this.getField('valor_unidad').setValue(Number.parseFloat(xvalor_iva + xcosto_mas_impuestos).toFixed(this.numero_decimales_compras));

        //total iva
        let xtotal_iva = xvalor_iva * xcantidad1;
        this.getField('total_iva').setValue(Number.parseFloat(xtotal_iva).toFixed(this.numero_decimales_compras));

        //datos otros impuestos y total
        let xtotalOtrosImpuestos = this.getField('xt_otros_impuestos').getValue();


        //total_impuestos
        let xtotal_impuestos=0;
        if(this.existeUbicacion===false && this.existe_impuestos && this.existe_impuestos_articulo){
            xtotal_impuestos = (ximpuestos1 * xcantidad1) + xtotalOtrosImpuestos;
            this.getField('total_impuestos').setValue(Number.parseFloat(xtotal_impuestos).toFixed(this.numero_decimales_compras));
        }else{
            xtotal_impuestos = ximpuestos1 * xcantidad1;
            this.getField('total_impuestos').setValue(Number.parseFloat(xtotal_impuestos).toFixed(this.numero_decimales_compras));
        }
        

        //total_item
        let xtotal_item = xtotal_impuestos + xtotal_iva;
        xtotal_item = xtotal_item + (xcosto1 * xcantidad1);
        this.getField('total_item').setValue(Number.parseFloat(xtotal_item).toFixed(this.numero_decimales_compras));

        //total item sin impuestos
        let xtotal_item_sin_iva = (xcosto1 * xcantidad1) + xtotal_impuestos;
        this.getField('total_item_sin_iva').setValue(Number.parseFloat(xtotal_item_sin_iva).toFixed(this.numero_decimales_compras));
        this.calcularPrecioSugerido();
    }

    traerPropiedades(){
      let datos={ datos: {
        sucursal_ingreso:this.sucursal_ingreso
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessTraerPropiedades,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
    }

    sucessTraerPropiedades(data){
      if (data.estado_p === 200 )
      {
          this.existe_ubicaciones=data.data.ubicaciones;

          this.tarifa_reteiva=data.data.reteiva;
          this.maneja_wms_externo=data.data.maneja_wms_externo;
          this.docSoporteCri=data.data.docSoporteCri;
          this.facturacion_electronica_propia=data.data.facturacion_electronica_propia;
          this.requiere_orden_compra=data.data.requiere_orden_compra;
          this.precio_incluye_iva=data.data.precio_incluye_iva;
          this.wms_nuevo=data.data.wms_nuevo;
          this.lasucursal=data.data.lasucursal;
          this.xsucursal=data.data.lasucursal;
          this.moneda_empresa=data.data.moneda_empresa;
          this.existe_impuestos=data.data.existe_impuestos;
          this.existe_impuestos_articulo=data.data.existe_impuestos_articulo;
          this.cons_impart=data.data.cons_impart;
          this.cargue_compras=data.data.cargue_compras;
          this.smartvv=data.data.smartvv;
          this.compras_wms=data.data.compras_wms;
          this.costo_administrativo=data.data.costo_administrativo;
          this.costo_por_bodega=data.data.costo_por_bodega;
          this.asigna_precio=data.data.asigna_precio;
          this.bodega_mandato=data.data.bodega_mandato;
          this.numero_decimales_compras=data.data.numero_decimales_compras;
          this.exigir_orden=data.data.exigir_orden;
          this.maneja_bodega_ubicacion=data.data.maneja_bodega_ubicacion;
          this.calcular_cree_automatico=data.data.calcular_cree_automatico;
          this.rsSinIva=data.data.rs_sin_iva;
          this.prioridad_barras=data.data.prioridad_barras;
          //quitar
         
          if(this.existe_impuestos_articulo && this.existe_impuestos)
            this.getField('bt_impuestos_articulo').setDisabled(false);
          else
            this.getField('bt_impuestos_articulo').setDisabled(true);

          
          if(this.existe_ubicaciones){
            //this.getField('detalle').setDisabled(true);
            this.getField('bt_impuestos_articulo').setVisible(false);
            /*this.getField('label12').setVisible(true);
             this.getField('bt_retefuente').setVisible(false); */
            this.getField('fecha_recibido').setVisible(false);
            this.getField('base_retencion').setVisible(false);
            this.getField('bt_impuestos').setVisible(false);
            this.getField('bt_novedades').setVisible(true);
            this.getField('cambiar_ubicacion').setVisible(true);
            //this.getField('modificar_cabys').setVisible(true);
            /* this.getField('bt_auditoria').setVisible(true); 
            this.getField('bt_datos_cargue').setVisible(false);*/
            this.getField('precio').setDisabled(true);
            this.getField('rentabilidad').setDisabled(true);
            this.getField('bt_actualizar_precio').setVisible(false);
            this.getField('bt_actualizar_precio').setDisabled(true);

            if (this.maneja_wms_externo==='S' && this.orden_wms && this.getField('no_orden').getValue()!=='0' && this.getField('no_orden').getValue()!=='') {
              this.getField('factura_numero').setDisabled(true);
            } else {
              this.getField('factura_numero').setDisabled(false);
            }
          }else{
            this.getField('bt_actualizar_precio').setVisible(true);
            this.getField('bt_actualizar_precio').setDisabled(false);
            this.getField('bt_impuestos_articulo').setVisible(true);
            this.getField('bt_novedades').setVisible(false);
            this.getField('cambiar_ubicacion').setVisible(false);
            this.getField('modificar_cabys').setVisible(false);
            /*this.getField('label12').setVisible(false);
             this.getField('bt_retefuente').setVisible(true); */
            this.getField('fecha_recibido').setVisible(true);
            this.getField('base_retencion').setVisible(true);
            this.getField('bt_impuestos').setVisible(true);

            /* this.getField('bt_auditoria').setVisible(false); 
            this.getField('bt_datos_cargue').setVisible(true);
            if(this.cargue_compras)
              this.getField('bt_datos_cargue').setDisabled(false);
            else
              this.getField('bt_datos_cargue').setDisabled(true);
            */
          }
          this.getField('con_codigo_proveedor').setValue('N');
          if(data.data.pedido_con_cod_proveedor==='S' && this.existe_ubicaciones===false){
            this.getField('confirmModalCustom').setTitleAndContent('Trabaja con codigo proveedor?',);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.getField('con_codigo_proveedor').setValue('S'); });
            this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("Si"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
          }
          //establecer setOnblur
          if(this.existe_ubicaciones===false && this.existe_impuestos && this.existe_impuestos_articulo){
              this.getField("cantidad_articulo").setOnBlur(this.traerImpuestosArticulo);
              this.getField('precio_sin_iva').setOnBlur(this.traerImpuestosArticulo);
          }
      }
      else 
      { 
        this.autoriza_ordenesc='';
        this.margen_costo_entrada='';
      }
      this.llenarSelect();
  }
  
  traerPropiedades2(){
    let datos={ datos: {
      sucursal_ingreso:this.sucursal_ingreso
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'prcdiferencia', operacion_tipo: 'consulta' };
    this.service.send({
        endpoint: this.constant.formConfiguration(),
        method:'GET',
        body: datos,
        success: this.sucessTraerPropiedades2,
        error: this.error_,
        general: this.generalFormatPmv,
        showMessage:false
    });
  }
  
  sucessTraerPropiedades2(data){
    if (data.estado_p === 200 )
    {
      this.porcentaje_diferencia=data.data.porcentaje_diferencia;
    }
    else 
    { 
      this.alertGeneral.toggle(true, 'Error trayendo porcentaje de diferencia', 'error');
    }
    this.llenarSelect();
}

    traerCostoBodega(){
      let datos={ datos: {
        value:this.getField('select_direccion').getValue(),
        value2:this.getField('codigo_articulo').getValue()
      }};
      this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '1_bodega_costo', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessTraerCostoBodega,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
    }

    sucessTraerCostoBodega(data){
      if (data.estado_p === 200 )
      {
          //this.getField('precio_sin_iva').setValue(data.data[0].costo);
      }
      else 
      { 
          this.getField('precio_sin_iva').setValue(0);
          if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          }
          else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          }
      }
  }

  traerCostoBodegaYAdmn(){
    let op='';
    if(this.costo_por_bodega==='S')
        op='1_costo_adiministractivo';
    else if(this.calcula_iva_item==='S')
        op='1_costo_calcula_iva';
    else
      op='1_costo_normal';
    let datos={ datos: {
      value:this.costo_administrativo==='S'?'a.costo_adm_'+this.getField('select_direccion').getValue():'a.costo'+this.getField('select_direccion').getValue(),
      value2:this.getField('codigo_articulo').getValue()
    }};
    this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: op, operacion_tipo: 'consulta' };
    this.service.send({
        endpoint: this.constant.formConfiguration(),
        method:'GET',
        body: datos,
        success: this.sucessTraerCostoBodegaYAdmn,
        error: this.error_,
        general: this.generalFormatPmv,
        showMessage:false
    });
  }

  sucessTraerCostoBodegaYAdmn(data){
    if (data.estado_p === 200 )
    {
        //this.getField('precio_sin_iva').setValue(data.data.costo_menos_impuesto);
        if(this.costo_administrativo==='S')
          this.getField('costo_administrativo').setValue(data.data.costo_menos_impuesto);
    }
    else 
    { 
        this.getField('precio_sin_iva').setValue(0);
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
  }

  validarCodigoAlterno(){
      //validar si es alterno
      let datos={ datos: {
        value:this.getField('codigo_articulo').getValue()
      }};
      this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '1_alterno', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessAlterno,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
  }

  sucessAlterno(data){
    if (data.estado_p === 200 )
    {
        if(data.data[0].total>0){
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'El artículo '+this.getField('codigo_articulo').getValue()+'\n '+this.getField('nombre_articulo').getValue()+' \nes alterno. ');
          this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false)});  
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);

          this.getField('codigo_articulo').setValue('');
          this.getField('nombre_articulo').setValue('');
          this.getField('tarifa_iva').setValue('');
          this.getField('descuento').setValue('');
          this.getField('precio_sin_iva').setValue(0);
          this.getField('costo').setValue('');
        }else{
          //sino es alterno no limpia entonces pasa a traer los demás datos.
          if(this.existe_ubicaciones===true){//
              this.saldosubicacion();
              if(this.costo_por_bodega==='S' && ((this.modificando==='true' && this.count>1) || this.modificando==='false'))
                this.traerCostoBodega();
          }else{ 
            if((this.modificando==='true' && this.count>1) || this.modificando==='false')
              this.traerCostoBodegaYAdmn();
          }
          this.traersaldoBodegas();
        }
    }
    else 
    { 
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
  }



  consultarXml(){
    this.getField('modal_documentos_recibidos').handleClickOpen();
    this.getField("consultar").setDisabled(false);
  }

  consultarDocumentos(){
/*     this.getField('nit').setValue(this.getField('nit2').getValue())
    this.getField('sede').setValue(this.getField('sede2').getValue())
    this.getField('nombre2').setValue(this.getField('nombre2').getValue()) */
    
    if(this.validarfechasDocReci()){
      let seguir = true;
      let datos={ 
          datos: {
              desde: this.getField('desde').getValue(), 
              hasta: this.getField('hasta').getValue(),
              nit:'.',
              numero:'.',
              pedido:'.'
          }
      };

      if(this.getField('nit').getValue() !== ''){
          if(this.getField('nit').valid() & this.getField('nombre2').valid()){
              datos.datos['nit'] = this.getField('nit').getValue();
          }else seguir = false
          
      }else{

      }

      if(this.getField('numero').getValue() !== ''){
          if(this.getField('numero').valid()){
              datos.datos['numero'] = this.getField('numero').getValue();
          }else seguir = false

      }


      if(this.getField('pedido').getValue() !== ''){
          if(this.getField('pedido').valid()){
              datos.datos['pedido'] = this.getField('pedido').getValue();
          }else seguir = false

      }


      if(seguir){
          this.generalFormatPmv = { tipo_servicio:  'cont-consultadocumentos', operacion: '1', operacion_tipo: 'consulta' };
          this.service.send(
          {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successConsulta,
              error: this.error_,
              general: this.generalFormatPmv
          });
      }else {

      }
    }
  }

  validarfechasDocReci(){

    let fecha_actual = new Date();
    let fecha_desde = new Date(this.getField("desde").getValue());
    let fecha_hasta = new Date(this.getField("hasta").getValue());

    this.getField("desde").setError(false,"");
    this.getField("hasta").setError(false,"");

    if(fecha_desde.getTime() > fecha_actual.getTime()){
        this.getField("desde").setError(true,"No puede ser mayor a la fecha actual.");
        return false;
    }

    if(fecha_hasta.getTime() > fecha_actual.getTime()){
        this.getField("hasta").setError(true,"No puede ser mayor a la fecha actual.");
        return false;
    }

    if(fecha_desde.getTime() > fecha_hasta.getTime()){
        this.getField("hasta").setError(true,"No puede ser menor a la fecha desde.");
        return false;
    }
    return true;
  
  }

  successConsulta(data){
    
    if(data.estado_p === 200){
      this.getField("consultar").setDisabled(true);
      let configCell = new Map();
      let createElement = this.createElementJaivana;
      this.gridOptionsDoc['rowData'] = data.data;
      this.gridOptionsDoc['onSelectionChanged'] =this.onSelectionChangedRowTable2;
      configCell.set('estado_acuse_dian', { cellRenderer: function (props) { 
          if (props.data.estado_acuse_dian === 'a') {
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_acuse_dian);
              div.setAttribute("class","divStyle3");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_acuse_dian === 'e'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_acuse_dian);
              div.setAttribute("class","divStyle4");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_acuse_dian === 'p'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_acuse_dian);
              div.setAttribute("class","divStyle1");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_acuse_dian === '.'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_acuse_dian);
              div.setAttribute("class","divStyle2");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else{
              return props.data.estado_acuse_dian
          }
      }, field: 'estado_acuse_dian' });

      configCell.set('estado_recibo_dian', { cellRenderer: function (props) { 
          if (props.data.estado_recibo_dian === 'a') {
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_recibo_dian);
              div.setAttribute("class","divStyle3");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_recibo_dian === 'e'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_recibo_dian);
              div.setAttribute("class","divStyle4");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_recibo_dian === 'p'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_recibo_dian);
              div.setAttribute("class","divStyle1");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_recibo_dian === '.'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_recibo_dian);
              div.setAttribute("class","divStyle2");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else{
              return props.data.estado_recibo_dian
          }
      }, field: 'estado_recibo_dian' });

      configCell.set('estado_aceptacion_dian', { cellRenderer: function (props) { 
          if (props.data.estado_aceptacion_dian === 'a') {
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_aceptacion_dian);
              div.setAttribute("class","divStyle3");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_aceptacion_dian === 'e'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_aceptacion_dian);
              div.setAttribute("class","divStyle4");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_aceptacion_dian === 'p'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_aceptacion_dian);
              div.setAttribute("class","divStyle1");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_aceptacion_dian === '.'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_aceptacion_dian);
              div.setAttribute("class","divStyle2");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else{
              return props.data.estado_aceptacion_dian
          }
      }, field: 'estado_aceptacion_dian' });

      configCell.set('estado_reclamo_dian', { cellRenderer: function (props) { 
          if (props.data.estado_reclamo_dian === 'a') {
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_reclamo_dian);
              div.setAttribute("class","divStyle3");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_reclamo_dian === 'e'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_reclamo_dian);
              div.setAttribute("class","divStyle4");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_reclamo_dian === 'p'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_reclamo_dian);
              div.setAttribute("class","divStyle1");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_reclamo_dian === '.'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_reclamo_dian);
              div.setAttribute("class","divStyle2");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else{
              return props.data.estado_reclamo_dian
          }
      }, field: 'estado_reclamo_dian' });

      configCell.set('estado_contabilizacion', { cellRenderer: function (props) { 
          if (props.data.estado_contabilizacion === 'CONTABILIZADO') {
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_contabilizacion);
              div.setAttribute("class","divStyle3");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else if(props.data.estado_contabilizacion === 'NO CONTABILIZADO'){
              let div = document.createElement("input");
              div.setAttribute("id", `div_${props.data.id}`);
              div.setAttribute("value",props.data.estado_contabilizacion);
              div.setAttribute("class","divStyle4");
              div.setAttribute("type","div");
              div.setAttribute("disabled", true);
              return createElement(div);
          }else{
              return props.data.estado_contabilizacion
          }
      }, field: 'estado_contabilizacion' });

      this.getField('rejilla_doc_reci').initData(this.gridOptionsDoc, configCell);
    }else{
      this.getField("consultar").setDisabled(false);
      this.getField('rejilla_doc_reci').toggle(false);
    }
  }

  onSelectionChangedRowTable2 = () =>{    
    this.selectedRows = [];
    this.datosLista = [];
    this.selectedRows = this.gridOptionsDoc.api.getSelectedRows();
    
    this.gridOptionsDocumentosRecibidosXml['rowData'] = [];
    this.getField('tb_documentosrecibidosxml').initData(this.gridOptionsDocumentosRecibidosXml);
    this.getField('tb_documentosrecibidosxml').toggle(false);
    if (this.selectedRows.length >= 1) {
        this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.datosAgregar.datos['numero_documento']=this.selectedRows[0].numero_documento.toString();
        this.datosAgregar.datos['nit_documento']=this.selectedRows[0].nit_documento.toString();
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadocumentos', operacion: '1_4', operacion_tipo: 'consulta'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: this.datosAgregar,
                success: this.successDocumentosRecibidosXml,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }
        );
    }

  }


  successDocumentosRecibidosXml(data){
    if(data.estado_p === 200){
      this.basetotaldistribucion =data.data[0].tarifa===0 || data.data[0].tarifa===0.0 || data.data[0].tarifa===0.00 ? Number(data.data[0].baseg + data.data[0].basee).toFixed(2)  : Number((data.data[0].valor)/(data.data[0].tarifa/100)).toFixed(2);        
      this.totaldistribucion = data.data[0].total;
      this.manejaDecimales();

      let dataGraficar = [];
      let objectGraficar = {};
      let asignacion = 0;
      let basee = 0;
      let baseg = 0;
      let cargo = 0;
      let total = 0;


      if (data.data.length >= 1){
        this.arrayTemp = [];
        this.valorPlantillas = '';
        if (data.data[0].fecha_vencimiento !== null && data.data[0].fecha_vencimiento !== '') {
            this.venceDocumento = data.data[0].fecha_vencimiento;            
        }

        for (let i = 0; i < data.data.length; i++) {
          // Vamos a hallar el valor del total y a reemplazar los datos null que se listan en la tabla (parte 1):
          if (asignacion === 0 || asignacion === '' || asignacion === null) {
              if (data.data[i].asignacion !== null && data.data[i].asignacion !== '') {
                  asignacion = data.data[i].asignacion;
              }
          }
          
          if (basee === 0 || basee === '' || basee === null) {
              if (data.data[i].basee !== null && data.data[i].basee !== '') {
                  basee = data.data[i].basee;
              }
          }
          
          if (baseg === 0 || baseg === '' || baseg === null) {
              if (data.data[i].baseg !== null && data.data[i].baseg !== '') {
                  baseg = data.data[i].baseg;
              }
          }
          
          if (cargo === 0 || cargo === '' || cargo === null) {
              if (data.data[i].cargo !== null && data.data[i].cargo !== '') {
                  cargo = data.data[i].cargo;
              }
          }
          
          if (total === 0 || total === '' || total === null) {
              if (data.data[i].total !== null && data.data[i].total !== '') {
                  total = data.data[i].total;
              }
          }
          objectGraficar = {
              asignacion: asignacion,
              basee: basee,
              baseg: data.data[i].tarifa===0 || data.data[i].tarifa===0.0 || data.data[i].tarifa===0.00 ? data.data[i].baseg.toFixed(this.numeroDecimales) : ((data.data[i].valor)/(data.data[i].tarifa/100)).toFixed(this.numeroDecimales),
              cargo: cargo,
              tarifa: data.data[i].tarifa,
              total: total,
              valor: data.data[i].valor,
              orden_compra: data.data[i].orden_compra
          };

          dataGraficar.push(objectGraficar);

          let encabezadoBaseeTarifa = `basee${data.data[i].tarifa}`;
          let encabezadoBasegTarifa = `baseg${data.data[i].tarifa}`;
          let encabezadoIvaTarifa = `iva${data.data[i].tarifa}`;
          let encabezadoIvaValor = `valor${data.data[i].tarifa}`;

          let dataOp = {};
          dataOp[encabezadoBaseeTarifa] = basee;
          dataOp[encabezadoBasegTarifa] =  data.data[i].tarifa===0 || data.data[i].tarifa===0.0 || data.data[i].tarifa===0.00 ? data.data[i].baseg : (data.data[i].valor)/(data.data[i].tarifa/100);
          dataOp[encabezadoIvaTarifa] = data.data[i].valor;
          dataOp[encabezadoIvaValor] = data.data[i].valor;

          dataOp["asignacion"] = asignacion;
          dataOp["cargo"] = cargo;
          dataOp["total"] = total;
          this.arrayTemp.push(dataOp);
        
        
        }

        this.numero_documento_xml=this.selectedRows[0].numero_documento.toString();
        this.fecha_vence_xml=this.selectedRows[0].vence_documento;


        
        let data2 = {
            "nit": this.selectedRows[0].nit_documento,
            "sede": this.selectedRows[0].sede,
            "nombre": this.selectedRows[0].nombre,
            "numero_del_documento": this.selectedRows[0].numero_documento,
            "fecha": this.selectedRows[0].fecha_documento,
            "vence": this.selectedRows[0].vence_documento,
            "total": "".formatoPrecio(total)
        };
        this.datosLista.push(data2);
        this.getField('listadocumentos').setItemsFichas(this.datosLista);
        //this.getField('numero_documento').setValue(this.selectedRows[0].numero_documento);
        this.documentos_recibidos_id=this.selectedRows[0].id;        
        //this.getField('modal_contabilizar').handleClickOpen();
        this.getField('tb_documentosrecibidosxml').toggle(true);
        this.gridOptionsDocumentosRecibidosXml['rowData'] = dataGraficar;
        this.gridOptionsDocumentosRecibidosXml['onSelectionChanged'] = this.onSelectionChangedRowTable3;
        let configCell = new Map();
        configCell.set('baseg', { cellRenderer: (props) => { return "".formatoPrecio(props.data.baseg) } });// return "".formatoPrecio, se usa para darle formato númerico a los valores de la columna especificada
        configCell.set('basee', { cellRenderer: (props) => { return "".formatoPrecio(props.data.basee) } });// return "".formatoPrecio, se usa para darle formato númerico a los valores de la columna especificada
        configCell.set('total', { cellRenderer: (props) => { return "".formatoPrecio(props.data.total) } });// return "".formatoPrecio, se usa para darle formato númerico a los valores de la columna especificada
        configCell.set('valor', { cellRenderer: (props) => { return "".formatoPrecio(props.data.valor) } });// return "".formatoPrecio, se usa para darle formato númerico a los valores de la columna especificada
        configCell.set('asignacion', {headerName: 'Asignación'});
        this.getField('tb_documentosrecibidosxml').initData(this.gridOptionsDocumentosRecibidosXml, configCell);

      }else{
        this.alertGeneral.toggle(true, 'No hay registros para este número documento', 'error');
        this.gridOptionsDocumentosRecibidosXml['rowData'] = [];
        this.getField('tb_documentosrecibidosxml').initData(this.gridOptionsDocumentosRecibidosXml);
        this.getField('tb_documentosrecibidosxml').toggle(false);
      }



      
      let data2 = {
        "nit": this.selectedRows[0].nit_documento,
        "sede": this.selectedRows[0].sede,
        "nombre": this.selectedRows[0].nombre,
        "numero_del_documento": this.selectedRows[0].numero_documento,
        "fecha": this.selectedRows[0].fecha_documento,
        "vence": this.selectedRows[0].vence_documento,
        "total": 1//"".formatoPrecio(total)
    };

    this.datosLista.push(data2);
    this.getField('listadocumentos').setItemsFichas(this.datosLista);
    //this.getField('numero_documento').setValue(this.selectedRows[0].numero_documento);
    this.documentos_recibidos_id=this.selectedRows[0].id;

    this.getField('modal_documentos_recibidos_2').handleClickOpen();

    }else{
      this.alertGeneral.toggle(true, 'Error al traer el Xml.', 'error');
    }
  }

  manejaDecimales(){
    this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
    this.generalFormatPmv = {tipo_servicio: 'cont-consultadocumentos', operacion: 'manejodecimales', operacion_tipo: 'consulta'};
    this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: this.datosAgregar,
            success: this.successManejaDecimales,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        }
    );
  }

  successManejaDecimales(data){
      if(data.estado_p === 200){
          this.numeroDecimales = Number(data.data.numero_decimales);
      }else{
          this.alertGeneral.toggle(true, 'No hay decimales configurados para calcular los totales', 'error');
      }
  }

  onSelectionChangedRowTable3 = () =>{    
    this.selectedRowsCompra = [];    
    this.selectedRowsCompra = this.gridOptionsDocumentosRecibidosXml.api.getSelectedRows();

    if (this.selectedRowsCompra.length >= 1 && this.selectedRowsCompra[0].orden_compra.toString() !== null) {
      this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
      this.datosAgregar.datos['no_orden']=this.selectedRowsCompra[0].orden_compra.toString();
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'traerordencompra', operacion_tipo: 'consulta'};
      this.service.send(
          {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: this.datosAgregar,
              success: this.successOrdenCompra,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
          }
      );
    }
  }

  successOrdenCompra(data){
    if(data.estado_p === 200){        
    
      this.getField('tb_orden_compra').toggle(true);
      this.gridOptionsOrdenCompras['rowData'] = data.data;
      this.getField('tb_orden_compra').initData(this.gridOptionsOrdenCompras);
      this.getField('aceptar_orden').setVisible(true);
      
    }else{
      this.alertGeneral.toggle(true, 'Error al traer la orden de compras', 'error');
    }
  }

  definirOrdenXml(){
    if(this.row_orden_xml.length>0){
      this.numero_orden_compra = this.row_orden_xml[0].numero;
      
      this.total_sin_impuestos_xml = this.selectedRowsCompra[0].baseg;
      this.valor_iva_xml = this.selectedRowsCompra[0].valor;
      this.total_xml = this.selectedRowsCompra[0].total;

      this.getField('numero_factura').setDisabled(false);
      
      this.getField('no_orden').setValue(this.numero_orden_compra);
      this.getField('factura_numero').setValue(this.numero_documento_xml);
      this.getField('fecha_vencimiento_xml').setValue(this.fecha_vence_xml);

      this.getField('total_subtotal_xml').setValue(this.total_sin_impuestos_xml);
      this.getField('valor_iva_xml').setValue(this.valor_iva_xml);
      this.getField('total_pedido_xml').setValue(this.total_xml);

      this.getField('detalle').setValue(this.row_orden_xml[0].observaciones.trim());
      this.getField('detalle').setError(false,'');
      this.getField('nit').setValue(this.getField('nit2').getValue());
      this.getField('sede').setValue(this.getField('sede2').getValue());
      this.getField('nombre').setValue(this.getField('nombre2').getValue());

      if(this.existe_ubicaciones===true){
        this.cargarOrdenXml(false,false);
      }else{
        if(this.smartvv==='S' && this.compras_wms==='S'){
          this.contarProductosOrdenWms();
        }else{
          this.getField('modal_pregunta').handleClickOpen();
        }
      }
    }else{
      this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    }
  }

  cargarOrdenXml(cantidad_escaneada,cantidad_cero){
    this.mostrarMensajeGenerando();
    this.orden_wms=false;
    let datos={ datos: {
      sucursal_ingreso:this.sucursal_ingreso,
      cantidad_escaneada:cantidad_escaneada+'',
      factor:this.getField('factor').getValue(),
      nit:this.getField('nit2').getValue(),
      sede:this.getField('sede2').getValue(),
      no_orden:this.numero_orden_compra.toString(),
      xfecha:this.row_orden_xml[0].fecha,
      cantidad_cero:''+cantidad_cero,
      existe_ubicaciones:this.existe_ubicaciones,
      xsucursal:this.xsucursal,
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cargarorden', operacion_tipo: 'crear' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.sucessCargarOrden,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

}
FormJaivana.addController("comp-grabarcompras", CustomGrabacionDeCompras);
export default CustomGrabacionDeCompras;