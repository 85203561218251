import FormJaivana from 'dashboard_jaivana_v1';


/**
 * @author Patricia Lopez Sanchez
 * @description Custom Administracion Conteo Pedidos Revisores - 1346
 * @version jdesk_1.01.0004.1
 * @class Administracion Conteo Pedidos Revisores
 * @extends {FormJaivana}
 */

class CustomPedidosRevisores extends FormJaivana.form {


    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);
        this.allUser                            = [];

        this.traerListadoConteo = this.traerListadoConteo.bind(this);
        this.successtraerListadoConteo = this.successtraerListadoConteo.bind(this);
        this.gridOptionsListadoConteo = Object.assign({},this.gridOptions)
        this.gridOptionsListadoVerMas = Object.assign({},this.gridOptions)

        this.traerListadoVerMas = this.traerListadoVerMas.bind(this);
        this.successtraerListadoVerMas = this.successtraerListadoVerMas.bind(this);

        
        this.arrayCopy =[];
        this.numero_pedido = 0;

        this.setButtonTraerItems            = this.setButtonTraerItems.bind(this);
    
        this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
        this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);

        this.setSelectUsuario   = this.setSelectUsuario.bind(this);
        this.getUsuarios        = this.getUsuarios.bind(this);
        this.traerUsuariosEncargados    = this.traerUsuariosEncargados.bind(this);
        this.successUsuariosEncargados  = this.successUsuariosEncargados.bind(this);
        this.valueChangeUsuario         = this.valueChangeUsuario.bind(this);
        this.successAgregararUsuarioEncargado   = this.successAgregararUsuarioEncargado.bind(this);
    }
    /**
     * Método que se ejecuta al inicio de renderizar el componente. Se encarga de configurar por defecto 
     * la vista principal
     */
    
    initForm() {
        console.log('Administracion Conteo Pedidos Revisores @version: jdesk_1.01.0004.1, @author: Patricia Lopez Sanchez');

        this.getField('btn_refrescar').setClick(() => {
            this.traerUsuariosEncargados();
            this.traerListadoConteo();
        });
        this.traerUsuariosEncargados();
        this.traerListadoConteo();
    }

    traerUsuariosEncargados(){
        let datos = { datos: {}};

        this.generalFormatPmv = { tipo_servicio: 'most-pedidosrevisores', operacion: 'todos', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successUsuariosEncargados,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }

    successUsuariosEncargados(data){
        if(data.estado_p === 200){
            this.allUser = data.data;
        }
    }

    traerListadoConteo(){
            //1347-servicio_traer_listado_conteo'
            let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'most-pedidosrevisores', operacion: 'listadoconteo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successtraerListadoConteo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    successtraerListadoConteo(data){
        if(data.estado_p === 200 && data.data.length > 0){
            let configCell = new Map();
            
            let arrayCopyConteo = [...data.data];

            this.gridOptionsListadoConteo['rowData'] = arrayCopyConteo;

            configCell.set('numero_pedido', {headerName: 'Número Pedido'});   
            configCell.set("operario",{ cellRenderer: this.setSelectUsuario, cellStyle: { backgroundColor: "rgb(238,238,238)",borderRadius:"5px",color:'rgb(0, 86, 201)' }, field: 'Operario' });  
            configCell.set('nombre', {headerName: 'Nombre Cliente'});     
            configCell.set('tipo_pedido', {headerName: 'Tipo Pedido'});      
            configCell.set('ver_mas', {cellRenderer: this.setButtonTraerItems, headerName: 'Ver más', sortable: false, filter: false });

            this.getField('rejilla').initData(this.gridOptionsListadoConteo,configCell);

        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true,'No hay datos',"error");
            this.getField('rejilla').toggle(false);
        } else {
            this.gridOptionsListadoConteo['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsListadoConteo);
            this.getField('rejilla').toggle(false);
        }
    }

    setSelectUsuario(props){
        let maquina = props.data.operario;
        let select = document.createElement("select");
        let div = document.createElement('div');
        div.setAttribute('class', 'custom-select');
        select.setAttribute('class', 'custom-select');
        select.onchange = (event) => this.valueChangeUsuario(event, props.data);
        select.setAttribute('id', 'mySelectTipoPedido_');

        let opciones = this.getUsuarios();
        this.valor_idRec = "";
        opciones.map((item) => {
            select.appendChild(item);
            if (item.value === maquina)
                this.valor_idRec = item.value;
            return null;
        })
        div.appendChild(select)
        if(maquina !== null && maquina !== undefined)
            select.value = this.valor_idRec;
        return this.createElementJaivana(div);
    }

    getUsuarios(){
        let opcionN = document.createElement('option');
        opcionN.setAttribute('value',' ');
        opcionN.textContent = 'Ninguno';

        let datos = [opcionN];
        this.allUser.map((item) =>{
            let opcion = document.createElement('option');
            opcion.setAttribute('value',item.codigo);
            opcion.textContent = item.codigo+"-"+item.nombre;
            datos.push(opcion);
            return null;
           
        })
        return datos;
    }

    valueChangeUsuario(e,data){

        let valor = e.target.value;
        let id_reg = data.numero_pedido;

        // proc-procesosactividadesencargadosconfiguracion
        let datos = { datos: {
            numero_pedido: id_reg,
            codigo: valor
        }};

        this.generalFormatPmv = { tipo_servicio: 'most-adminoperarios', operacion: 'cambiarusuario', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successAgregararUsuarioEncargado,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successAgregararUsuarioEncargado(data){
        if(data.estado_p === 200){
            this.getField('rejilla').toggle(false);
            //this.traerListadoAdminOperarios();
            this.traerListadoConteo();
        }
    }

    traerListadoVerMas(numero_pedido){
            //'1347-servicio_ver_mas_conteo'
            let datos={ datos: {numero_pedido: parseInt(numero_pedido)}};
            this.generalFormatPmv = { tipo_servicio: 'most-pedidosrevisores', operacion: 'vermas', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successtraerListadoVerMas,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    successtraerListadoVerMas(data){

        if(data.estado_p === 200 && data.data.length > 0){
            this.getField("modal_ver_mas_2").handleClickOpen();
            let configCell = new Map();
            this.arrayCopy = [...data.data];

            let createElementJaivana   = this.createElementJaivana;
            configCell.set('estado', { cellRenderer: function (props) { 
                if (props.data.estado === 'POR TERMINAR') {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.estado}`);
                    div.setAttribute("value","POR TERMINAR");
                    div.setAttribute("class","divStyle1");
                    div.setAttribute("type","div");
                    //fragment.appendChild(div);
                    return createElementJaivana(div);     
                }else if (props.data.estado === 'POR REVISAR'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.estado}`);
                    div.setAttribute("value","POR REVISAR");
                    div.setAttribute("class","divStyle4");
                    div.setAttribute("type","div");
                    //fragment.appendChild(div);
                    return createElementJaivana(div);     
                }else{
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.estado}`);
                    div.setAttribute("value","FINALIZADO");
                    div.setAttribute("class","divStyle3");
                    div.setAttribute("type","div");
                    //fragment.appendChild(div);
                    return createElementJaivana(div);    
                }
            }, field: 'estado' });

            configCell.set('ubicacion', {headerName: 'Ubicación'});
            configCell.set('cantidad_pedida', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_pedida) }, cellStyle: {textAlign:"right"},headerName: 'Cantidad Pedida'});
            configCell.set('contado', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.contado) }, cellStyle: {textAlign:"right"},headerName: 'Cantidad Contada'});
            configCell.set('revisado', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.revisado) }, cellStyle: {textAlign:"right"},headerName: 'Cantidad Revisada'});


            this.gridOptionsListadoVerMas['rowData'] = this.arrayCopy;
            this.getField('rejilla_ver_mas').initData(this.gridOptionsListadoVerMas, configCell);


        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true,'No hay datos',"error");
            this.getField('rejilla_ver_mas').toggle(true);
        } else {
            this.gridOptionsListadoVerMas['rowData'] = [];
            this.getField('rejilla_ver_mas').initData(this.gridOptionsListadoVerMas);
        }

    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    setButtonTraerItems(props){
        let id = props.data.id;        
        let button = document.createElement('input'); 
        button.setAttribute("class","buttonStyle7");
        button.onclick = ()=> this.traerListadoVerMas(props.data.numero_pedido);
        button.setAttribute("id",'button_conteo_'+id);
        button.setAttribute("value",'Ver conteo');
        button.setAttribute("type","button");
        let fragment = document.createDocumentFragment();
        fragment.appendChild(button);
        
        /* return this.createElementJaivana(fragment); */
        return this.createElementJaivana(fragment);
        
    }

}
FormJaivana.addController("most-pedidosrevisores", CustomPedidosRevisores);
export default CustomPedidosRevisores;