import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomListadoParaInventarioFisico
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_jdesk_1.01.0002
 **/
class CustomListadoParaInventarioFisico extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm = this.initForm.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);

    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes['onSelectionChanged'] = this.onSelectionChanged;

    this.gridOptionsComponentes2 = Object.assign({}, this.gridOptions);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes2['onSelectionChanged'] = this.onSelectionChanged;

    this.habilitarCorreo = this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo = this.deshabilitarCorreo.bind(this);
    this.abrirImprimir = this.abrirImprimir.bind(this);

    this.operacion_actual = '';
    this.validarConsultar = this.validarConsultar.bind(this);
    this.successTraerDatosInventario = this.successTraerDatosInventario.bind(this);
    this.traerPropiedades = this.traerPropiedades.bind(this);
    this.sucessTraerPropiedades = this.sucessTraerPropiedades.bind(this);;


    this.isJson = this.isJson.bind(this);
    this.generarExcelTabla3 = this.generarExcelTabla3.bind(this);
    this.generarPdfTabla3 = this.generarPdfTabla3.bind(this);
    this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo = this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado = this.mostrarMensajeEmailEnviado.bind(this);
    this.successEnviarCorreo = this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros = this.enviarCorreoTerceros.bind(this);
    this.procesar = this.procesar.bind(this);
    this.sucessTraerDatos = this.sucessTraerDatos.bind(this);
    this.genearModalGlobales = this.genearModalGlobales.bind(this);
    this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
    this.currencyFormatterGeneral = this.currencyFormatterGeneral.bind(this);
    this.formatNumberSaldo = this.formatNumberSaldo.bind(this);


    this.nombre_archivo = 'listado_para_inventario_fisico';
    this.numero_deciamles = '';
    this.sucursal_ingreso = '';
  }

  initForm() {
    console.log("Formulario CustomListadoParaInventarioFisico,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.traerPropiedades();

    this.getField('imprimir_marcas').setVisible(false);
    this.getField('imprimir').setVisible(false);

    this.getField('procesar').setClick(() => this.validarConsultar());
    this.getField('imprimir').setClick(() => {
      this.nombre_archivo='listado_inventario_fisico';
      this.boton='normal';
      this.abrirImprimir()
  });
    this.getField('imprimir_marcas').setClick(() => {
      this.nombre_archivo='listado_inventario_fisico_marcas';
      this.boton='marcas';
      this.abrirImprimir()
    });

    this.getField('ch_saldos_cero').setOnChange(() => {
      this.limpiarCampos();
      this.getField('nombre_bodega').setError(false, '');
    });


    this.getField('bodega').setOnChange(() => {
      this.limpiarCampos();
      this.getField('nombre_bodega').setError(false, '');
    });

    this.getField('codigo_articulo_desde').setOnChange(() => {
      this.limpiarCampos();
      this.getField('nombre_articulo_desde').setError(false, '');
    });

    this.getField('codigo_articulo_hasta').setOnChange(() => {
      this.limpiarCampos();
      this.getField('nombre_articulo_hasta').setError(false, '');
    });

    this.getField('codigo_grupo').setOnChange(() => {
      this.limpiarCampos();
      this.getField('nombre_grupo').setError(false, '');
    });

    this.getField('codigo_subgrupo').setOnChange(() => {
      this.limpiarCampos();
      this.getField('nombre_subgrupo').setError(false, '');
    });

    this.getField('codigo_marca').setOnChange(() => {
      this.limpiarCampos();
      this.getField('nombre_marca').setError(false, '');
    });


    //imprimir
    this.getField('radio_correo').setValue("N");
    this.getField('generar_archivo').setClick(() => this.procesar());

    //para balances globales
    this.getField('ch_excel').setOnChange((props)=>{
      if(this.getField('ch_excel').getValue()==='false'){
        this.getField('ch_pdf').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='excel';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pdf').setOnChange((props)=>{
      if(this.getField('ch_pdf').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='pdf';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pantalla').setOnChange((props)=>{
      if(this.getField('ch_pantalla').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pdf').setValueSwitch (false);
        this.operacion_actual='pantalla';
        this.deshabilitarCorreo();
      }else{
          this.habilitarCorreo();
      }
    });
    
  }

  limpiarCampos() {
    this.getField('rejilla').toggle(false);
    this.getField('imprimir').setVisible(false);
    this.getField('imprimir_marcas').setVisible(false);
    this.getField('procesar').setDisabled(false);
    this.getField('total_costo').setVisible(false);
    this.getField('total_saldo').setVisible(false);
    this.getField('total_valor').setVisible(false);
  }

  validarConsultar() {

    this.getField('bodega').setError(false, '');
    this.getField('codigo_articulo_desde').setError(false, '');
    this.getField('codigo_articulo_hasta').setError(false, '');
    this.getField('codigo_grupo').setError(false, '');
    this.getField('codigo_subgrupo').setError(false, '');
    this.getField('codigo_marca').setError(false, '');

    let errores = 0;
    if (!this.getField('bodega').valid() || !this.getField('nombre_bodega').valid())
      errores++;
    if (this.getField('codigo_articulo_desde').getValue() !== '' && this.getField('codigo_articulo_desde').getValue() !== ' ') {
      if (!this.getField('codigo_articulo_desde').valid())
        errores++;
      if (!this.getField('nombre_articulo_desde').valid())
        errores++;
    }
    if (this.getField('codigo_articulo_hasta').getValue() !== '' && this.getField('codigo_articulo_hasta').getValue() !== ' ') {
      if (!this.getField('codigo_articulo_hasta').valid())
        errores++;
      if (!this.getField('nombre_articulo_hasta').valid())
        errores++;
    }
    if (this.getField('codigo_grupo').getValue() !== '' && this.getField('codigo_grupo').getValue() !== ' ') {
      if (!this.getField('codigo_grupo').valid())
        errores++;
      if (!this.getField('nombre_grupo').valid())
        errores++;
    }
    if (this.getField('codigo_subgrupo').getValue() !== '' && this.getField('codigo_subgrupo').getValue() !== ' ') {
      if (!this.getField('codigo_subgrupo').valid())
        errores++;
      if (!this.getField('nombre_subgrupo').valid())
        errores++;
    }
    if (this.getField('codigo_marca').getValue() !== '' && this.getField('codigo_marca').getValue() !== ' ') {
      if (!this.getField('codigo_marca').valid())
        errores++;
      if (!this.getField('nombre_marca').valid())
        errores++;
    }

    if (errores === 0) {
      this.mostrarMensajeGenerando();
      let datos = {
        datos: {
          sucursal_ingreso: this.sucursal_ingreso,
          bodega: this.getField('bodega').getValue(),
          ch_saldos_cero: this.getField('ch_saldos_cero').getValue(),
        }
      }
      if (this.getField('nombre_articulo_desde').getValue() !== '' && this.getField('nombre_articulo_desde').getValue() !== ' ')
        datos.datos.codigo_articulo_desde = this.getField('codigo_articulo_desde').getValue();
      if (this.getField('nombre_articulo_hasta').getValue() !== '' && this.getField('nombre_articulo_hasta').getValue() !== ' ')
        datos.datos.codigo_articulo_hasta = this.getField('codigo_articulo_hasta').getValue();
      if (this.getField('nombre_grupo').getValue() !== '' && this.getField('nombre_grupo').getValue() !== ' ')
        datos.datos.codigo_grupo = this.getField('codigo_grupo').getValue();
      if (this.getField('nombre_subgrupo').getValue() !== '' && this.getField('nombre_subgrupo').getValue() !== ' ')
        datos.datos.codigo_subgrupo = this.getField('codigo_subgrupo').getValue();
      if (this.getField('nombre_marca').getValue() !== '' && this.getField('nombre_marca').getValue() !== ' ')
        datos.datos.codigo_marca = this.getField('codigo_marca').getValue();
      this.mostrarMensajeGenerando();
      this.getField('procesar').setDisabled(true);
      this.getField("rejilla").setTipoServicioOperacion('inve-listadoinventariofisico-inventario');
      this.getField("rejilla").sendRequest(this.successTraerDatosInventario, datos);

    }

  }

  successTraerDatosInventario(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      this.getField('rejilla').toggle(true);
      this.getField('imprimir').setVisible(true);
      this.getField('imprimir_marcas').setVisible(true);
      this.gridOptionsComponentes2['rowData'] = [];
      let configCell = new Map();
      this.gridOptionsComponentes2['rowData'] = data.data.items;
      configCell.set('contenido', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.contenido) }, cellStyle: { textAlign: "right" }, width: 110 });

      this.getField('rejilla').initData(this.gridOptionsComponentes2, configCell);
      this.getField('imprimir').setDisabled(false);
      this.getField('total_costo').setVisible(true);
      this.getField('total_saldo').setVisible(true);
      this.getField('total_valor').setVisible(true);

      this.getField('total_costo').setValue(data.data.totales[0].total_costo);
      this.getField('total_saldo').setValue(data.data.totales[0].total_saldo);
      this.getField('total_valor').setValue(data.data.totales[0].total_valor);
    } else if (data.estado_p === 404) {
      //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      this.mostrarMensajeNoHayDatos();
    }
    else {
      let respuesta = Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    }
  }

  currencyFormatterGeneral(number) {
    let decimal = (number + "").split(".")[1];//para manejar los decimales
    if ((decimal !== 0) && (decimal !== undefined)) {
      if (decimal.length > this.numero_decimales) {
        number = number.toFixed(this.numero_decimales); //para que redondea a dos decimales
        decimal = (number + "").split(".")[1];
      }
      return (this.formatNumberSaldo(number) + "," + decimal);
    } else {
      return this.formatNumberSaldo(number);
    }
  }

  formatNumberSaldo(number) {
    return Number(number).toFixed(this.numero_decimales).toString().split('.')[0]
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  onSelectionChanged() {

  }

  //TRAER PROPIEDADES

  traerPropiedades() {
    let datos = {
      datos: {
        sucursal_ingreso: this.sucursal_ingreso
      }
    };
    this.generalFormatPmv = { tipo_servicio: 'inve-listadoinventariofisico', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send({
      endpoint: this.constant.formConfiguration(),
      method: 'GET',
      body: datos,
      success: this.sucessTraerPropiedades,
      error: this.error_,
      general: this.generalFormatPmv,
      showMessage: false
    });
  }

  sucessTraerPropiedades(data) {
    if (data.estado_p === 200) {
      this.numero_decimales = data.data.numero_decimales_compras;
    }
  }

  //funciones para imprimir

  deshabilitarCorreo() {
    this.getField('email').setDisabled(true);
    this.getField('email').setValue('');
    this.getField('email').setError(false, '');
    this.getField('radio_correo').setDisabled(true);
    this.getField('radio_correo').setValue("N");
  }

  habilitarCorreo() {
    this.getField('email').setDisabled(false);
    this.getField('radio_correo').setDisabled(false);
    this.getField('radio_correo').setValue("N");
  }


  abrirImprimir() {

    this.getField("modal_imprimir").setLabel("IMPRIMIR");
    this.getField('modal_imprimir').handleClickOpen();
    this.getField('email').setValue('');
    this.getField('email').setError(false, '');
    this.getField('radio_correo').setValue("N");
    this.getField('ch_excel').setValueSwitch(false);
    this.getField('ch_pdf').setValueSwitch(false);
    this.getField('ch_pantalla').setValueSwitch(false);
    if(this.boton==='normal')
      this.getField('ch_pantalla').setDisabled(true);
    else
      this.getField('ch_pantalla').setDisabled(false);
    
  }

  procesar() {
    let errores = 0;
    if (this.operacion_actual !== 'pantalla') {
      if (this.getField('email').valid() === false) {
        errores++;
      }
    }
    if (this.getField('ch_excel').getValue() === 'false' && this.getField('ch_pdf').getValue() === 'false' && this.getField('ch_pantalla').getValue() === 'false') {
      errores++;
      this.getField('ch_excel').setError(true, '* Es necesario seleccionar una opción.');
      this.getField('ch_pdf').setError(true, '* Es necesario seleccionar una opción.');
      this.getField('ch_pantalla').setError(true, '* Es necesario seleccionar una opción.');
      this.operacion_actual = '';
      this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
    }

    if (errores === 0) {
      if (this.operacion_actual !== '') {
        if (this.getField('radio_correo').getValue() === 'S') {
          this.enviarCorreoTerceros();
        } else {
          if (this.operacion_actual === 'pdf') {
            this.generarPdfTabla3();
          } else if (this.operacion_actual === 'pantalla') {
            this.genearModalGlobales();
          } else if (this.operacion_actual === 'excel') {
            this.generarExcelTabla3();
          }
        }
      } else {
        this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
        this.getField('confirmModalCustom').toggle(false);
      }
    }
  }

  genearModalGlobales() {
    this.mostrarMensajeGenerando();
    let datos = { datos:{ }};
    let op='';
    if(this.boton==='normal'){
      datos.datos = {
        bodega: this.getField('bodega').getValue(),
        ch_saldos_cero: this.getField('ch_saldos_cero').getValue(),
        email: this.getField('email').getValue(),
        sucursal_ingreso: this.sucursal_ingreso,
        operacion: this.operacion_actual,
      };
  
      if (this.getField('nombre_articulo_desde').getValue() !== '' && this.getField('nombre_articulo_desde').getValue() !== ' ')
        datos.datos.codigo_articulo_desde = this.getField('codigo_articulo_desde').getValue();
      if (this.getField('nombre_articulo_hasta').getValue() !== '' && this.getField('nombre_articulo_hasta').getValue() !== ' ')
        datos.datos.codigo_articulo_hasta = this.getField('codigo_articulo_hasta').getValue();
      if (this.getField('nombre_grupo').getValue() !== '' && this.getField('nombre_grupo').getValue() !== ' '){
        datos.datos.codigo_grupo = this.getField('codigo_grupo').getValue();
        datos.datos.nombre_grupo = this.getField('nombre_grupo').getValue();
      }
      if (this.getField('nombre_subgrupo').getValue() !== '' && this.getField('nombre_subgrupo').getValue() !== ' '){
        datos.datos.codigo_subgrupo = this.getField('codigo_subgrupo').getValue();
        datos.datos.nombre_subgrupo = this.getField('nombre_subgrupo').getValue();
      }
      if (this.getField('nombre_marca').getValue() !== '' && this.getField('nombre_marca').getValue() !== ' '){
        datos.datos.codigo_marca = this.getField('codigo_marca').getValue();
        datos.datos.nombre_marca = this.getField('nombre_marca').getValue();
      }
      op = 'archivoitems_pag';
    }else{
      datos.datos = {
        bodega: this.getField('bodega').getValue(),
        email: this.getField('email').getValue(),
        sucursal_ingreso: this.sucursal_ingreso,
        operacion: this.operacion_actual,
      };
      op = 'archivoitems_marca_pag';
    }
    this.getField("rejilla_items_result").setTipoServicioOperacion('inve-listadoinventariofisico-'+op.replace('_pag',''));
    this.getField("rejilla_items_result").sendRequest(this.sucessTraerDatos, datos); 

  }

  sucessTraerDatos(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      let datosFicha = [];
      this.lista = data.data.datos_lista;
      let data2 =
      {
        "empresa": this.lista.empresa,
        "nit": this.lista.nit,
        "direccion": this.lista.direccion,
        "pbx": this.lista.pbx,
        "nit_xml": this.lista.nit_xml,
        "titulo": this.lista.titulo,
        "fecha_sistema": this.lista.fecha_sistema
      };

      datosFicha.push(data2);
      this.getField('lista_datos').setItemsFichas(datosFicha);
      this.getField('rejilla_items_result').toggle(true);
      this.getField("modal_result").setLabel('Listado para Inventario Físico');
      this.getField('modal_result').handleClickOpen();
      this.gridOptionsComponentes['rowData'] = [];
      let configCell = new Map();
      this.gridOptionsComponentes['rowData'] = data.data.datos_items;

      configCell.set('contenido', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.contenido) }, cellStyle: { textAlign: "right" }, width: 110 });

      if (this.maneja_bodega_ubicacion !== 'S') {
        configCell.set('ubicacion_bodega', { cellRenderer: (props) => { return "" + props.data.ubicacion_bodega }, hide: true });
      }
      this.getField('rejilla_items_result').initData(this.gridOptionsComponentes, configCell);


      this.getField('proveedor_result').setValue(this.lista.proveedor);
      this.getField('direcccion').setValue(this.lista.direccion_nit);
      this.getField('telefono').setValue(this.lista.telefono_nit);
      this.getField('ciudad').setValue(this.lista.ciudad_nit);

      this.getField('retencion_result').setValue(this.currencyFormatterGeneral(this.lista.retencion));
      this.getField('valor_exento_result').setValue(this.currencyFormatterGeneral(this.lista.valor_exento));
      this.getField('reteiva_result').setValue(this.currencyFormatterGeneral(this.lista.reteiva));
      this.getField('valor_grabado_result').setValue(this.currencyFormatterGeneral(this.lista.grabado));

      this.getField('reteica_result').setValue(this.currencyFormatterGeneral(this.lista.retencionica));
      this.getField('cree_result').setValue(this.currencyFormatterGeneral(this.lista.cree));
      this.getField('aproximacion_result').setValue(this.currencyFormatterGeneral(this.lista.aproximacion));
      this.getField('iva_result').setValue(this.currencyFormatterGeneral(this.lista.total_iva));
      this.getField('fletes_result').setValue(this.currencyFormatterGeneral(this.lista.fletes));
      this.getField('valor_total_result').setValue(this.currencyFormatterGeneral(this.lista.valor_total));

      //descuentos
      let fecha1 = this.lista.descuento1.split(':')[0];
      let fecha2 = this.lista.descuento2.split(':')[0];
      let fecha3 = this.lista.descuento3.split(':')[0];
      let descuento1 = this.currencyFormatterGeneral(Number(this.lista.descuento1.split(':')[1].trim()));
      let descuento2 = this.currencyFormatterGeneral(Number(this.lista.descuento2.split(':')[1].trim()));
      let descuento3 = this.currencyFormatterGeneral(Number(this.lista.descuento3.split(':')[1].trim()));

      this.getField('descuento1_result').setValue(fecha1 + ' : ' + descuento1);
      this.getField('descuento2_result').setValue(fecha2 + ' : ' + descuento2);
      this.getField('descuento3_result').setValue(fecha3 + ' : ' + descuento3);


    } else if (data.estado_p === 404) {
      //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      this.mostrarMensajeNoHayDatos();
    }
    else {
      let respuesta = Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    }
  }


  enviarCorreoTerceros() {
    this.mostrarMensajeGenerando();
    let datos = { datos:{ }};
    let op='';
    if(this.boton==='normal'){
      datos.datos = {
        bodega: this.getField('bodega').getValue(),
        ch_saldos_cero: this.getField('ch_saldos_cero').getValue(),
        email: this.getField('email').getValue(),
        radio_correo:this.getField('radio_correo').getValue(),
        sucursal_ingreso: this.sucursal_ingreso,
        operacion: this.operacion_actual,
      };
  
      if (this.getField('nombre_articulo_desde').getValue() !== '' && this.getField('nombre_articulo_desde').getValue() !== ' ')
        datos.datos.codigo_articulo_desde = this.getField('codigo_articulo_desde').getValue();
      if (this.getField('nombre_articulo_hasta').getValue() !== '' && this.getField('nombre_articulo_hasta').getValue() !== ' ')
        datos.datos.codigo_articulo_hasta = this.getField('codigo_articulo_hasta').getValue();
      if (this.getField('nombre_grupo').getValue() !== '' && this.getField('nombre_grupo').getValue() !== ' '){
        datos.datos.codigo_grupo = this.getField('codigo_grupo').getValue();
        datos.datos.nombre_grupo = this.getField('nombre_grupo').getValue();
      }
      if (this.getField('nombre_subgrupo').getValue() !== '' && this.getField('nombre_subgrupo').getValue() !== ' '){
        datos.datos.codigo_subgrupo = this.getField('codigo_subgrupo').getValue();
        datos.datos.nombre_subgrupo = this.getField('nombre_subgrupo').getValue();
      }
      if (this.getField('nombre_marca').getValue() !== '' && this.getField('nombre_marca').getValue() !== ' '){
        datos.datos.codigo_marca = this.getField('codigo_marca').getValue();
        datos.datos.nombre_marca = this.getField('nombre_marca').getValue();
      }
      op = 'archivoitems_pag';
    }else{
      datos.datos = {
        bodega: this.getField('bodega').getValue(),
        email: this.getField('email').getValue(),
        sucursal_ingreso: this.sucursal_ingreso,
        operacion: this.operacion_actual,
      };
      op = 'archivoitems_marca_pag';
    }
    this.generalFormatPmv = { tipo_servicio: 'inve-listadoinventariofisico', operacion: op, operacion_tipo: 'consulta' };
    this.service.send(
      {
        endpoint: this.constant.formConfiguration(),
        method: 'GET',
        body: datos,
        success: this.successEnviarCorreo,
        error: this.error_,
        general: this.generalFormatPmv,
        showMessage: false
      });
  }

  successEnviarCorreo(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      this.mostrarMensajeEmailEnviado();
    } else {
      this.mostrarMensajeEmailFallo();
      let respuesta = Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    }
  }

  mostrarMensajeEmailEnviado() {
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeEmailFallo() {
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeNoHayDatos() {
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeGenerando() {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
    this.getField('confirmModalCustom').setClickDialog(() => { });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeAdicional(mensaje) {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('confirmModalCustom').setTitleAndContent('Compras', mensaje);
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  generarPdfTabla3() {
    this.mostrarMensajeGenerando();
    let url = this.constant.formConfiguration();
    let datos2 = {};
    let op='';
    if(this.boton==='normal'){
      datos2 = {
        bodega: this.getField('bodega').getValue(),
        ch_saldos_cero: this.getField('ch_saldos_cero').getValue(),
        email: this.getField('email').getValue(),
        sucursal_ingreso: this.sucursal_ingreso,
        operacion: this.operacion_actual,
      };
  
      if (this.getField('nombre_articulo_desde').getValue() !== '' && this.getField('nombre_articulo_desde').getValue() !== ' ')
        datos2.codigo_articulo_desde = this.getField('codigo_articulo_desde').getValue();
      if (this.getField('nombre_articulo_hasta').getValue() !== '' && this.getField('nombre_articulo_hasta').getValue() !== ' ')
        datos2.codigo_articulo_hasta = this.getField('codigo_articulo_hasta').getValue();
      if (this.getField('nombre_grupo').getValue() !== '' && this.getField('nombre_grupo').getValue() !== ' '){
        datos2.codigo_grupo = this.getField('codigo_grupo').getValue();
        datos2.nombre_grupo = this.getField('nombre_grupo').getValue();
      }
      if (this.getField('nombre_subgrupo').getValue() !== '' && this.getField('nombre_subgrupo').getValue() !== ' '){
        datos2.codigo_subgrupo = this.getField('codigo_subgrupo').getValue();
        datos2.nombre_subgrupo = this.getField('nombre_subgrupo').getValue();
      }
      if (this.getField('nombre_marca').getValue() !== '' && this.getField('nombre_marca').getValue() !== ' '){
        datos2.codigo_marca = this.getField('codigo_marca').getValue();
        datos2.nombre_marca = this.getField('nombre_marca').getValue();
      }
      op = 'archivoitems_pag';
    }else{
      datos2 = {
        bodega: this.getField('bodega').getValue(),
        email: this.getField('email').getValue(),
        sucursal_ingreso: this.sucursal_ingreso,
        operacion: this.operacion_actual,
      };
      op = 'archivoitems_marca_pag';
    }
    
    const myJSON = JSON.stringify(datos2);
    let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"inve-listadoinventariofisico","operacion":"' + op + '","operacion_tipo":"consulta"}}';
    fetch(url + datos, {
      method: 'GET',
      headers: new Headers({
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
        "Content-Type": 'application/json'
      })
    })
      .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if (response.status !== 200) {
          return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          }
        })
      })
      .then(stream => new Response(stream))
      .then(response => response.blob())
      .then(blob => {
        if (blob.size > 0) {
          blob.text().then(res => {
            if (this.isJson(res)) {
              this.successEnviarCorreo(JSON.parse(res));
            } else {
              const pdf = new File([blob], this.nombre_archivo + '.pdf', {
                type: 'application/pdf'
              });
              window.open(URL.createObjectURL(pdf));
            }
          });
        } else {
          this.mostrarMensajeNoHayDatos();
        }
      }).catch(err => {
        this.alertGeneral.toggle(true, err, 'error');
      });
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  generarExcelTabla3() {
    this.mostrarMensajeGenerando();
    let url = this.constant.formConfiguration();
    let datos2={};
    let op='';
    if(this.boton==='normal'){
      datos2 = {
        bodega: this.getField('bodega').getValue(),
        ch_saldos_cero: this.getField('ch_saldos_cero').getValue(),
        email: this.getField('email').getValue(),
        sucursal_ingreso: this.sucursal_ingreso,
        operacion: this.operacion_actual,
      };
  
      if (this.getField('nombre_articulo_desde').getValue() !== '' && this.getField('nombre_articulo_desde').getValue() !== ' ')
        datos2.codigo_articulo_desde = this.getField('codigo_articulo_desde').getValue();
      if (this.getField('nombre_articulo_hasta').getValue() !== '' && this.getField('nombre_articulo_hasta').getValue() !== ' ')
        datos2.codigo_articulo_hasta = this.getField('codigo_articulo_hasta').getValue();
      if (this.getField('nombre_grupo').getValue() !== '' && this.getField('nombre_grupo').getValue() !== ' '){
        datos2.codigo_grupo = this.getField('codigo_grupo').getValue();
        datos2.nombre_grupo = this.getField('nombre_grupo').getValue();
      }
      if (this.getField('nombre_subgrupo').getValue() !== '' && this.getField('nombre_subgrupo').getValue() !== ' '){
        datos2.codigo_subgrupo = this.getField('codigo_subgrupo').getValue();
        datos2.nombre_subgrupo = this.getField('nombre_subgrupo').getValue();
      }
      if (this.getField('nombre_marca').getValue() !== '' && this.getField('nombre_marca').getValue() !== ' '){
        datos2.codigo_marca = this.getField('codigo_marca').getValue();
        datos2.nombre_marca = this.getField('nombre_marca').getValue();
      }
      op = 'archivoitems_pag';
    }else{
      datos2 = {
        bodega: this.getField('bodega').getValue(),
        email: this.getField('email').getValue(),
        sucursal_ingreso: this.sucursal_ingreso,
        operacion: this.operacion_actual,
      };
      op = 'archivoitems_marca_pag';
    }

    const myJSON = JSON.stringify(datos2);
    let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"inve-listadoinventariofisico","operacion":"' + op + '","operacion_tipo":"consulta"}}';
    fetch(url + datos, {
      method: 'GET',
      headers: new Headers({
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
        "Content-Type": 'application/json'
      })
    })
      .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if (response.status !== 200) {
          return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          }
        })
      })
      .then(stream => new Response(stream))
      .then(response => response.blob())
      .then(blob => {
        if (blob.size > 0) {
          blob.text().then(res => {
            if (this.isJson(res)) {
              this.successEnviarCorreo(JSON.parse(res));
            } else {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              // the filename you want
              a.download = this.nombre_archivo + '.xlsx';
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              alert('Se descargo el archivo!');
            }
          });
        } else {
          this.mostrarMensajeNoHayDatos();
        }
      }).catch(err => {
        this.alertGeneral.toggle(true, err, 'error');
      });
  }



}
FormJaivana.addController("inve-listadoinventariofisico", CustomListadoParaInventarioFisico);
export default CustomListadoParaInventarioFisico;