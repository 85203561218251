import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomIntegracionContable
 * @author: Santiago Hernández N
 * @version: jdesk_jdesk_1.01.0004
 **/
class CustomIntegracionContable extends FormJaivana.form {

  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.traerDatosLista                   = this.traerDatosLista.bind(this);
    this.sucessTraerDatosLista             = this.sucessTraerDatosLista.bind(this);
    this.integracionContable               = this.integracionContable.bind(this);
    this.sucessIntegracionContable         = this.sucessIntegracionContable.bind(this);
    this.successIntegracionProceso         = this.successIntegracionProceso.bind(this);
  }

  initForm() {
    console.log("Formulario CustomIntegracionContable,  @version: jdesk_1.01.0004, @author: Santiago Hernández N");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.traerDatosLista();
    this.getField("integrar").setClick(this.integracionContable);

  }

  traerDatosLista(){
    let numero_factura = this.props.data.factura_numero
    let datos={ datos: {
      factura_numero: numero_factura
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-validarcompra', operacion: 'traercompra', operacion_tipo: 'consulta' };
    this.service.send({
      endpoint: this.constant.formConfiguration(),
      method:'GET',
      body: datos,
      success: this.sucessTraerDatosLista,
      error: this.error_,
      general: this.generalFormatPmv,
      showMessage: false
    });    
  }

  sucessTraerDatosLista(data){
    if(data.estado_p === 200){ 
      this.dataic = data.data[0];
      let datosFicha = [];
      this.lista = data.data[0];
      let data2 = {
        "numero_factura": this.lista.numero,
        "fecha": this.lista.fecha,
        "proveedor": this.lista.nproveedor,
        "valor_sin_impuestos": "".formatoPrecio(this.lista.valor_sin_impuestos),
        "valor_iva":  "".formatoPrecio(this.lista.valor_iva),
        "orden_compra": this.lista.pedido_compra,
      };

      datosFicha.push(data2);
      this.getField('li_datos_compras').setItemsFichas(datosFicha);
      
    }else if(data.estado_p === 404){
      this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre la compra.', 'error');
    }else{
      let respuesta=Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 
  }

  integracionContable(){
    let datos={ datos: {
      numero_documento: this.dataic.numero,
      codigo_sucursal: this.dataic.codigo_sucursal,
      fecha_documento: this.dataic.fecha
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-integracioncontable', operacion: 'integrar', operacion_tipo: 'crear' };
    this.service.send({
      endpoint: this.constant.formConfiguration(),
      method:'POST',
      body: datos,
      success: this.sucessIntegracionContable,
      error: this.error_,
      general: this.generalFormatPmv
    }); 
  }

  sucessIntegracionContable(data){
    if(data.estado_p === 200){ 
      let data_envio={
        factura_numero:this.props.data.factura_numero,       
      }
      let datos={ datos: {
        data:data_envio,
        proceso_id:this.props.data.proceso_id,
        procesos_actividades_id:this.props.data.actividad_id,
        procesos_actividades_configuracion_id:this.props.data.actividad_configuracion_id,
        referencia:this.props.data.factura_numero,
        tabla:".",
        registro_id:1,
        nombre_proceso:"Nueva Compra",
        descripcion_proceso:this.props.data.factura_numero
      }};
      this.generalFormatPmv = { tipo_servicio: 'proc-procesos', operacion: '6', operacion_tipo: 'modificar' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'PUT',
              body: datos,
              success: this.successIntegracionProceso,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });


      
    }else{
      this.alertGeneral.toggle(true, 'Error al contabilzar la compra', 'error');
    } 
  }

  successIntegracionProceso(data){
    if(data.estado_p === 200){ 
      let datos={ datos: {
        id:this.props.data.proceso_id,
      }};
      this.generalFormatPmv = { tipo_servicio: 'proc-procesos', operacion: '7', operacion_tipo: 'modificar' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'PUT',
              body: datos,
              success: this.successCloseEstage,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });    
    }else{
      this.alertGeneral.toggle(true, 'Error al cerrar proceso para la compra', 'error');
    } 
  }

}
FormJaivana.addController("comp-integracioncontable", CustomIntegracionContable);
export default CustomIntegracionContable;